import request from '@/utils/request';
import { filterQuery } from '@/utils/index';

export function checkDataIntegrity() {
  return request({
    url: '/api/ImportFile/CheckDataIntegrity',
    method: 'get',
  });
}

export function getImportFileById(importFileId) {
  return request({
    url: '/api/ImportFile/GetImportFileById?importFileId=' + importFileId,
    method: 'get',
  });
}

export function getImportFileList(query) {
  return request({
    url: '/api/ImportFile/GetImportFileList',
    method: 'get',
    params: filterQuery(query)
  });
}

export function createImportFile(query) {
  return request({
    url: '/api/ImportFile/CreateImportFile',
    method: 'post',
    params: filterQuery(query)
  });
}

export function updateImportFile(query) {
  return request({
    url: '/api/ImportFile/UpdateImportFile',
    method: 'post',
    params: filterQuery(query)
  });
}

export function importFileContentController() {
  return request({
    url: '/api/ImportFile/ImportFileContentController',
    method: 'get',
  });
}

export function getTypeImportsAsKeyValuePairList() {
  return request({
    url: '/api/ImportFile/getTypeImportsAsKeyValuePairList',
    method: 'get',
  });
}
