<template>
  <v-dialog
    v-model="open"
    max-width="70%"
    scrollable
  >
    <v-card>
      <v-card-title>
        Детайли за грешка
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col lg="3" md="4" cols="12">
            <v-text-field 
              label="Дата на действие"
              :value="formatDateTimeSeconds(data.dateCreated)"
              readonly
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field 
              label="Съобщение"
              :value="data.message"
              readonly
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              label="Stacktrace"
              :value="data.stacktrace"
              rows="25"
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          v-if="data.traceId && data.traceId.length"
          @click="viewAuditLog"
          class="mr-2"
        >
          Преглед на действие
        </v-btn>
        <v-btn
          @click="open = false"
        >
          Затвори
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDateTimeSeconds } from '@/utils';

export default {
  name: 'exceptionLogModal',
  data(){
    return {
      open: false,
      data: {}
    }
  },
  methods: {
    openModal(data){
      this.data = data;
      this.open = true;
    },
    viewAuditLog(){
      this.$emit("viewAuditLog", this.data);
    },
    formatDateTimeSeconds: formatDateTimeSeconds
  }
}
</script>