<template>
  <v-container id="editUserRole"
               fluid
               tag="section"
               class="full-height">

    <base-v-component goBackText="Обратно към потребители"
                      goBackTo="/administration/listUsers" />


    <base-material-card hover-reveal
                        color="primary"
                        icon="mdi-align-horizontal-right"
                        inline
                        class="px-5 py-5 mt-15"
                        style="border-top-right-radius: 30px; border-bottom-right-radius: 30px; border-bottom-left-radius: 30px;">
      <h3>Потребител: {{getUserInfo}}</h3><br />
    </base-material-card>

    <v-expansion-panels multiple v-model="panel" focusable>
      <v-expansion-panel disabled style="background-color: #263238;">
        <v-expansion-panel-header>
          <v-row>
            <v-col :cols="panel.length > 0 ? 6 : 4">
              <h3 style="color: white !important">Модул</h3>
            </v-col>
            <v-col v-if="panel.length === 0" cols="8">
              <h3 style="color: white !important">Избрани роли</h3>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
      </v-expansion-panel>
      <v-expansion-panel v-for="(client, idx) in clients" :key="idx">
        <v-expansion-panel-header>
          <v-row>
            <v-col cols="4">
              {{client.clientId}}
            </v-col>
            <v-col cols="8" v-if="panel.length === 0">
              {{showAssignedRoles(client)}}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="justify-center" wrap>

            <!--All available Roles-->
            <v-col cols="6">
              <v-card class="v-card-profile" color="white">
                <v-card-text>
                  <b>Налични роли за модул</b>
                  <v-list dense>
                    <v-list-item-group v-model="client.selectedRoles"
                                       color="primary"
                                       multiple>
                      <v-list-item v-for="(role, i) in client.allRoles"
                                   :key="i"
                                   :value="role.id">
                        <v-list-item-content>
                          <v-list-item-title v-text="role.name"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="unactiveGradient" style="font-weight: bold;"
                         x-small
                         @click="addRoleToClient(client)">
                    Добави<v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>

            <!--All Associated Roles-->
            <v-col cols="6">
              <v-card class="v-card-profile" color="white">
                <v-card-title class="text-h5">
                  <b>Избрани роли за модул</b>
                </v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item-group v-model="client.selectedAssigned"
                                       color="primary"
                                       multiple>
                      <v-list-item v-for="(role, i) in client.assignedRoles"
                                   :key="i"
                                   :value="role.id">
                        <v-list-item-content>
                          <v-list-item-title v-text="role.name"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="unactiveGradient" style="font-weight: bold;"
                         x-small
                         @click="removeRoleToClient(client)">
                    <v-icon>mdi-chevron-left</v-icon>Премахни
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>

          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>

  import { getAllClientsFromKeycloak, getClientAvailableRolesByUserIdFromKeycloak, getClientAssignedRolesByUserIdFromKeycloak, assingClientRolesToUser, removeClientRolesFromUser } from "@/api/roles";
  import { getUserInfoKeycloak } from "@/api/users";

  export default {
    name: "editUserRole",
    components: {
    },
    data() {
      return {
        panel: [],
        clients: [],

        currentUser: {}
      }
    },
    watch: {},
    computed: {
      showAssignedRoles() {
        return client => {
          if (client.assignedRoles) {
            let text = client.assignedRoles.map(x => x.name).join(', ');
            if (text.length > 100)
              return text.substring(0, 100) + '...'
            return text;
          }
        }
      },
      getUserInfo() {
        return `${this.currentUser.firstName} ${this.currentUser.lastName} (${this.currentUser.username})`;
      }
    },
    mounted() {
      let vue = this;

      vue.getData(vue.$route.params.userID);
      getUserInfoKeycloak(vue.$route.params.userID).then((data) => { vue.currentUser = data; });
    },
    created() { },
    methods: {
      getData(userID) {
        getAllClientsFromKeycloak().then((clients) => {
          let vue = this;
          vue.clients = clients;

          vue.clients.forEach(client => {
            getClientAvailableRolesByUserIdFromKeycloak(userID, client.id).then((data) => {
              client.selectedRoles = [];
              client.allRoles = data;
              vue.$forceUpdate();
            });

            getClientAssignedRolesByUserIdFromKeycloak(userID, client.id).then((data) => {
              client.selectedAssigned = [];
              client.assignedRoles = data;
              vue.$forceUpdate();
            });
          });
        });
      },
      addRoleToClient(client) {
        let vue = this;

        if (client.selectedRoles.length > 0) {

          /// iterate all selected roles from the left and move them to the right - just from user experience
          let rolesToBeAddedInKeyCloak = [];
          client.selectedRoles.forEach(id => {
            let obj = client.allRoles.find(x => x.id === id);
            client.assignedRoles.push(obj);

            let index = client.allRoles.findIndex(x => x.id === id);
            rolesToBeAddedInKeyCloak.push(client.allRoles.splice(index, 1)[0]);
          });

          /// make server call - same as previous step but for keycloak
          assingClientRolesToUser(rolesToBeAddedInKeyCloak, vue.$route.params.userID, client.id).then((data) => {
            this.notification(data, false);
          });

          client.selectedRoles = [];

          vue.$forceUpdate();
        }
      },
      removeRoleToClient(client) {
        let vue = this;

        if (client.selectedAssigned.length > 0) {

          /// iterate all assigned roles from the right and move them to the left - just from user experience
          let rolesToBeDeletedInKeyCloak = [];
          client.selectedAssigned.forEach(id => {
            let obj = client.assignedRoles.find(x => x.id === id);
            client.allRoles.push(obj);

            let index = client.assignedRoles.findIndex(x => x.id === id);
            rolesToBeDeletedInKeyCloak.push(client.assignedRoles.splice(index, 1)[0]);
          });

          /// make server call - same as previous step but for keycloak
          removeClientRolesFromUser(rolesToBeDeletedInKeyCloak, vue.$route.params.userID, client.id).then((data) => {
            this.notification(data, false);
          });

          client.selectedAssigned = [];

          vue.$forceUpdate();
        }
      },
      notification(data, doAditionalActions) {
        if (data.includes('(0)')) {

          //Notification for failed operation
          this.$snotify.error(data.substring(4));

        } else if (data.includes('({0})')) {

          //Notification for successfull operation
          this.$snotify.success(data.substring(6));

          if (doAditionalActions) {
            //After update clean the model
            this.clientID = null;
            this.clients = [];

            //Redirect to all users list
            this.$router.push({ path: `/administration/listRoles` });
          }
        }
      }
    }
  };
</script>
