<template>
  <base-material-filter
    style="border-top-right-radius: 30px"
    title="Търсене на водомери"
    :hasFilters="hasActiveFilters"
    v-on:do-filter="doFilter()"
    v-on:remove-filter="clearFilters"
  >
    <v-row @keypress.enter="doFilter" class="mx-1" style="margin-top: 10px">
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(1, 0)">
        <v-text-field
          label="Водомер №"
          v-model="filters.waterMeterFabricNo"
          autocomplete="off"
          clearable
          @click:clear="clearIt(1, 0, 'waterMeterFabricNo')"
          @click="clickFilterItems(1, 0)"
          dense
        />
      </v-col>
      <!--<v-col lg="2" md="4" cols="6" v-show="getVisibility(2, 0)">
    <v-text-field label="Географски координати за водомер"
                  v-model="filters.geoRegion"
                  autocomplete="off"
                  clearable
                  placeholder="[(latitudeFrom, longitudeFrom) - (latitudeTo, longitudeTo)]"
                  @click:clear="clearIt(2, 0, 'geoRegion')"
                  @click="clickFilterItems(2, 0)"
                  dense />
  </v-col>-->
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(4, 0)">
        <v-menu
          v-model="menuLastDateOfRepairFrom"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата последен ремонт от"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.lastDateOfRepairFrom"
              clearable
              @click:clear="clearIt(4, 0, 'lastDateOfRepairFrom')"
              @click="clickFilterItems(4, 0)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.lastDateOfRepairFrom"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(8, 0)">
        <v-menu
          v-model="menuLastDateOfRepairТо"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата последен ремонт до"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.lastDateOfRepairТо"
              clearable
              @click:clear="clearIt(8, 0, 'lastDateOfRepairТо')"
              @click="clickFilterItems(8, 0)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.lastDateOfRepairТо"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(16, 0)">
        <v-menu
          v-model="menuLastDateOfCheckFrom"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата последно засичане от"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.lastDateOfCheckFrom"
              clearable
              @click:clear="clearIt(16, 0, 'lastDateOfCheckFrom')"
              @click="clickFilterItems(16, 0)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.lastDateOfCheckFrom"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(32, 0)">
        <v-menu
          v-model="menuLastDateOfCheckТо"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата последно засичане до"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.lastDateOfCheckТо"
              clearable
              @click:clear="clearIt(32, 0, 'lastDateOfCheckТо')"
              @click="clickFilterItems(32, 0)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.lastDateOfCheckТо"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(64, 0)">
        <v-text-field
          label="Стойност от"
          v-model="filters.checkValueFrom"
          autocomplete="off"
          clearable
          @click:clear="clearIt(64, 0, 'checkValueFrom')"
          @click="clickFilterItems(64, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(128, 0)">
        <v-text-field
          label="Стойност до"
          v-model="filters.checkValueTo"
          autocomplete="off"
          clearable
          @click:clear="clearIt(128, 0, 'checkValueTo')"
          @click="clickFilterItems(128, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(256, 0)">
        <v-menu
          v-model="menuNextDateOfCheckFrom"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Следващо засичане от"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.nextDateOfCheckFrom"
              clearable
              @click:clear="clearIt(256, 0, 'nextDateOfCheckFrom')"
              @click="clickFilterItems(256, 0)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.nextDateOfCheckFrom"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(512, 0)">
        <v-menu
          v-model="menuNextDateOfCheckТо"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Следващо засичане до"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.nextDateOfCheckТо"
              clearable
              @click:clear="clearIt(512, 0, 'nextDateOfCheckТо')"
              @click="clickFilterItems(512, 0)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.nextDateOfCheckТо"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(1024, 0)">
        <base-select
          label="Общ / Индивидуален водомер"
          :items="properties.waterMeterOwnershipTypes"
          item-text="value"
          item-value="key"
          v-model="filters.waterMeterOwnershipTypeId"
          clearable
          @click:clear="clearIt(1024, 0, 'waterMeterOwnershipTypeId')"
          @click="clickFilterItems(1024, 0)"
          dense
        >
        </base-select>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(2048, 0)">
        <v-text-field
          label="Клиентски номер"
          v-model="filters.clientNumber"
          autocomplete="off"
          clearable
          @click:clear="clearIt(2048, 0, 'clientNumber')"
          @click="clickFilterItems(2048, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(2048, 0)">
        <v-text-field
          label="Име на клиента"
          v-model="filters.clientName"
          autocomplete="off"
          clearable
          @click:clear="clearIt(2048, 0, 'clientName')"
          @click="clickFilterItems(2048, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(4096, 0)">
        <v-checkbox
          v-model="filters.isGeocoded"
          dense
          label="Само геокодирани"
        ></v-checkbox>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(4096, 0)">
        <v-text-field
          label="Град"
          v-model="filters.town"
          autocomplete="off"
          clearable
          @click:clear="clearIt(16384, 0, 'block')"
          @click="clickFilterItems(16384, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(8192, 0)">
        <v-text-field
          label="Улица"
          v-model="filters.street"
          autocomplete="off"
          clearable
          @click:clear="clearIt(16384, 0, 'block')"
          @click="clickFilterItems(16384, 0)"
          dense
        />
        <!--<base-select label="Улица"
                     :items="properties.streets"
                     item-text="value"
                     item-value="key"
                     v-model="filters.street"
                     clearable
                     @click:clear="clearIt(8192, 0, 'street')"
                     @click="clickFilterItems(8192, 0)"
                     dense>
        </base-select>-->
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(8192, 0)">
        <v-text-field
          label="Номер"
          v-model="filters.streetNumber"
          autocomplete="off"
          clearable
          @click:clear="clearIt(16384, 0, 'block')"
          @click="clickFilterItems(16384, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(16384, 0)">
        <v-text-field
          label="Блок"
          v-model="filters.block"
          autocomplete="off"
          clearable
          @click:clear="clearIt(16384, 0, 'block')"
          @click="clickFilterItems(16384, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(32768, 0)">
        <v-text-field
          label="Вход"
          v-model="filters.entrance"
          autocomplete="off"
          clearable
          @click:clear="clearIt(32768, 0, 'entrance')"
          @click="clickFilterItems(32768, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(65536, 0)">
        <v-text-field
          label="Етаж"
          v-model="filters.floor"
          autocomplete="off"
          clearable
          @click:clear="clearIt(65536, 0, 'floor')"
          @click="clickFilterItems(65536, 0)"
          dense
        />
      </v-col>
      <!--<v-col lg="2" md="4" cols="6" v-show="getVisibility(131072, 0)">
    <v-menu v-model="menuClosedDateFrom"
            v-bind:nudge-right="40"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px">
      <template v-slot:activator="{ on }">
        <v-text-field label="Дата на затваряне от"
                      height="30"
                      v-on="on"
                      autocomplete="off"
                      v-bind:value="filters.closedDateFrom"
                      clearable
                      @click:clear="clearIt(131072, 0, 'closedDateFrom')"
                      @click="clickFilterItems(131072, 0)"
                      dense>
        </v-text-field>
      </template>
      <v-date-picker v-model="filters.closedDateFrom"
                     @@input="validFromMenu = false"
                     persistent-hint
                     color="primary"
                     v-bind:first-day-of-week="1"
                     locale="bg-bg">
      </v-date-picker>
    </v-menu>
  </v-col>-->
      <!--<v-col lg="2" md="4" cols="6" v-show="getVisibility(262144, 0)">
    <v-menu v-model="menuClosedDateTo"
            v-bind:nudge-right="40"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px">
      <template v-slot:activator="{ on }">
        <v-text-field label="Дата на затваряне до"
                      height="30"
                      v-on="on"
                      autocomplete="off"
                      v-bind:value="filters.closedDateТо"
                      clearable
                      @click:clear="clearIt(262144, 0, 'closedDateТо')"
                      @click="clickFilterItems(262144, 0)"
                      dense>
        </v-text-field>
      </template>
      <v-date-picker v-model="filters.closedDateТо"
                     @@input="validFromMenu = false"
                     persistent-hint
                     color="primary"
                     v-bind:first-day-of-week="1"
                     locale="bg-bg">
      </v-date-picker>
    </v-menu>
  </v-col>-->
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(524288, 0)">
        <v-text-field
          label="Хора ползващи водомера от"
          v-model="filters.customersTotalFrom"
          autocomplete="off"
          clearable
          @click:clear="clearIt(524288, 0, 'customersTotalFrom')"
          @click="clickFilterItems(524288, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(1048576, 0)">
        <v-text-field
          label="Хора ползващи водомера до"
          v-model="filters.customersTotalТо"
          autocomplete="off"
          clearable
          @click:clear="clearIt(1048576, 0, 'customersTotalТо')"
          @click="clickFilterItems(1048576, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(2097152, 0)">
        <v-text-field
          label="Апартаменти ползващи водомера от"
          v-model="filters.apartamentsTotalFrom"
          autocomplete="off"
          clearable
          @click:clear="clearIt(2097152, 0, 'apartamentsTotalFrom')"
          @click="clickFilterItems(2097152, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(4194304, 0)">
        <v-text-field
          label="Апартаменти ползващи водомера до"
          v-model="filters.apartamentsTotalTo"
          autocomplete="off"
          clearable
          @click:clear="clearIt(4194304, 0, 'apartamentsTotalTo')"
          @click="clickFilterItems(4194304, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(8388608, 0)">
        <v-menu
          v-model="menuCreatedOnFrom"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата на създаване на партидата от"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.createdOnFrom"
              clearable
              @click:clear="clearIt(8388608, 0, 'createdOnFrom')"
              @click="clickFilterItems(8388608, 0)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.createdOnFrom"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(16777216, 0)">
        <v-menu
          v-model="menuCreatedOnТо"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата на създаване на партидата до"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.createdOnТо"
              clearable
              @click:clear="clearIt(16777216, 0, 'createdOnТо')"
              @click="clickFilterItems(16777216, 0)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.createdOnТо"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(33554432, 0)">
        <v-menu
          v-model="menuModifiedOnFrom"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата на последна промяна на партидата от"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.modifiedOnFrom"
              clearable
              @click:clear="clearIt(33554432, 0, 'modifiedOnFrom')"
              @click="clickFilterItems(33554432, 0)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.modifiedOnFrom"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(67108864, 0)">
        <v-menu
          v-model="menuModifiedOnТо"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата на последна промяна на партидата до"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.modifiedOnТо"
              clearable
              @click:clear="clearIt(67108864, 0, 'modifiedOnТо')"
              @click="clickFilterItems(67108864, 0)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.modifiedOnТо"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(134217728, 0)">
        <base-select
          label="Тип водомер"
          :items="properties.waterMeterTypes"
          item-text="value"
          item-value="key"
          v-model="filters.waterMeterType"
          clearable
          @click:clear="clearIt(134217728, 0, 'waterMeterType')"
          @click="clickFilterItems(134217728, 0)"
          dense
        >
        </base-select>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(4194304, 0)">
        <base-select
          label="Производител"
          :items="properties.manufacturers"
          item-text="value"
          item-value="key"
          v-model="filters.manufacturer"
          clearable
          @click:clear="clearIt(4194304, 0, 'manufacturer')"
          @click="clickFilterItems(4194304, 0)"
          dense
        >
        </base-select>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(8388608, 0)">
        <base-select
          label="Партидата затворена"
          :items="properties.closedItems"
          item-text="name"
          item-value="value"
          v-model="filters.closed"
          clearable
          @click:clear="clearIt(8388608, 0, 'closed')"
          @click="clickFilterItems(8388608, 0)"
          dense
        >
        </base-select>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(16777216, 0)">
        <v-text-field
          label="Клиент име"
          v-model="filters.clientName"
          autocomplete="off"
          clearable
          @click:clear="clearIt(16777216, 0, 'clientName')"
          @click="clickFilterItems(16777216, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(33554432, 0)">
        <v-text-field
          label="Клиент EGN/Bulstat"
          v-model="filters.clientEGN"
          autocomplete="off"
          clearable
          @click:clear="clearIt(33554432, 0, 'clientEGN')"
          @click="clickFilterItems(33554432, 0)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(67108864, 0)">
        <base-select
          label="Клиент тип"
          :items="properties.clientTypes"
          item-text="value"
          item-value="key"
          v-model="filters.clientTypeId"
          clearable
          @click:clear="clearIt(67108864, 0, 'clientTypeId')"
          @click="clickFilterItems(67108864, 0)"
          dense
        >
        </base-select>
      </v-col>
      <v-col
        v-if="properties.districts.length > 0"
        lg="2"
        md="4"
        cols="6"
        v-show="getVisibility(134217728, 0)"
      >
        <base-select
          label="Област"
          :items="properties.districts"
          item-text="value"
          item-value="key"
          v-model="filters.district"
          clearable
          @click:clear="clearIt(134217728, 0, 'district')"
          @click="clickFilterItems(134217728, 0)"
          dense
        >
        </base-select>
      </v-col>
      <v-col
        v-if="properties.municipalities.length > 0"
        lg="2"
        md="4"
        cols="6"
        v-show="getVisibility(268435456, 0)"
      >
        <base-select
          label="Община"
          :items="properties.municipalities"
          item-text="value"
          item-value="key"
          v-model="filters.municipality"
          clearable
          @click:clear="clearIt(268435456, 0, 'municipality')"
          @click="clickFilterItems(268435456, 0)"
          dense
        >
        </base-select>
      </v-col>

      <v-col lg="2" md="4" cols="6" v-show="getVisibility(536870912, 0)">
        <base-select
          label="Град"
          :items="properties.townGraoCodes"
          item-text="value"
          item-value="key"
          v-model="filters.townGraoCode"
          clearable
          @click:clear="clearIt(536870912, 0, 'townGraoCode')"
          @click="clickFilterItems(536870912, 0)"
          dense
        >
        </base-select>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(1073741824, 0)">
        <base-select
          label="Улица"
          :items="properties.streets"
          item-text="value"
          item-value="key"
          v-model="filters.street"
          clearable
          @click:clear="clearIt(1073741824, 0, 'street')"
          @click="clickFilterItems(1073741824, 0)"
          dense
        >
        </base-select>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(0, 1)">
        <v-text-field
          label="Блок"
          v-model="filters.block"
          autocomplete="off"
          clearable
          @click:clear="clearIt(0, 1, 'block')"
          @click="clickFilterItems(0, 1)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(0, 2)">
        <v-text-field
          label="Вход"
          v-model="filters.entrance"
          autocomplete="off"
          clearable
          @click:clear="clearIt(0, 2, 'entrance')"
          @click="clickFilterItems(0, 2)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(0, 4)">
        <v-text-field
          label="Етаж"
          v-model="filters.floor"
          autocomplete="off"
          clearable
          @click:clear="clearIt(0, 4, 'floor')"
          @click="clickFilterItems(0, 4)"
          dense
        />
      </v-col>

      <v-col lg="2" md="4" cols="6" v-show="getVisibility(0, 8)">
        <v-text-field
          label="Апартамент"
          v-model="filters.apartment"
          autocomplete="off"
          clearable
          @click:clear="clearIt(0, 8, 'apartment')"
          @click="clickFilterItems(0, 8)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(0, 16)">
        <v-text-field
          label="Пощенски код"
          v-model="filters.zipCode"
          autocomplete="off"
          clearable
          @click:clear="clearIt(0, 16, 'zipCode')"
          @click="clickFilterItems(0, 16)"
          dense
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(0, 32)">
        <v-menu
          v-model="menuAddressCreatedOnFrom"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата на създаване на адреса от"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.addressCreatedOnFrom"
              clearable
              @click:clear="clearIt(0, 32, 'addressCreatedOnFrom')"
              @click="clickFilterItems(0, 32)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.addressCreatedOnFrom"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(0, 64)">
        <v-menu
          v-model="menuAddressCreatedOnТо"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата на създаване на адреса до"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.addressCreatedOnТо"
              clearable
              @click:clear="clearIt(0, 64, 'addressCreatedOnТо')"
              @click="clickFilterItems(0, 64)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.addressCreatedOnТо"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(0, 128)">
        <v-menu
          v-model="menuAddressModifiedOnFrom"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата на последна промяна на адреса от"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.addressModifiedOnFrom"
              clearable
              @click:clear="clearIt(0, 128, 'addressModifiedOnFrom')"
              @click="clickFilterItems(0, 128)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.addressModifiedOnFrom"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(0, 256)">
        <v-menu
          v-model="menuAddressModifiedOnТо"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата на последна промяна на адреса до"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.addressModifiedOnТо"
              clearable
              @click:clear="clearIt(0, 256, 'addressModifiedOnТо')"
              @click="clickFilterItems(0, 256)"
              dense
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.addressModifiedOnТо"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="3" cols="6" v-show="getVisibility(512, 512)">
        <v-text-field
          label="Карнет №"
          v-model="filters.addressCarnetNo"
          autocomplete="off"
          clearable
          append-outer-icon="/"
          @click:clear="clearIt(512, 512, 'addressCarnetNo')"
          @click="clickFilterItems(512, 512)"
          dense
        />
      </v-col>
      <v-col lg="2" md="3" cols="6" v-show="getVisibility(1024, 1024)">
        <v-text-field
          label="Страница за адреса"
          v-model="filters.addressCarnetPage"
          autocomplete="off"
          clearable
          dense
          @click:clear="clearIt(1024, 1024, 'addressCarnetPage')"
          @click="clickFilterItems(1024, 1024)"
        />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(0, 2048)">
        >
        <v-text-field
          label="Географски координати за адрес"
          v-model="filters.addressGeoRegion"
          autocomplete="off"
          clearable
          placeholder="[(latitudeFrom, longitudeFrom) - (latitudeTo, longitudeTo)]"
          @click:clear="clearIt(0, 2048, 'addressGeoRegion')"
          @click="clickFilterItems(0, 2048)"
          dense
        />
      </v-col>
    </v-row>
  </base-material-filter>
</template>

<script>
import { isEmptyObject } from "@/utils";
import {
  getWaterMeterTypesAsKeyValuePairList,
  getManufacturersAsKeyValuePairList,
  getClientTypesAsKeyValuePairList,
  getDistrictsAsKeyValuePairList,
  getMunicipalitiesAsKeyValuePairList,
  getTownGraoCodesAsKeyValuePairList,
  getStreetsAsKeyValuePairList,
  getWaterMeterOwnershipTypesAsKeyValuePairList,
} from "@/api/gis_nomenclatures";

const filtersModel = Object.freeze({
  waterMeterFabricNo: null,
  geoRegion: null,
  lastDateOfRepairFrom: null,
  lastDateOfRepairТо: null,
  lastDateOfCheckFrom: null,
  lastDateOfCheckТо: null,
  checkValueFrom: null,
  checkValueTo: null,
  nextDateOfCheckFrom: null,
  nextDateOfCheckТо: null,
  customersTotalFrom: null,
  customersTotalТо: null,
  apartamentsTotalFrom: null,
  apartamentsTotalTo: null,
  createdOnFrom: null,
  createdOnТо: null,
  modifiedOnFrom: null,
  modifiedOnТо: null,
  waterMeterTypeCode: null,
  waterMeterOwnershipTypeId: null,
  manufacturerCode: null,
  clientNumber: null,
  isGeocoded: null,
  clientName: null,
  clientEGN: null,
  clientTypeId: null,
  district: null,
  municipality: null,
  townGraoCode: null,
  street: null,
  block: null,
  entrance: null,
  floor: null,
  apartment: null,
  zipCode: null,
  addressCreatedOnFrom: null,
  addressCreatedOnТо: null,
  addressModifiedOnFrom: null,
  addressModifiedOnТо: null,
  addressCarnetNo: null,
  addressCarnetPage: null,
  addressGeoRegion: null,
});

export default {
  name: "waterMetersFilter",

  data() {
    return {
      properties: {
        waterMeterTypes: [],
        manufacturers: [],
        clientTypes: [],
        districts: [],
        municipalities: [],
        townGraoCodes: [],
        streets: [],
        waterMeterOwnershipTypes: [],
      },
      filters: Object.assign({}, filtersModel),

      hasActiveFilters: false,

      menuLastDateOfRepairFrom: false,
      menuLastDateOfRepairТо: false,
      menuLastDateOfCheckFrom: false,
      menuLastDateOfCheckТо: false,
      menuNextDateOfCheckFrom: false,
      menuNextDateOfCheckТо: false,
      menuCreatedOnFrom: false,
      menuCreatedOnТо: false,
      menuModifiedOnFrom: false,
      menuModifiedOnТо: false,
      menuAddressCreatedOnFrom: false,
      menuAddressCreatedOnТо: false,
      menuAddressModifiedOnFrom: false,
      menuAddressModifiedOnТо: false,

      pagination: {
        itemsPerPage: 10,
        page: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 10,
      },

      itemSelectedMarker1: 0,
      itemSelectedMarker2: 0,
      itemVisibilityMarker1: 65535, //itemVisibilityMarker1, itemVisibilityMarker2 are in block when one bit is up this control is visible, else - is hidden
      itemVisibilityMarker2: 0, //this data comes from parent, for adjusting the filter elements visibility
    };
  },

  created() {
    this.getWaterMeterTypesList();
    this.getManufacturersList();
    this.getClientTypesList();
    this.getDistrictsList();
    this.getMunicipalitiesList();
    this.getStreetsList();
    this.getWaterMeterOwnershipTypesList();
  },

  methods: {
    getWaterMeterTypesList() {
      getWaterMeterTypesAsKeyValuePairList().then((data) => {
        this.properties.waterMeterTypes = data;
      });
    },

    getManufacturersList() {
      getManufacturersAsKeyValuePairList().then((data) => {
        this.properties.manufacturers = data;
      });
    },

    getClientTypesList() {
      getClientTypesAsKeyValuePairList().then((data) => {
        this.properties.clientTypes = data;
      });
    },

    getDistrictsList() {
      getDistrictsAsKeyValuePairList().then((data) => {
        this.properties.districts = data;
      });
    },

    getMunicipalitiesList() {
      getMunicipalitiesAsKeyValuePairList().then((data) => {
        this.properties.municipalities = data;
      });
    },

    getTownGraoCodesList() {
      getTownGraoCodesAsKeyValuePairList().then((data) => {
        this.properties.townGraoCodes = data;
      });
    },

    getStreetsList() {
      getStreetsAsKeyValuePairList().then((data) => {
        this.properties.streets = data;
      });
    },

    getWaterMeterOwnershipTypesList() {
      getWaterMeterOwnershipTypesAsKeyValuePairList().then((data) => {
        this.properties.waterMeterOwnershipTypes = data;
      });
    },

    doFilter() {
      if (!isEmptyObject(this.filters)) {
        this.filters.page = 1;
        this.hasActiveFilters = true;
        this.$emit("doFilter");
        delete this.filters.page;
      } else {
        if (this.hasActiveFilters === true) this.$emit("doFilter");

        this.hasActiveFilters = false;
      }
    },

    clearFilters() {
      this.filters = Object.assign({}, filtersModel);

      this.hasActiveFilters = false;

      this.$emit("doFilter", true);
    },

    getFilters() {
      this.filters;
    },

    clickFilterItems(item1, item2) {
      this.itemSelectedMarker1 = this.itemSelectedMarker1 | item1;
      this.itemSelectedMarker2 = this.itemSelectedMarker2 | item2;
    },

    clearIt(item1, item2, propName) {
      this.itemSelectedMarker1 = this.itemSelectedMarker1 - item1;
      this.itemSelectedMarker2 = this.itemSelectedMarker2 - item2;
      this.filters[propName] = null;
    },

    getVisibility(item1, item2) {
      var visibilityMarker1 = this.itemVisibilityMarker1 & item1;
      var visibilityMarker2 = this.itemVisibilityMarker2 & item2;

      return visibilityMarker1 > 0 || visibilityMarker2 > 0;
    },

    filterSettings() {
      this.$filterSettingsModal.showModal();
    },
  },
};
</script>
