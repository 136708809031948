<template>
    <div id="map" style="height: 94%">
        <sidebar-layers :wsmlayers="wsmlayers" @toggle-wms-layer="wmsLayerChanged"></sidebar-layers>
    </div>
</template>

<script>
import MapboxGeocoder from "@maplibre/maplibre-gl-geocoder";
import "@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";
import GeocodeAPI from "@/components/services/GeocodeAPI.js";

import maplibregl, { Map, Marker } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import constants from "@/utils/constants";

import { getMapWMSLayers } from "@/api/map"

export default {
    name: "mapbox",
    components: {
        sidebarLayers: () => import('./sidebarLayers.vue')
    },
    props: {
        mapData: {
            type: Array,
            default: () => []
        },
        currentWatermeter: {
            type: Object,
            default: () => { }
        },
        selectedData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            map: Map,
            marker: Marker,
            highlightedId: -1,
            highlightedSrc: "",
            highlightedSrcLayer: "",
            geocodeApi: new GeocodeAPI(),
            wsmlayers: []
        }
    },
    watch: {
        currentWatermeter: {
            handler(val) {
                this.marker.remove();
                this.flyToObject(val)
            }
        }
    },
    computed: {

    },
    mounted() {
        this.map = new maplibregl.Map(constants.MAP_INIT_LAYER);

        this.map.once('render', () => {
            this.map.resize();
            this.addMapData();
            this.flyToObject(this.currentWatermeter);
        });

        const geocoder_api = this.geocodeApi.getApiSource();
        const options = constants.GEOCODER_OPTIONS;
        options.maplibregl = maplibregl;
        const geocoderContainer = new MapboxGeocoder(geocoder_api, options);
        this.map.addControl(geocoderContainer);
    },
    created() {
        getMapWMSLayers().then((res) => {
            //Manualy add main layer
            this.wsmlayers.push({
                id: "OSM",
                text: 'Основна карта',
                show: true,
                zoomExtent: 0,
                selected: true,
            });

            res.forEach((x) => {
                // Populate radio button component
                this.wsmlayers.push({
                    id: x.secondary_id,
                    text: x.name,
                    show: x.show,
                    zoomExtent: x.zoom_extent,
                    selected: false,
                });

                //Add provided WMS layers to the map
                this.map.addSource(x.secondary_id, {
                    type: x.type,
                    tiles: [x.url],
                    tileSize: 256,
                });
                this.map.addLayer({
                    id: x.secondary_id,
                    type: x.type,
                    source: x.secondary_id,
                    metadata: {},
                    paint: {},
                    layout: {
                        visibility: "none",
                    },
                });
            });
        });
    },
    methods: {
        addMapData() {
            this.mapData.forEach((x) => {
                const sourceObj = JSON.parse(x.source.sourceObject);
                const layout = JSON.parse(x.style.layout);
                const paint = JSON.parse(x.style.paint);

                if (!this.map.getSource(x.source.sourceName)) {
                    this.map.addSource(x.source.sourceName, sourceObj);
                }

                if (!this.map.getLayer(x.layer.layerName)) {
                    const layerObj = {
                        id: x.layer.layerName,
                        source: x.source.sourceName,
                        type: x.layer.layerType.name,
                        layout: layout,
                        paint: paint,
                        minzoom: x.layer.minZoom ?? 1,
                        maxzoom: x.layer.maxZoom ?? 24,
                    };

                    if (x.layer.sourceLayer) {
                        layerObj["source-layer"] = x.layer.sourceLayer
                    }

                    this.map.addLayer(layerObj);

                    if (x.selectable) {
                        this.map.on("mouseenter", x.layer.layerName, (e) => {
                            this.mEnter(e);
                        });
                        this.map.on("mouseleave", x.layer.layerName, (e) => {
                            this.mLeave(e);
                        });
                        this.map.on("mousedown", x.layer.layerName, (e) => {
                            this.mDown(e);
                        });
                    }
                }
            });
        },
        mEnter(e) {
            this.map.getCanvas().style.cursor = "pointer";
            if (e.features && e.features.length > 0) {
                const el = e.features[0];
                if (this.highlightedId > -1 && this.highlightedSrc !== "") {
                    this.map.setFeatureState(
                        {
                            source: el.source,
                            id: el.id,
                            sourceLayer: el.sourceLayer,
                        },
                        { highlighted: false }
                    );
                }
                this.highlightedId = el.id;
                this.highlightedSrc = el.source;
                this.highlightedSrcLayer = el.sourceLayer;
                this.map.setFeatureState(
                    { source: el.source, id: el.id, sourceLayer: el.sourceLayer },
                    { highlighted: true }
                );
            }
        },
        mLeave() {
            this.map.getCanvas().style.cursor = "";
            if (this.highlightedId > -1) {
                this.map.setFeatureState(
                    {
                        source: this.highlightedSrc,
                        id: this.highlightedId,
                        sourceLayer: this.highlightedSrcLayer,
                    },
                    { highlighted: false }
                );
            }
            this.highlightedId = -1;
            this.highlightedSrc = "";
            this.highlightedSrcLayer = "";
        },
        mDown(e) {
            this.$emit("setInfoData", e.features[0]);
            if (e.features.length > 0) {
                const el = e.features[0];
                this.map.setFeatureState(
                    {
                        source: el.source,
                        id: this.selectedData.svoID,
                        sourceLayer: el.sourceLayer,
                    },
                    { selected: false }
                );
                this.selectedData.svoID = el.properties.osm_id;
                this.selectedData.source = el.source;
                this.map.setFeatureState(
                    {
                        source: el.source,
                        id: this.selectedData.svoID,
                        sourceLayer: el.sourceLayer,
                    },
                    { selected: true }
                );
            }

        },
        flyToObject(watermeter) {
            if (watermeter.address.longitude && watermeter.address.latitude) {
                this.map.flyTo({
                    center: [watermeter.address.longitude, watermeter.address.latitude],
                });
                const layers = this.map.getStyle().layers.filter(l => l.id.includes('watermeter') && l.type === 'circle');
                layers.forEach(l => {
                    this.map.setFeatureState(
                        {
                            source: l.source,
                            id: watermeter.address.id,
                            sourceLayer: l['source-layer'],
                        },
                        { selected: true }
                    );
                });

                this.selectedData.watermeterID = watermeter.id;
                this.selectedData.markerLat = watermeter.address.latitude;
                this.selectedData.markerLng = watermeter.address.longitude;

                this.marker = new maplibregl.Marker({
                    draggable: true
                }).setLngLat([
                    watermeter.address.longitude,
                    watermeter.address.latitude,
                ]).addTo(this.map)

                this.marker.on('dragend', this.onDragEnd);
            }
        },
        onDragEnd(marker) {
            const lngLat = marker.target._lngLat;
            this.selectedData.markerLng = lngLat.lng;
            this.selectedData.markerLat = lngLat.lat;
        },
        wmsLayerChanged(selectedLayers) {
            this.wsmlayers.forEach((x, i) => {
                const hasLayer = this.map.getLayer(x.id);
                if (hasLayer) {
                    if (selectedLayers.includes(x.id)) {
                        this.map.setLayoutProperty(x.id, "visibility", "visible");
                        this.wsmlayers[i]["selected"] = true;
                    }
                    else {
                        this.map.setLayoutProperty(x.id, "visibility", "none");
                        this.wsmlayers[i]["selected"] = false;
                    }
                }
                else if (!hasLayer && x.id === 'OSM') {
                    const baseMapLayers = this.map.getStyle().layers.filter(x => x.source === 'composite');
                    if (selectedLayers.includes(x.id)) {
                        baseMapLayers.forEach(l => {
                            this.map.setLayoutProperty(l.id, "visibility", "visible");
                        });
                        this.wsmlayers[i]["selected"] = true;
                    }
                    else {
                        baseMapLayers.forEach(l => {
                            this.map.setLayoutProperty(l.id, "visibility", "none");
                        });
                        this.wsmlayers[i]["selected"] = false;
                    }
                }
            });
        }
    }
}
</script>