<template>
  <v-container id="usersList"
               ref="usersList"
               fluid
               tag="section"
               class="full-height">
    <base-v-component heading="Потребители" />

    <base-material-card hover-reveal
                        color="primary"
                        icon="mdi-account"
                        inline
                        class="px-5 py-5 mt-15"
                        style="border-top-right-radius: 30px; border-bottom-right-radius: 30px; border-bottom-left-radius: 30px;">

      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn color="success" @click="onAddUser" class="successGradient">
            <v-icon>mdi-plus</v-icon>
            Добави потребител
          </v-btn>
        </v-col>
      </v-row>

      <base-data-table :headers="table.headers"
                       :data="table.data"
                       @getData="getData"
                       @doAction="doTableAction"
                       :inlineSearch="true"
                       :hasPaging="false" />

    </base-material-card>
  </v-container>
</template>

<script>

  import { activateUserKeycloak, deactivateUserKeycloak, getAllUsersKeycloak } from "@/api/users";

  export default {
    name: "usersList",
    components: {
    },
    data() {
      return {
        table: {
          search: '',
          headers: [
            { text: 'Потребител', value: 'username', valueType: "text" },
            { text: 'Е-поща', value: 'email', valueType: "text" },
            { text: 'Име', value: 'firstName', valueType: "text" },
            { text: 'Фамилия', value: 'lastName', valueType: "text" },
            {
              sortable: false, text: '',
              value: 'actions',
              valueType: "button",
              class: 'text-center cols cols-4',
              style: 'width:350px; padding: 10px;',
              buttons: [
                {
                  title: 'Роли',
                  label: 'Роли',
                  icon: 'mdi-align-horizontal-right',
                  size: 'x-small',
                  class: 'success',
                  click: 'editUserRole',
                  id: 'editUserRole',
                  gradient: 'successGradient',
                  style: 'width: 20px !important;height: 30px !important;padding: unset!important; margin-right: 5px !important;'
                },
                {
                  title: 'Групи',
                  label: 'Групи',
                  icon: 'mdi-account-group',
                  size: 'x-small',
                  class: 'success',
                  click: 'editUserGroup',
                  id: 'editUserGroup',
                  gradient: 'successGradient',
                  style: 'width: 20px !important;height: 30px !important;padding: unset!important; margin-right: 5px !important;'
                },
                {
                  title: 'Активен',
                  icon: 'mdi-lock-open-check',
                  size: 'x-small',
                  class: 'success',
                  click: 'deactivateUser',
                  id: 'deactivateUser',
                  gradient: 'successGradient',
                  style: 'width: 20px !important;height: 30px !important;padding: unset!important; margin-right: 5px !important;',
                  if: { item: "enabled", value: true, type: 'equal' },
                },
                {
                  title: 'Неактивен',
                  icon: 'mdi-lock-open-remove',
                  size: 'x-small',
                  class: 'error',
                  click: 'activateUser',
                  id: 'activateUser',
                  gradient: 'unactiveGradient',
                  style: 'width: 20px !important;height: 30px !important;padding: unset!important; margin-right: 5px !important;',
                  if: { item: "enabled", value: false, type: 'equal' },
                },
                {
                  title: 'Редакция',
                  icon: 'mdi-pencil-outline',
                  size: 'x-small',
                  class: 'success',
                  click: 'editUser',
                  id: 'editUser',
                  gradient: 'errorGradient',
                  style: 'width: 20px !important;height: 30px !important;padding: unset!important; margin-right: 5px !important;'
                },
              ],
            }
          ],
          data: [],
        }

      }
    },
    watch: {
      '$route.path': 'getData'
    },
    computed: {},
    mounted() {
      let vue = this;
      vue.getData();
    },
    created() { },
    methods: {
      getData() {
        getAllUsersKeycloak().then((data) => {
          this.table.data = data;
          this.$forceUpdate();
        });
      },
      onAddUser() {
        this.$router.push({ path: `/administration/adduser` })
      },
      doTableAction(actionData) {
        switch (actionData.action) {
          case 'editUser':
            this.$router.push({ path: `/administration/editUser/${actionData.item.id}` })
            break;
          case 'deactivateUser':
            this.deactivateUser(actionData.item)
            break;
          case 'activateUser':
            this.activateUser(actionData.item)
            break;
          case 'editUserRole':
            this.$router.push({ path: `/administration/editUserRole/${actionData.item.id}` })
            break;
          case 'editUserGroup':
            this.$router.push({ path: `/administration/editUserGroup/${actionData.item.id}` })
            break;
        }
      },
      deactivateUser(item) {
        if (confirm(`Сигурни ли сте, че искате да деактивирате потребител ${this.table.data.filter(x => x.id === item.id)[0].username}?`)) {
          item.enabled = false;
          deactivateUserKeycloak(item).then(() => {
            this.$snotify.success('Успешно деактивиран потребител');
            this.$forceUpdate();
          });
        }
      },
      activateUser(item) {
        if (confirm(`Сигурни ли сте, че искате да активирате потребител ${this.table.data.filter(x => x.id === item.id)[0].username}?`)) {
          item.enabled = true;
          activateUserKeycloak(item).then(() => {
            this.$snotify.success('Успешно активиран потребител');
            this.$forceUpdate();
          });
        }
      },
    }
  };
</script>
