<template>
  <base-material-filter
    style="border-top-right-radius: 30px;"
    title="Търсене на водомери"
    :hasFilters="hasActiveFilters"
    v-on:do-filter="doFilter()"
    v-on:remove-filter="clearFilters"
  >
    <v-row @keypress.enter="doFilter" class="mx-1">
      <v-col lg="1" md="2" cols="4" v-show="getVisibility(1, 0)">
        <v-text-field label="Водомер №"
                      v-model="filters.waterMeterFabricNo"
                      autocomplete="off"
                      height="30"
                      clearable
                      @click:clear="clearIt(1, 0, 'waterMeterFabricNo')"
                      @click="clickFilterItems(1, 0)"
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(4, 0)">
        <v-menu v-model="menuLastDateOfCheckFrom"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Дата последен отчет от"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.lastDateOfCheckFrom"
                          clearable
                          @click:clear="clearIt(4, 0, 'lastDateOfCheckFrom')"
                          @click="clickFilterItems(4, 0)"
                          dense>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.lastDateOfCheckFrom"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(8, 0)">
        <v-menu v-model="menuLastDateOfCheckTo"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Дата последен отчет до"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.lastDateOfCheckTo"
                          clearable
                          @click:clear="clearIt(8, 0, 'lastDateOfCheckTo')"
                          @click="clickFilterItems(8, 0)"
                          dense>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.lastDateOfCheckTo"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(16, 0)">
        <v-menu v-model="menuNextDateOfCheckFrom"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Дата последен отчет от"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.nextDateOfCheckFrom"
                          clearable
                          @click:clear="clearIt(16, 0, 'nextDateOfCheckFrom')"
                          @click="clickFilterItems(16, 0)"
                          dense>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.nextDateOfCheckFrom"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(32, 0)">
        <v-menu v-model="menuNextDateOfCheckTo"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Дата следващ отчет до"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.nextDateOfCheckTo"
                          clearable
                          @click:clear="clearIt(32, 0, 'nextDateOfCheckTo')"
                          @click="clickFilterItems(32, 0)"
                          dense>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.nextDateOfCheckTo"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(64, 0)">
        <v-menu v-model="menuLastDateOfRepairFrom"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Дата последен ремонт от"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.lastDateOfRepairFrom"
                          clearable
                          @click:clear="clearIt(64, 0, 'lastDateOfRepairFrom')"
                          @click="clickFilterItems(64, 0)"
                          dense>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.lastDateOfRepairFrom"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="1" md="2" cols="3" v-show="getVisibility(128, 0)">
        <v-menu v-model="menuLastDateOfRepairTo"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Дата последен ремонт до"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.LastDateOfRepairTo"
                          clearable
                          @click:clear="clearIt(128, 0, 'LastDateOfRepairTo')"
                          @click="clickFilterItems(128, 0)"
                          dense>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.LastDateOfRepairTo"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </base-material-filter>
</template>

<script>
import { isEmptyObject } from "@/utils";

const filtersModel = Object.freeze({
  waterMeterFabricNo: null,
  geoRegion: null,
  lastDateOfRepairFrom: null,
  LastDateOfRepairTo: null,
  lastDateOfCheckFrom: null,
  lastDateOfCheckTo: null,
  checkValueFrom: null,
  checkValueTo: null,
  nextDateOfCheckFrom: null,
  nextDateOfCheckTo: null,
  customersTotalFrom: null,
  customersTotalТо: null,
  apartamentsTotalFrom: null,
  apartamentsTotalTo: null,
  createdOnFrom: null,
  createdOnТо: null,
  modifiedOnFrom: null,
  modifiedOnТо: null,
  waterMeterTypeCode: null,
  waterMeterOwnershipTypeId: null,
  manufacturerCode: null,
  clientNumber: null,
  clientName: null,
  clientEGN: null,
  clientTypeId: null,
  district: null,
  municipality: null,
  townGraoCode: null,
  street: null,
  block: null,
  entrance: null,
  floor: null,
  apartment: null,
  zipCode: null,
  addressCreatedOnFrom: null,
  addressCreatedOnТо: null,
  addressModifiedOnFrom: null,
  addressModifiedOnТо: null,
  addressCarnetNo: null,
  addressCarnetPage: null,
  addressGeoRegion: null,
});

export default {
  name: "clientsWaterMetrsFilter",

  data() {
    return {
      properties: {},

      filters: Object.assign({}, filtersModel),

      hasActiveFilters: false,

      menuLastDateOfCheckFrom: false,
      menuLastDateOfCheckTo: false,
      menuLastDateOfRepairFrom: false,
      menuLastDateOfRepairTo: false,
      menuNextDateOfCheckFrom: false,
      menuNextDateOfCheckTo: false,

      pagination: {
        itemsPerPage: 10,
        page: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 10,
      },

      itemSelectedMarker1: 0,
      itemSelectedMarker2: 0,
      itemVisibilityMarker1: 65535, //itemVisibilityMarker1, itemVisibilityMarker2 are in block when one bit is up this control is visible, else - is hidden
      itemVisibilityMarker2: 0, //this data comes from parent, for adjusting the filter elements visibility
    };
  },

  created() {},

  methods: {
    doFilter() {
      if (!isEmptyObject(this.filters)) {
        this.filters.page = 1;
        this.hasActiveFilters = true;
        this.$emit("doFilter");
        delete this.filters.page;
      } else {
        if (this.hasActiveFilters === true) this.$emit("doFilter");

        this.hasActiveFilters = false;
      }
    },

    clearFilters() {
      this.filters = Object.assign({}, filtersModel);

      this.hasActiveFilters = false;

      this.$emit("doFilter", true);
    },

    getFilters() {
      this.filters;
    },

    clickFilterItems(item1, item2) {
      this.itemSelectedMarker1 = this.itemSelectedMarker1 | item1;
      this.itemSelectedMarker2 = this.itemSelectedMarker2 | item2;
    },

    clearIt(item1, item2, propName) {
      this.itemSelectedMarker1 = this.itemSelectedMarker1 - item1;
      this.itemSelectedMarker2 = this.itemSelectedMarker2 - item2;
      this.filters[propName] = null;
    },

    getVisibility(item1, item2) {
      var visibilityMarker1 = this.itemVisibilityMarker1 & item1;
      var visibilityMarker2 = this.itemVisibilityMarker2 & item2;

      return visibilityMarker1 > 0 || visibilityMarker2 > 0;
    },

    filterSettings() {
      this.$filterSettingsModal.showModal();
    },
  },
};
</script>
