import request from '@/utils/request';
import { filterQuery } from '@/utils/index';

export function getNomenclaturesList(query) {
  return request({
    url: '/api/nomenclatures/GetNomenclaturesList',
    params: filterQuery(query)
  })
}

export function getNomenclatureByID(id) {
  return request({
    url: '/api/nomenclatures/GetNomenclatureById?id=' + id,
    method: 'get'
  })
}

export function createNomenclature(data) {
  return request({
    url: '/api/nomenclatures/CreateNomenclature',
    method: 'post',
    data: data
  })
}

export function updateNomenclature(data) {
  return request({
    url: '/api/nomenclatures/UpdateNomenclature',
    method: 'post',
    data: data
  })
}

export function changeActivateNomenclatureStatus(data) {
  return request({
    url: '/api/nomenclatures/UpdateNomenclature',
    method: 'post',
    data: data
  })
}
 
export function getItemNomenclatureList(nomenclatureId, query) {
   return request({
    url: '/api/nomenclatures/getItemsNomenclatureList?nomenclatureId=' + nomenclatureId,
    params: filterQuery(query)
  })
}

export function getItemNomenclatureByNomenclatureIdByItemNomenclatureId(nomenclatureId, itemNomenclatureid) {
  return request({
    url: '/api/nomenclatures/GetItemNomenclatureByNomenclatureIdByItemNomenclatureId?nomenclatureId=' + nomenclatureId + '&itemNomenclatureid=' + itemNomenclatureid,
    method: 'get'
  })
}

export function changeActivateItemNomenclatureStatus(data) {
  return request({
    url: '/api/nomenclatures/UpdateItemNomenclature',
    method: 'post',
    data: data
  })
}

export function updateItemNomenclature(nomenclatureId, data) {
  return request({
    url: '/api/nomenclatures/updateItemNomenclature?nomenclatureId=' + nomenclatureId,
    method: 'post',
    data: data
  })
}

export function createItemNomenclature(nomenclatureId, data) {
  return request({
    url: '/api/nomenclatures/createItemNomenclature?nomenclatureId=' + nomenclatureId,
    method: 'post',
    data: data
  })
}

export function getWaterMeterTypesAsKeyValuePairList() {
  return request({
    url: '/api/WaterMeter/getWaterMeterTypesAsKeyValuePairList',
    method: 'get',
  });
}

export function getManufacturersAsKeyValuePairList() {
  return request({
    url: '/api/WaterMeter/getManufacturersAsKeyValuePairList',
    method: 'get',
  });
}

export function getClientTypesAsKeyValuePairList() {
  return request({
    url: '/api/WaterMeter/getClientTypesAsKeyValuePairList',
    method: 'get',
  });
}

export function getDistrictsAsKeyValuePairList() {
  return request({
    url: '/api/WaterMeter/getDistrictsAsKeyValuePairList',
    method: 'get',
  });
}

export function getMunicipalitiesAsKeyValuePairList() {
  return request({
    url: '/api/WaterMeter/getMunicipalitiesAsKeyValuePairList',
    method: 'get',
  });
}

export function getTownGraoCodesAsKeyValuePairList() {
  return request({
    url: '/api/WaterMeter/getTownGraoCodesAsKeyValuePairList',
    method: 'get',
  });
}

export function getStreetsAsKeyValuePairList() {
  return request({
    url: '/api/WaterMeter/getStreetsAsKeyValuePairList',
    method: 'get',
  });
}

export function getAddressesAsKeyValuePairList() {
  return request({
    url: '/api/WaterMeter/getAddressesAsKeyValuePairList',
    method: 'get',
  });
}

export function getClientsAsKeyValuePairList() {
  return request({
    url: '/api/WaterMeter/getClientsAsKeyValuePairList',
    method: 'get',
  });
}

export function getCarnetsAsKeyValuePairList() {
  return request({
    url: '/api/WaterMeter/getCarnetsAsKeyValuePairList',
    method: 'get',
  });
}
 
export function getWaterMeterOwnershipTypesAsKeyValuePairList() {
  return request({
    url: '/api/WaterMeter/getWaterMeterOwnershipTypesAsKeyValuePairList',
    method: 'get',
  });
}

export function getAddressPropertyTypesAsKeyValuePairList() {
  return request({
    url: '/api/WaterMeter/getAddressPropertyTypesAsKeyValuePairList',
    method: 'get',
  });
}


