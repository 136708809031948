<template>
  <v-card
    class="px-5 v-card--filter"
    :flat="flat"
    :class="[
      {
        'v-card--has-filter': hasFilters,
        'mb-15': marginBottom,
      },
      cardClass,
    ]"
  >
    <div class="pt-6" v-if="header">
      <v-icon color="primary" x-large> mdi-filter-variant </v-icon>
      <h2 class="display-2 font-weight-light" v-text="title" />
    </div>

    <v-row>
      <slot />
    </v-row>
    <v-row justify="center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="successGradient"
            v-bind="attrs"
            v-on="on"
            @click="doFilter()"
          >
            <v-icon size="20">mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>Приложи филтрите</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="hasFilters"
            v-bind="attrs"
            v-on="on"
            title="Премахни филтрите"
            @click="clearFilters()"
            color="errorGradient"
          >
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Изчисти филтрите</span>
      </v-tooltip>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "BaseMaterialFilter",
  data() {
    return {
      isSearchedByBreakdown: false,
    };
  },
  created() {},
  props: {
    title: {
      type: String,
      default: "",
    },
    hasFilters: {
      type: Boolean,
      default: false,
    },
    cardClass: {
      type: String,
      default: "",
    },
    flat: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: true,
    },
    doFilterWithEmptyFields: {
      type: Boolean,
      default: false,
    },
    marginBottom: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    doFilter() {
      this.$emit("do-filter", this.doFilterWithEmptyFields);
    },
    clearFilters() {
      this.$emit("remove-filter");
      this.isSearchedByBreakdown = false;
    },
    searchByBreakdownId() {
      this.isSearchedByBreakdown = true;
      this.$emit("search-by-breakdown-Id");
    },
  },
};
</script>

<style lang="sass">
.v-card--filter
  padding-bottom: 72px
  margin-bottom: 64px

  h2
    display: inline-block
    margin-left: 10px

    .v-btn.success
      position: absolute
      left: calc(50% - 50px)
      top: calc(100% - 54px)

  .v-card--filter.v-card--has-filter
    .v-btn.success
      left: calc(50% - 125px)

    .v-btn.error
      position: absolute
      left: calc(50% - -30px)
      top: calc(100% - 54px)
</style>
