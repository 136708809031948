<template>
  <div v-html="svg" class="aq-iconn"></div>
</template>

<script lang="ts">
export default {
  name: "SVGLoader",
  props: {
    iconn: {
      type: String
    }
  },
  data() {
    return {
        svg:
          '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18"><path class="a" d="M2,4A1,1,0,0,1,3,3H21a1,1,0,0,1,0,2H3A1,1,0,0,1,2,4Zm1,9H21a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Zm0,8h9a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Z" transform="translate(-2 -3)"/></svg>',
        iconns: {
          menu:
            '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18"><path class="a" d="M2,4A1,1,0,0,1,3,3H21a1,1,0,0,1,0,2H3A1,1,0,0,1,2,4Zm1,9H21a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Zm0,8h9a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Z" transform="translate(-2 -3)"/></svg>',
          login:
            '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22"><defs></defs><path class="a" d="M20,21V3H13a1,1,0,0,1,0-2h8a1,1,0,0,1,1,1V22a1,1,0,0,1-1,1H13a1,1,0,0,1,0-2ZM2,12a1,1,0,0,0,1,1H14.586l-2.293,2.293a1,1,0,1,0,1.414,1.414l4-4a1,1,0,0,0,0-1.414l-4-4a1,1,0,1,0-1.414,1.414L14.586,11H3A1,1,0,0,0,2,12Z" transform="translate(-2 -1)"/></svg>',
          logout:
            '<svg xmlns="http://www.w3.org/2000/svg" width="26.829" height="24" viewBox="0 0 26.829 24"><path class="a" d="M2.219,26.912h9.756a1.332,1.332,0,0,0,0-2.654H3.526a8.817,8.817,0,0,1,8.45-7.961,6.3,6.3,0,0,0,6.09-5.41,6.819,6.819,0,0,0-3.773-7.505A5.9,5.9,0,0,0,6.821,6.043a7.126,7.126,0,0,0,1.036,8.48A12,12,0,0,0,1,25.585,1.277,1.277,0,0,0,2.219,26.912ZM11.975,5.683a3.83,3.83,0,0,1,3.658,3.98,3.83,3.83,0,0,1-3.658,3.98,3.83,3.83,0,0,1-3.658-3.98,3.83,3.83,0,0,1,3.658-3.98Zm15.3,14.064h-.862a.575.575,0,0,1-.522-.4v-.008A.661.661,0,0,1,26,18.656l.61-.663a.64.64,0,0,0,0-.853l-.768-.836a.523.523,0,0,0-.784,0l-.61.663a.535.535,0,0,1-.629.118h-.007a.621.621,0,0,1-.366-.568v-.938a.631.631,0,0,0-.162-.433.531.531,0,0,0-.4-.176H21.8a.58.58,0,0,0-.554.6v.938a.621.621,0,0,1-.366.568H20.87a.535.535,0,0,1-.629-.118l-.61-.663a.523.523,0,0,0-.784,0l-.768.836a.64.64,0,0,0,0,.853l.61.663a.661.661,0,0,1,.109.685v.008a.575.575,0,0,1-.522.4h-.862a.58.58,0,0,0-.554.6v1.184a.58.58,0,0,0,.554.6h.862a.575.575,0,0,1,.522.4v.008a.661.661,0,0,1-.109.685l-.61.663a.64.64,0,0,0,0,.853l.768.836a.523.523,0,0,0,.784,0l.61-.663a.535.535,0,0,1,.629-.118h.007a.621.621,0,0,1,.366.568V26.3a.58.58,0,0,0,.554.6h1.088a.58.58,0,0,0,.554-.6v-.938a.621.621,0,0,1,.366-.568h.007a.535.535,0,0,1,.629.118l.61.663a.523.523,0,0,0,.784,0l.768-.836a.64.64,0,0,0,0-.853l-.61-.663a.661.661,0,0,1-.109-.685v-.008a.575.575,0,0,1,.522-.4h.862a.578.578,0,0,0,.56-.6V20.349A.58.58,0,0,0,27.275,19.747Zm-4.934,2.985a1.8,1.8,0,1,1,1.646-1.791,1.873,1.873,0,0,1-.482,1.267,1.58,1.58,0,0,1-1.164.524Z" transform="translate(-1 -2.912)"/></svg>',
          airplay:
            '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18"><path class="a" d="M23,4V16a1,1,0,0,1-1,1H18a1,1,0,0,1,0-2h3V5H3V15H6a1,1,0,0,1,0,2H2a1,1,0,0,1-1-1V4A1,1,0,0,1,2,3H22A1,1,0,0,1,23,4ZM7.118,20.472a1,1,0,0,1,.05-1.027l4-6a1.039,1.039,0,0,1,1.664,0l4,6A1,1,0,0,1,16,21H8A1,1,0,0,1,7.118,20.472ZM9.868,19h4.264L12,15.8Z" transform="translate(-1 -3)"/></svg>',
          map:
            '<svg xmlns="http://www.w3.org/2000/svg" width="27.5" height="22.001" viewBox="0 0 27.5 22.001"><g transform="translate(-1 -3.999)"><path class="a" d="M28.115,4.174a.912.912,0,0,0-.825-.128L19.315,6.7l-8.809-2.64A.791.791,0,0,0,10.165,4a.923.923,0,0,0-.291.046L1.623,6.8A.929.929,0,0,0,1,7.667V25.083a.9.9,0,0,0,.385.743A.86.86,0,0,0,1.917,26a1.086,1.086,0,0,0,.293-.046L10.185,23.3l8.883,2.668a.971.971,0,0,0,.559-.009l8.25-2.75a.929.929,0,0,0,.623-.871V4.917a.9.9,0,0,0-.385-.743ZM9.25,21.673,2.833,23.809V8.327L9.25,6.191Zm17.417,0L20.25,23.809V22.333a.917.917,0,0,0-1.833,0v1.522l-7.333-2.2V6.145l7.333,2.2V9.5a.917.917,0,0,0,1.833,0V8.327l6.417-2.136Z" transform="translate(0 0)"/><path class="a" d="M17,16.667a3.671,3.671,0,0,0,3.667,3.667,3.626,3.626,0,0,0,1.851-.519l1.168,1.168a.917.917,0,1,0,1.3-1.3l-1.168-1.168a3.626,3.626,0,0,0,.52-1.851,3.667,3.667,0,0,0-7.333,0Zm5.5,0a1.833,1.833,0,1,1-1.833-1.833A1.833,1.833,0,0,1,22.5,16.667Z" transform="translate(-1.333 -0.75)"/></g></svg>',
          mapEditor:
            '<svg xmlns="http://www.w3.org/2000/svg" width="27.45" height="22" viewBox="0 0 27.45 22"><g transform="translate(0 0)"><g transform="translate(0 0)"><path class="a" d="M27.064.174a.915.915,0,0,0-.823-.128L18.3,2.745,9.488.064A.86.86,0,0,0,9.149,0a1.034,1.034,0,0,0-.293.046L.622,2.791A.915.915,0,0,0,0,3.66V21.044a.915.915,0,0,0,.384.741.86.86,0,0,0,.531.174,1.034,1.034,0,0,0,.293,0l7.96-2.653,8.866,2.653a.97.97,0,0,0,.558,0l8.235-2.745a.915.915,0,0,0,.622-.915V.915a.915.915,0,0,0-.384-.741ZM8.235,17.64l-6.4,2.132V4.319l6.4-2.132Zm17.384,0-6.4,2.132V18.3a.915.915,0,0,0-1.83,0v1.519l-7.32-2.2V2.141l7.32,2.2V5.49a.915.915,0,1,0,1.83,0V4.319l6.4-2.132Z" transform="translate(0 0)"/><path class="a" d="M18.5,14.149l.97-.732a.915.915,0,0,0-1.107-1.427l-.961.741a2.745,2.745,0,1,0,1.1,1.418Zm-3.312,1.436a5.316,5.316,0,1,0,.027,0Z" transform="translate(-1.119 -1.01)"/><path class="a" d="M20.672,10.024l-2.534,1.967a.919.919,0,1,0,1.135,1.446L21.8,11.47a2.745,2.745,0,1,0-1.125-1.446ZM23.984,8.6a.915.915,0,1,1-.613-.347A.915.915,0,0,1,23.984,8.6Z" transform="translate(-1.513 -0.545)"/></g></g></svg>',
          reports:
            '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22"><path class="a" d="M4,23a1,1,0,0,1-1-1V19a1,1,0,0,1,2,0v3A1,1,0,0,1,4,23Zm9-1V15a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Zm7-11a1,1,0,0,0-1,1V22a1,1,0,0,0,2,0V12A1,1,0,0,0,20,11Zm.382-9.923A.991.991,0,0,0,20,1H16a1,1,0,0,0,0,2h1.586L12,8.586,8.707,5.293a1,1,0,0,0-1.414,0l-4,4a1,1,0,1,0,1.414,1.414L8,7.414l3.293,3.293a1,1,0,0,0,1.414,0L19,4.414V6a1,1,0,0,0,2,0V2a1,1,0,0,0-.618-.923Z" transform="translate(-3 -1)"/></svg>',
          history:
            '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="21.986" viewBox="0 0 22 21.986"><path class="a" d="M23,12A11,11,0,0,1,4.963,20.451l-.256.256A1,1,0,0,1,4,21a.987.987,0,0,1-.383-.076A1,1,0,0,1,3,20V18a1,1,0,0,1,1-1H6a1,1,0,0,1,.707,1.707l-.322.322A9,9,0,1,0,3,12a9.1,9.1,0,0,0,.18,1.8,1,1,0,1,1-1.96.4A11,11,0,1,1,23,12ZM12,5a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h5a1,1,0,0,0,0-2H13V6A1,1,0,0,0,12,5Z" transform="translate(-1 -1.011)"/></svg>',
          users:
            '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22"><path class="a" d="M12,11A5,5,0,1,0,7,6,5,5,0,0,0,12,11Zm0-8A3,3,0,1,1,9,6a3,3,0,0,1,3-3ZM4,23H20a1,1,0,0,0,1-1A9,9,0,0,0,3,22,1,1,0,0,0,4,23Zm8-8a7.011,7.011,0,0,1,6.929,6H5.071A7.011,7.011,0,0,1,12,15Z" transform="translate(-3 -1)"/></svg>',
          search:
            '<svg xmlns="http://www.w3.org/2000/svg" width="15.995" height="15.999" viewBox="0 0 15.995 15.999"><path class="a" d="M12.974,3.884a6.425,6.425,0,1,0-2.16,10.511l2.864,2.865a2.532,2.532,0,0,0,3.582-3.579L14.4,10.817a6.437,6.437,0,0,0-1.422-6.933ZM8.429,13.256a4.827,4.827,0,1,1,3.413-1.415,4.827,4.827,0,0,1-3.413,1.415Zm7.7,1.554a.932.932,0,0,1-1.318,1.318l-2.549-2.549a6.572,6.572,0,0,0,.712-.606,6.572,6.572,0,0,0,.606-.712Z" transform="translate(-2.006 -2.002)"/></svg>',
          close:
            '<svg xmlns="http://www.w3.org/2000/svg" width="16.25" height="16.25" viewBox="0 0 16.25 16.25"><path class="a" d="M9.125,17.25A8.125,8.125,0,1,0,1,9.125,8.125,8.125,0,0,0,9.125,17.25Zm0-14.773A6.648,6.648,0,1,1,2.477,9.125,6.648,6.648,0,0,1,9.125,2.477ZM6.387,10.819,8.081,9.125,6.387,7.431A.739.739,0,1,1,7.431,6.387L9.125,8.081l1.694-1.694a.739.739,0,1,1,1.045,1.045L10.169,9.125l1.694,1.694a.739.739,0,1,1-1.045,1.045L9.125,10.169,7.431,11.863a.739.739,0,1,1-1.045-1.045Z" transform="translate(-1 -1)"/></svg>'
        }
    };
  },
  mounted() {
    this.svg = this.iconns[this.iconn];
  }
};
</script>
<style></style>
