<!--
Main sourse for developing basic interactive map with vue2-leaflet:
https://github.com/eyuelberga/vue-leaflet-geolocation-selector/blob/master/src/components/GeolocationSelectorMap.vue
-->

<template>
  <div>
    <p>Локация <i v-if="!isReadonly" style="font-size:small;">(Използвайте двоен клик за избор на локация)</i></p>
    <l-map ref="mapid"
           @dblclick="onMapClick"
           :zoom="zoom"
           :center="[position.lat, position.lng]"
           style="height: 400px; margin-bottom: 40px; cursor: pointer; z-index: 1;">
      <l-control-layers position="topright"></l-control-layers>
      <l-tile-layer v-for="tileProvider in tileProviders"
                    :key="tileProvider.name"
                    :name="tileProvider.name"
                    :visible="tileProvider.visible"
                    :url="tileProvider.url"
                    :attribution="tileProvider.attribution"
                    layer-type="base"
                    :opacity="1" />
      <template v-if="isBreakdown">
        <l-wms-tile-layer v-for="layer in layersWMS"
                          :key="layer.name"
                          :base-url="layer.url"
                          :layers="layer.layers"
                          :visible="layer.visible"
                          :name="layer.name"
                          layerType="overlay"
                          :opacity="0.5" />
      </template>
      <l-control position="bottomleft">
        <v-btn v-if="position" x-small outlined elevation="2" @click="recenterMap()">
          <span class="mdi mdi-target" />
        </v-btn>
      </l-control>
      <l-geosearch v-if="!isReadonly" :options="geoSearchOptions"></l-geosearch>
      <l-marker v-if="position.lat && position.lng"
                visible
                :draggable="!isReadonly"
                :icon="icon"
                :lat-lng.sync="position"
                @dragstart="onDragStart"
                @dragend="onDragEnd(); onMapClick">
        <l-tooltip :content="tooltipContent" :options="{ permanent: false, direction: 'bottom' }" />
      </l-marker>
    </l-map>
  </div>
</template>

<script>

  import 'leaflet/dist/leaflet.css';
  import 'leaflet-geosearch/dist/geosearch.css';
  import { LMap, LTileLayer, LMarker, LTooltip, LControlLayers, LWMSTileLayer, LControl } from 'vue2-leaflet';
  import { OpenStreetMapProvider } from 'leaflet-geosearch';
  import LGeosearch from "vue2-leaflet-geosearch";
  import { icon } from "leaflet";

  export default {
    components: {
      LMap,
      LTileLayer,
      'l-wms-tile-layer': LWMSTileLayer,
      LMarker,
      LTooltip,
      LGeosearch,
      LControlLayers,
      LControl
    },
    props: {
      value: {
        type: Object,
        default: () => ({})
      },
      model: {
        type: Object,
        default: () => ({})
      },
      isReadonly: {
        type: Boolean,
        default: () => (false)
      },
      isBreakdown: {
        type: Boolean,
        default: () => (false)
      }
    },
    data() {
      return {
        loading: false,
        geoSearchOptions: {
          provider: new OpenStreetMapProvider(),
          showMarker: false,
          autoClose: true
        },
        icon: icon({
          iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
          iconUrl: require('leaflet/dist/images/marker-icon.png'),
          shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
          iconAnchor: [12, 37]
        }),
        position: { lat: 42.548, lng: 22.963 }, //default if value is missing or the data is not loaded yet
        address: '',
        pipelineInfo: '',
        tileProviders: [
          {
            name: 'Карта',
            visible: true,
            attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          }
        ],
        layersWMS: [
          {
            name: 'Водопроводи',
            visible: false,
            layers: 'pipelines',
            url: 'https://basemaps.aquagis.bg/cgi-bin/mapserv?map=/srv/mapserver/aquagis_pernik_service.map'
          },
        ],
        zoom: 14,
        dragging: false,
        mapEvent: null,
        outputModel: {},
      };
    },
    mounted() {
      if (!this.isBreakdown && !this.isReadonly) this.getUserPosition();

      this.$refs.mapid.mapObject.on("geosearch/showlocation", this.onSearch);
      this.$refs.mapid.mapObject.doubleClickZoom.disable();
    },
    watch: {
      position: {
        deep: true,
        async handler(value) {
          if (value) {
            this.address = await this.getAddress();

            if (this.isBreakdown) {
              this.pipelineInfo = await this.getPipelineInfo();
            }

            this.$emit("input", { position: value, address: this.address });

            //Assigning coordinates to input form model
            this.model.latitude = this.position.lat;
            this.model.longitude = this.position.lng;

            //Assigning coordinates and pipeline data to output model
            this.outputModel.lat = this.position.lat;
            this.outputModel.lng = this.position.lng;
          }
        }
      },
      value: {
        async handler(value) {
          if (value.lat && value.lng) {
            this.position = value;
          }
        }
      }
    },
    computed: {
      tooltipContent() {
        if (this.dragging) return "...";
        if (this.loading) return "Зареждане...";

        let markerMessage =
          `<strong>${this.address.replace(",", "<br/>")}</strong>
            <hr/>
            <strong>lat:</strong> ${this.position.lat}
            <br/>
            <strong>lng:</strong> ${this.position.lng}`;

        if (this.isBreakdown && this.pipelineInfo !== '' && !this.pipelineInfo.includes('Search returned no results')) {
          const regex = /(osm_id ?= ?'[\d]+')\s+(aq_type ?= ?'[\W]+')/gm;
          const str = this.pipelineInfo;
          let m;

          while ((m = regex.exec(str)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
              regex.lastIndex++;
            }

            // The result can be accessed through the 'm' variable.
            markerMessage += `<hr/><strong>Идентификатор: </strong>`;
            m.forEach((match, groupIndex) => {
              if (groupIndex > 0) {
                markerMessage += `${match}; `;
              } else {
                this.outputModel.pipelineId = `${match}`.replace(/\n/gm, ';').replace(/\s+/gm, '');
              }
            });
          }
        } else {
          this.erasePipelineData();
        }

        return markerMessage;
      }
    },
    methods: {
      recenterMap() {
        let map = this.$refs.mapid;
        let pos = this.position;

        map.setZoom(16);
        map.setCenter([pos.lat, pos.lng])
        for (let i = 0; i <= 1; i++) {
          setTimeout(function () {
            map.setZoom(16);
            map.setCenter([pos.lat, pos.lng])
          }, 500);
        }
      },
      async getAddress() {
        this.loading = true;
        let address = "Непознат адрес";
        try {
          const { lat, lng } = this.position;
          const result = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`);
          if (result.status === 200) {
            const body = await result.json();
            address = body.display_name;
          }
        } catch (e) {
          //console.error("Reverse Geocode Error->", e);
        }
        this.loading = false;
        return address;
      },
      async getPipelineInfo() {
        const map = this.$refs.mapid.mapObject;
        const bounds = this.$refs.mapid._data.lastSetBounds;

        if (bounds && this.mapEvent && this.isBreakdown) {
          let northEastCoord = bounds._northEast;
          let southWestCoord = bounds._southWest
          let size = map._size;
          let mouseContainerPoint = map.mouseEventToContainerPoint(this.mapEvent);

          let url = `https://basemaps.aquagis.bg/cgi-bin/mapserv` +
            `?map=/srv/mapserver/aquagis_pernik_service.map` +
            `&REQUEST=GetFeatureInfo` +
            `&SERVICE=WMS` +
            `&SRS=EPSG:4326` +
            `&STYLES=` +
            `&TRANSPARENT=true` +
            `&VERSION=1.1.1` +
            `&FORMAT=image/png` +
            `&BBOX=${southWestCoord.lng},${southWestCoord.lat},${northEastCoord.lng},${northEastCoord.lat}` +
            `&HEIGHT=${size.y}` +
            `&WIDTH=${size.x}` +
            `&LAYERS=pipelines` +
            `&QUERY_LAYERS=pipelines` +
            `&INFO_FORMAT=text/plain` +
            `&X=${mouseContainerPoint.x}` +
            `&Y=${mouseContainerPoint.y}`;

          const result = await fetch(url);
          const text = await result.text();
          return text;
        }
        return '';
      },
      async onMapClick(event) {
        // place the marker on the clicked spot
        if (!this.isReadonly) {
          this.position = event.latlng;
          this.mapEvent = event.originalEvent;
        }
      },
      async onSearch(value) {
        const loc = value.location;
        this.position = { lat: loc.y, lng: loc.x };
      },
      async getUserPosition() {
        if (navigator.geolocation) {
          // get GPS position
          navigator.geolocation.getCurrentPosition(pos => {
            // set the user location
            this.position = {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude
            };
          });
        }
      },
      onDragStart() {
        this.dragging = true;
      },
      onDragEnd() {
        this.dragging = false;
      },
      erasePipelineData() {
        this.outputModel.pipelineId = '';
        this.pipelineInfo = '';
      },
    }
  }
</script>
