import router, { asyncRoutes, constantRoutes, dashboardRoutes } from '@/router'
import { removeDuplicateElementsArray } from '@/utils'

/**
 * Use meta.role to determine if the current user has permission
 * @param actions
 * @param route
 */

function hasPermission(actions, groups, route) {
  if (route.meta && route.meta.actions && route.meta.groups && route.meta.groups.length > 0) {
    return actions.some(role => route.meta.actions.includes(role)) &&
      groups.map(x => x.name).some(group => route.meta.groups.includes(group));
  } else {
    return true;
  }
}

function hasDashboardPermission(groups, route) {
  if (route.groups) {
    return groups.map(x => x.name).some(group => route.groups.includes(group));
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param actions asyncRoutes
 * @param actions
 */
export function filterAsyncRoutes(routes, actions, groups) {
  const res = [];

  routes.forEach(route => {
    const tmp = { ...route };
    if (hasPermission(actions, groups, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, actions, groups)
      }
      res.push(tmp)
    }
  })
  return res;
}

/**
 * Filter asynchronous dashboard routing tables by recursion
 * @param routes dashboard links
 * @param groups
 */
export function filterAsyncDashboards(routes, groups) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route };
    if (hasDashboardPermission(groups, tmp)) {
      res.push(tmp)
    }
  });
  return res;
}

const state = {
  routes: [],
  addRoutes: [],
  lastRoutes: [],
  lastRoute: {},
  dashboards: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    let _constantRoutes = [...constantRoutes]
    state.addRoutes = routes

    let routesToBeAdded = removeDuplicateElementsArray(state.routes, routes);

    if (routesToBeAdded.length) {
      state.routes = _constantRoutes.concat(routesToBeAdded)
    }
  },
  RESET_ROUTES: (state) => {
    state.routes = [];
    state.addRoutes = [];
    state.lastRoutes = [];
    state.lastRoute = {};
    state.dashboards = [];
  },
  ADD_LAST_ROUTE: (state, route) => {
    state.lastRoutes.unshift(route);
    state.lastRoute = route;
    if (state.lastRoutes.length >= 5) {
      state.lastRoutes.pop();
    }
  },
  REMOVE_LAST_2_ROUTES: (state) => {
    state.lastRoutes.shift();
    state.lastRoutes.shift();
    state.lastRoute = state.lastRoutes[0];
  },
  SET_DASHBOARDS: (state, dashboards) => {
    state.dashboards = dashboards;
  },
}

const actions = {
  generateRoutes({ commit }, { allowedActions, allowedGroups }) {
    return new Promise(resolve => {
      let accessedRoutes = filterAsyncRoutes(asyncRoutes, allowedActions, allowedGroups);

      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    })
  },

  resetRoutes({ commit }) {
    commit('RESET_ROUTES');
  },

  addLastRoute({ commit }, route) {
    commit('ADD_LAST_ROUTE', route)
  },

  goBack({ commit }) {
    router.push({ path: state.lastRoute.path });
    commit('REMOVE_LAST_2_ROUTES');
  },

  generateDashboards({ commit }, groups) {
    return new Promise(resolve => {
      let accessedDashboards = filterAsyncDashboards(dashboardRoutes, groups);
      commit('SET_DASHBOARDS', accessedDashboards);
      resolve(groups);
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}