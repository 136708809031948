<template>
  <v-container id="signalFromOper"
               ref="signalFromOper"
               fluid tag="section"
               class="full-height">
    <base-v-component heading="Подай сигнал"
                      goBackText="Обратно към сигнали"
                      goBackTo="/signals" />

    <v-card style="border-top-right-radius: 30px;
             border-bottom-right-radius: 30px;
             border-bottom-left-radius: 30px;">
      <v-container fluid fill-height tag="section" class="full-height">
        <v-row class="mt-10 d-print-none">
          <v-col cols="12" xl="12" lg="12" md="12" class="mx-auto">
            <v-form>
              <label>Заглавие</label>
              <v-text-field v-model="signalData.title"
                            outlined
                            clearable
                            placeholder="Заглавие">
              </v-text-field>

              <p>{{ getSelectedGeoLocation() }}</p>
              <base-map :model="signalData" :isBreakdown="false"></base-map>

              <editor-component v-model="messageBody"
                                ref="editorComponent"></editor-component>

              <div style="margin-top: 15px;">
                <v-file-input v-model="attachments"
                              placeholder="Прикачи файл към съобщението."
                              multiple
                              show-size
                              style="width: 350px;">
                </v-file-input>
              </div>
              <v-btn style="margin-top: 15px;"
                     class="successGradient"
                     @click="CreateNewSignal(signalData, attachments)">
                Изпрати
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import config from '@/config';
  import { editorComponent } from "@/components";
  import { mapGetters } from "vuex";
  import { getToken } from "@/utils/auth";

  export default {
    name: "signalFromOper",
    components: {
      editorComponent
    },
    data() {
      return {
        constants: Object.freeze({
          operator: false,
          client: true,
          dateTimeFormt: "DD.MM.YYYY HH:mm:ss",
          operatorEmail: "aquagis@legit.bg",
          department: "гр. София",
          useMessage: 0,
          useEmail: 1
        }),

        signalData: {
          title: null,
          latitude: null,
          longitude: null,
          message: null,        //use signalDataMmessage  to workaround the existing bug in editor-component: objects in v-model doesnt work
        },
        attachments: [],
        messageBody: null,
      };
    },

    mounted() {},

    computed: {
      ...mapGetters(["lastRoute", "actionsAccess"])
    },

    methods: {
      getSelectedGeoLocation() {
        return 'Локация на сигнала: ' + (this.signalData.latitude == null ? '-' : this.signalData.latitude) + ' : ' + (this.signalData.longitude == null ? '-' : this.signalData.longitude);
      },

      async CreateNewSignal(signalData, attachments) {
        var title = signalData.title;
        var latitude = signalData.latitude; 
        var longitude = signalData.longitude;
        var message = this.$refs.editorComponent.content;

        let formData = new FormData();
        for (var i = 0; i < attachments.length; i++) {
          formData.append('attachments', attachments[i]);
        }

        if (title == null || title.trim() == "" ||
          message == null || message.trim() == "" ||
          latitude == null || longitude == null ) {
          this.$snotify.error(
            '"Заглавие", "Описание" и "Локация" на сигнала трябва да имат стойности.'
          );
          return;
        }

        var url = 'api/signal/createSignal/';
        url += '?title=' + title;
        url += '&latitude=' + latitude;
        url += '&longitude=' + longitude;
        url += '&message=' + message;

        await axios.post(url,
          formData, {
          baseURL: config.apiBaseURL,
          headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${getToken()}` },
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          timeout: config.requestTimeout * 60000
        })
          .then(response => {
            if (response.data != null) {
              var signalId = response.data.id;
              if (signalId > 0) {
                this.$snotify.success(`Успешно създаден сигнал № '${signalId}'`);
                this.$router.push({ path: `/signals_oper/preview/${signalId}` })
              }
            } else {
              this.$snotify.error(
                'Подаването на нов сигнал не е записано. Опитайте пак!.'
              );
            }

            this.attachments = [];
          })
          .catch(error => {
            this.$snotify.error(
              'Неочаквана грешка при изпращането на данните. ' + error + '.'
            );
            this.attachments = [];
          });
      },
    }
  };
</script>
