<template>
  <v-container id="logsList"
               ref="logsList"
               fluid
               tag="section"
               class="full-height">

    <template>
      <div>
        <filter-logs ref="logsFilter" @doFilter="getData()" />

        <v-data-table style="border-bottom-right-radius: 30px;
                             border-bottom-left-radius: 30px;
                             margin-top: -15px;"
                      ref="table"
                      :headers="tableProps.headers"
                      :items="tableProps.data"
                      class="elevation-2"
                      disable-pagination
                      hide-default-footer
                      :sort-by.sync="customSortColumn"
                      :sort-desc.sync="customSortDesc"
                      multi-sort
                      :disable-sort="!tableProps.data || !tableProps.data.length">
          <template v-slot:body="{ items }">
            <tbody>
              <template v-if="items && items.length">
                <tr v-for="(item, idx) in items"
                    :key="`row_${idx}`"
                    @click="setSignalSelected(item)">
                  <template v-for="(cell, idx) in tableProps.headers">
                    <td :key="'td_'+idx" v-if="cell.valueType !== 'button' && cell.valueType !== 'status' && cell.valueType !== 'textBalance' && cell.valueType !== 'checkbox'" :class="cell.classItem" :style="cell.style">{{getValue(item, cell)}}</td>

                    <td :key="'td_'+idx" v-if="cell.valueType === 'textBalance'" :class="cell.classItem" :style="cell.style">
                        <span class="blue--text darken-4">
                          {{getValue(item, cell)}}
                        </span>
                    </td>

                    <td :key="'td_'+idx" v-if="cell.valueType === 'status'" :class="cell.classItem + ' ' + (cell.active === item[cell.displayValue || cell.value] ? cell.activeClass : (cell.notActive === item[cell.displayValue || cell.value] ? cell.notActiveClass : ''))" :style="cell.style">
                      <template v-if="cell.statusIcons">
                        <v-icon class="success--text" v-if="item[cell.displayValue || cell.value] === cell.active">mdi-check</v-icon>
                        <v-icon class="error--text" v-if="item[cell.displayValue || cell.value] === cell.notActive">mdi-close</v-icon>
                      </template>
                      <template v-else>
                        <template v-if="item[cell.displayValue || cell.value] == cell.active">
                          {{cell.activeText}}
                        </template>
                        <template v-else-if="item[cell.displayValue || cell.value] == cell.notActive">
                          {{cell.notActiveText}}
                        </template>
                        <template v-else>
                          {{getValue(item, cell)}}
                        </template>
                      </template>
                    </td>
                    <td :key="'td_'+idx" v-if="cell.valueType === 'checkbox'" :class="cell.classItem" :style="cell.style">
                      <v-checkbox v-model="item[cell.displayValue || cell.value]"
                                  color="primary"
                                  :value="cell.checked"
                                  :false-value="cell.unchecked"
                                  hide-details
                                  class="mt-0"
                                  @change="changeCheckboxValue(item)"></v-checkbox>
                    </td>
                    <td :key="'td_'+idx" v-if="cell.valueType === 'button'" :class="cell.classItem" :style="cell.style" class="d-print-none">
                      <template v-for="(button, bIdx) in cell.buttons">
                        <v-btn class="successGradient" v-if="checkButton(button, item)"
                               :key="'td_button_'+bIdx"
                               :block="button.block"
                               :style="button.style"
                               :title="button.title"
                               @click="doAction(button.click, item)"
                               :class="`v-size--${button.size} ` +button.class">
                          <v-icon :class="button.label ? 'mr-2' : 'mr-0'" v-if="button.icon && button.icon.length ? true : false">{{button.icon}}</v-icon>
                          {{button.label}}
                        </v-btn>
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td :colspan='tableProps.headers.length' class="text-center px-8">
                    <v-row>
                      <v-col cols="12" class="pa-7">
                        <h4 class="display-1 font-weight-medium blue-grey--text text--darken-2">Няма намерени резултати</h4>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>

        <div style="display: flex; justify-content: space-between;">
          <div style="display:inline;padding-top:20px;width:150px;">
            <v-select v-if="tableProps.hasPaging"
                      :items="tableProps.pagination.perPageOptions"
                      v-model="tableProps.pagination.itemsPerPage"
                      item-text="Value"
                      item-value="Key"
                      placeholder="-- Избери --"
                      label="Редове на страница"
                      :color="tableProps.color"
                      @change="changeRowsPerPage()">
            </v-select>
          </div>

          <div style="display:inline;">
            <v-pagination v-if="tableProps.hasPaging"
                          v-model="tableProps.pagination.page"
                          :color="tableProps.props.color"
                          :length="Math.ceil(tableProps.pagination.total / tableProps.pagination.itemsPerPage)"
                          :total-visible="10"
                          @input="changedPagination()"
                          class="d-print-none"></v-pagination>
          </div>

          <div style="display:inline;padding-top:30px">
            <span>Общо: <strong>{{tableProps.pagination.total}}</strong> записа.</span>
          </div>
        </div>
      </div>
     
    </template>
  </v-container>
</template>

<script>
  import { getLogsList } from '@/api/logs';
  import { filterLogs } from "@/components";
  import moment from "moment";
  import { formatDateTime, formatDateTimeSeconds, getArrayField, ISODateString, roundNumber } from "@/utils"
  export default {
    name: "logsList",
    components: {
      filterLogs
    },
    data() {
      return {
        constants: Object.freeze({
          currentClient: 50,
          currentClientEmail: "i_dikov@yahoo.com"
        }),

        tableProps: {
          headers: [
            { text: 'Пореден No.', value: 'id', valueType: "text", class: "successHeader" },
            { text: 'Грешка описание', value: 'errorMessage', valueType: "text", class: "successHeader" },
            { text: 'Модул име', value: 'modulName', valueType: "text", class: "successHeader" },
            { text: 'Процедура име', value: 'procedureName', valueType: "text", class: "successHeader" },
            { text: 'Създаден на', value: 'createdOn', valueType: "dateTime", class: "successHeader" },
            { text: 'Импорт файл No.', value: 'importFileId', valueType: "text", class: "successHeader" },
            { sortable: false, text: '', value: 'actions', valueType: "button", class: 'text-center cols cols-2 successHeader', style: 'width:100px; padding: 10px;',
              buttons: [{
                label: '',
                title: 'Преглед',
                class: 'success',
                icon: 'mdi-text-box-search-outline',
                style: "width: 100px; height: 30px;",
                click: 'previewSignals',
                id: 'signalDetailsPreview'
              }]
            }
          ],
          data: [],
          filters: {},
          hasPaging: true,
          noms: {
            statuses: [],
            priorities: [],
          },
          pagination: {
            itemsPerPage: 10,
            page: 1,
            perPageOptions: [5, 10, 25, 50, 100],
            total: 0
          },

          props: {
            id: {
              type: String,
              default: ''
            },
            color: 'primary',
          },
          sortColumn: [],
          sortDescending: [],
        },

        sortBy: [],
        sortDesc: [],
        showDetails: false,
        signalSelectedId: null,
        signalUserIdSelected: null
      }
    },

    computed: {
      customSortColumn: {
        get: function () {
          return this.tableProps.sortColumn;
        },
        set: function (value) {
          this.tableProps.sortColumn = value;
          this.$emit('update:sortBy', value);
          this.$forceUpdate();
        }
      },
      customSortDesc: {
        get: function () {
          return this.tableProps.sortDesc;
        },
        set: function (value) {
          this.tableProps.sortDescending = value;
          this.$emit('update:sortDesc', value);
          this.getSignals();
          this.$forceUpdate();
        }
      }
    },

    mounted() {
      this.getData();
    },

    created() {
      this.tableProps.sortColumn = this.sortBy;
      this.tableProps.sortDescending = this.sortDesc;
    },

    methods: {
      getLogs() {

        this.$refs.logsFilter.doFilterIsActivated = true;   //show must begin

        let query = Object.assign({}, this.tableProps.filters);
        query.userId = this.constants.currentClient;

        if (!query.page) query.page = this.tableProps.pagination.page;
        query.itemsPerPage = this.tableProps.pagination.itemsPerPage;
        query.sortBy = this.tableProps.sortColumn;
        query.sortDesc = this.tableProps.sortDescending;

        getLogsList(query).then((data) => {
          this.tableProps.data = data.items;
          this.tableProps.pagination.total = data.totalCount;
          this.tableProps.pagination.page = query.page;
          this.$forceUpdate();
        })
      },

      getData() {
        this.tableProps.filters = this.$refs.logsFilter.filters;
        this.getLogs(this.tableProps.filters);
      },

      previewSignals(signalId) {
        if (signalId > 0) {
          this.$router.push({ path: `/signals/preview/${signalId}` });
          this.$forceUpdate();
        }
      },

      backToSignals() {
        this.showDetails = false;
        this.$forceUpdate();
      },

      setSignalSelected(signalSelected) {
        this.signalSelectedId = signalSelected.id;
        this.signalUserIdSelected = signalSelected.CreatedBySubmitterId
      },

      changeRowsPerPage() {
        if ((this.tableProps.pagination.total > 0 && this.data) && this.tableProps.pagination.page >= Math.ceil(this.tableProps.pagination.total / this.tableProps.pagination.itemsPerPage)) {
          this.tableProps.pagination.page = Math.ceil(this.tableProps.pagination.total / this.tableProps.pagination.itemsPerPage)
        }

        this.getData();
      },

      checkButton(button, item) {
        let ifValue = true;
        if (button.ifValueObjectExists) {
          let props = button.ifValueObjectExists.split('.');
          if (item[props[0]]) {
            let value = props.reduce((a, v) => a[v], item);

            if (!value)
              ifValue = false
          }
        }
        return (button.if ? (button.if.type ? (button.if.type === "equal" ? item[button.if.item] === button.if.value : false) : item[button.if.item] !== button.if.value) : true) && ifValue
      },

      getValue(item, options) {
        let template = '';
        let keys = [];
        let props = [];
        let prop = options.displayValue || options.value;
        let result;
        let itemData;

        if (options.value == 'needsReply') {
          return item.needsReply == false ? "Не" : "Да";
        } else if (options.value == 'attachmentId') {
          // return this.tableProps.noms.needsReply == 0 ? "Не" : "Да";
        }

        switch (options.valueType) {
          case 'decimal':
            return roundNumber(item[prop || options.value], 16, 16, 8);
          case 'text':
            return item[prop] || item[prop] === 0 ? item[prop] : ' — ';
          case 'textGL':
            return item[prop] || 'GL';
          case 'textBalance':
            // result = (item[options.value] + '').split('.')[1];
            // if(result && result.length <= 1 || (item[options.value] + '').length === 1){
            return roundNumber(item[prop || options.value], 2)
          // }
          // return item[options.value];
          case 'price':
            return roundNumber(item[prop || options.value], 2);
          case 'priceAccounting':
            var num = item[prop || options.value];
            if (typeof num === 'number')
              num = num + '';

            var value = Number(num);

            var res = num.split(".");
            if (res.length == 1 || res[1].length < 3)
              value = roundNumber(value, 2);

            return value;
          case 'array':
            // eslint-disable-next-line no-case-declarations
            let val = getArrayField(item, prop, options.comma);
            if (val && val !== 'null') {
              return val;
            }
            return '';
          //return val != null ? val : '';
          case 'date':
            if (item[prop]) {
              // eslint-disable-next-line no-case-declarations
              let d = moment();

              if ((typeof item[prop] === 'number' && item[prop].toString().length === 8) || (typeof item[prop] === 'string' && item[prop].length === 8)) {
                d = moment(item[prop], "DDMMYYYY");
                if (!d.isValid())
                  d = moment(item[prop], "YYYYMMDD");
              } else if ((typeof item[prop] === 'number' && item[prop].toString().length === 14) || (typeof item[prop] === 'string' && item[prop].length === 14)) {
                d = moment(item[prop], "YYYYMMDDHHmmss")
              } else {
                d = moment(item[prop]);
              }
              if (d.isValid()) {
                return ISODateString(d.toISOString());
              }
            }

            return '—';
          case 'dateTime':
            if (item[prop]) {
              // eslint-disable-next-line no-case-declarations
              let d = moment(item[prop]);
              if (d.isValid()) {
                return formatDateTime(item[prop]);
              }
            }

            return '—';
          case 'dateTimeSeconds':
            if (item[prop]) {
              // eslint-disable-next-line no-case-declarations
              let d = moment(item[prop]);
              if (d.isValid()) {
                return formatDateTimeSeconds(item[prop]);
              }
            }

            return '—';
          case 'firstElement':
            props = prop.split('.');
            return item[props[0]] ? item[props[0]][0][props[1]] : '';
          case 'firstElementObject':
            result = '';
            keys = prop.split('.');
            var firstChildren = item[keys[0]][0];
            keys.shift();
            if (firstChildren) {
              if (firstChildren[keys[0]]) {
                result = keys.reduce((a, v) => a[v], firstChildren);
              }
            }
            return result ? result : '';
          case 'object':
            keys = prop.split('.');
            // eslint-disable-next-line no-case-declarations
            result = '';
            try {
              if (item[keys[0]]) {
                result = keys.reduce((a, v) => a[v], item);
              }
              return result ? result : ' — ';
            } catch (e) {
              return ' — '
            }
          case 'objectDate':
            keys = prop.split('.');

            // eslint-disable-next-line no-case-declarations
            let date = keys.reduce((a, v) => a[v], item);

            if (date) {
              // eslint-disable-next-line no-case-declarations
              let d;
              if (typeof date === 'number' && date.toString().length === 8) {
                d = moment(date, "YYYYMMDD");
              } else {
                d = moment(date);
              }

              if (d.isValid()) {
                return ISODateString(d.toISOString());
              }
            }

            return ' — ';
          case 'objectDateTime':
            keys = prop.split('.');

            // eslint-disable-next-line no-case-declarations
            let dateTimeObject = keys.reduce((a, v) => a[v] != null && a[v], item);

            if (dateTimeObject) {
              return formatDateTime(dateTimeObject);
            }

            return ' — ';
          case 'objectPrice':
            keys = prop.split('.');
            // eslint-disable-next-line no-case-declarations
            result = '';
            try {
              if (item[keys[0]]) {
                result = keys.reduce((a, v) => a[v], item);
              }
              return result ? roundNumber(result, 2) : ' — ';
            } catch (e) {
              return ' — '
            }
          case 'twoObjectsPrice':
            result = '';
            try {
              for (let obj of prop) {
                keys = obj.split('.');
                if (item[keys[0]]) {
                  if (result.length) {
                    let val = keys.reduce((a, v) => a[v], item);
                    result += val ? ' ' + val : '';
                  } else {
                    result += roundNumber(keys.reduce((a, v) => a[v], item), 2);
                  }
                }
              }

              return result ? result : ' — ';
            } catch (e) {
              return ' — '
            }
          case 'valueOrValue':
            keys = prop.split('.');

            try {
              if (item[keys[0]]) {
                return item[keys[0]]
              }

              return item[keys[1]] || ' — ';
            } catch (e) {
              return ' — '
            }
          case 'valueOrValueObject':
            keys = prop[0].split('.');
            try {
              result = '';
              if (item[keys[0]]) {
                result = keys.reduce((a, v) => a[v], item);
              }

              if (result) {
                return result;
              }

              // checking next value
              keys = prop[1].split('.');

              result = '';
              if (item[keys[0]]) {
                result = keys.reduce((a, v) => a[v], item);
              }

              return result ? result : ' — ';
            } catch (e) {
              return ' — '
            }
          case 'valueOrValueArray':
            result = '';
            for (let i = 0; i < prop.length; i++) {
              if (prop[i].includes('.')) {
                keys = prop[i].split('.');
              } else {
                keys = [prop[i]];
              }

              for (let k = 0; k < keys.length; k++) {
                if (item[keys[k]]) {
                  if (result.length) result += options.separator || ' ';
                  result += item[keys[k]];
                }
              }
            }
            return result;
          case 'button':
            for (let i = 0; i < options.buttons.length; i++) {
              template += ``;
            }
            return template;
          case 'sort':
            itemData = Object.assign([], item[options['sortBy']]);

            if (options['sortType'] === 'date') {
              itemData.sort(function (x, y) {
                if (options['sortDesc']) {
                  return (new Date(y[options['sortValue']])).getTime() - (new Date(x[options['sortValue']])).getTime();
                } else {
                  return (new Date(x[options['sortValue']])).getTime() - (new Date(y[options['sortValue']])).getTime();
                }
              })
            }

            if (prop.includes('.')) {
              keys = prop.split('.');
              result = '';
              if (itemData[0][keys[0]]) {
                result = keys.reduce((a, v) => a[v], itemData[0]);
              }
              return result ? result : ' — ';
            }

            return itemData[0][prop];
          case 'status':
            if (item[prop] != null) {
              return item[prop].toString() || ' - ';
            }
            return ' - ';
          case 'translateValue':
            result = ' - ';
            for (let obj of options.translateValues) {
              if (obj.value === item[prop]) {
                result = obj.label
              }
            }
            return result;
        }
      },
            
      doAction(action, data) {
        if (action == "previewSignals") 
          this.previewSignals(data.id);
      },

      changedPagination() {
        this.getData();
      },

      changeCheckboxValue(item) {
        this.$emit("changeCheckbox", item);
      },

    },
  }
</script>
