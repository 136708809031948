import Layout from "@/layouts/Layout.vue";
import WaterMeterList from "@/views/water_meters/list.vue";
import WaterMeterPreview from "@/views/water_meters/preview.vue";
import WaterMeterAdd from "@/views/water_meters/add.vue";
import ClientPreview from "@/views/gis_clients/clientPreview.vue";

let waterMeterRoute = {
  path: "/water_meters",
  component: Layout,
  name: "Водомери",
  redirect: "/water_meters",
  hidden: true,
  meta: {
    actions: ["Оператор"],
    groups: ["TestMainGroup", "TestDummyGroup"],
  },
  children: [
    {
      path: "/water_meters/list",
      name: "Водомери",
      component: WaterMeterList,
      meta: {
        title: "Водомери",
        actions: ["Оператор"],
        groups: ["TestMainGroup", "TestDummyGroup"],
        icon: "mdi-gauge",
        goBackTitle: "Обратно към водомери",
      },
      hidden: false
    },
    {
      path: "/water_meters/preview/:id",
      name: "Преглед на водомер",
      component: WaterMeterPreview,
      meta: {
        title: "Преглед на водомер",
        actions: ["Оператор"],
        groups: ["TestMainGroup", "TestDummyGroup"],
        icon: "mdi-account-alert",
        goBackTitle: "Обратно към водомери",
      },
      hidden: true
    },
    {
      path: "/water_meters/add",
      name: "Нов водомер",
      component: WaterMeterAdd,
      meta: {
        title: "Нов водомер",
        actions: ["Оператор"],
        groups: ["TestMainGroup", "TestDummyGroup"],
        icon: "mdi-account-alert",
        goBackTitle: "Обратно към преглед на водомер",
      },
      hidden: true
    },   
    {
      path: "/gis_clients/clientPreview/:clientId",
      name: "Преглед клиент",
      component: ClientPreview,
      meta: {
        title: "Преглед клиент",
        actions: ["Оператор"],
        groups: ["TestMainGroup", "TestDummyGroup"],
        icon: "mdi-account-alert",
        goBackTitle: "Обратно към преглед на водомер",
      },
      hidden: true
    },
  ]
}

export default waterMeterRoute;
