import request from '@/utils/request';
import { filterQuery } from '@/utils/index';

export function getBreakdownsList(query) {
  return request({
    url: '/api/breakdown/GetBreakdownsList',
    method: 'get',
    params: filterQuery(query)
  });
}
export function csvFileDownload() {
  return request({
    url: '/api/Breakdown/ExportAll',
    method: 'get',
    responseType: 'blob',
  });
}


export function getBreakdownById(breakdownId) {
  return request({
    url: '/api/breakdown/GetBreakdownById?breakdownId=' + breakdownId,
    method: 'get',
  });
}

export function updateBreakdown(breakdown) {
  return request({
    url: '/api/breakdown/Update',
    method: 'post',
    params: breakdown
  });
}


export function createBreakdown(objectBreakdown) {
  return request({
    url: '/api/breakdown/Create',
    method: 'post',
    data: objectBreakdown
  });
}

export function addSignalToBreakdown(breakdownId, signalId) {
  return request({
    url: '/api/breakdown/AddSignalToBreakdown?breakdownId=' + breakdownId + "&signalId=" + signalId,
    method: 'get'
  });
}

export function removeSignalFromBreakdown(signalId) {
  return request({
    url: '/api/breakdown/RemoveSignalFromBreakdown?signalId=' + signalId,
    method: 'get'
  });
}