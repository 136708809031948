<template>
  <v-container id="gisItemNomenclaturePreview"
               ref="gisItemNomenclaturePreview"
               fluid
               tag="section"
               class="full-height">
    <base-v-component :heading="getHeading()"
                      goBackText="Обратно към номенклатури"
                      goBackTo="/gis_nomenclatures/main_list" />
    <v-row>
      <v-col col="12">
        <base-material-card style="border-top-right-radius: 30px;
                                   border-bottom-right-radius: 30px;
                                   border-bottom-left-radius: 30px;"
                            class="v-card-profile text-center"
                            :avatar="require(`@/assets/nomenclatures.png`)"
                            color="primary">
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field label="Код"
                              v-model="itemNomenclature.code"
                              autocomplete="off"
                              clearable
                              dense />
              </v-col>
              <v-col>
                <v-text-field label="Име на български"
                              v-model="itemNomenclature.nameBg"
                              autocomplete="off"
                              clearable
                              dense />
              </v-col>
              <v-col>
                <v-text-field label="Име на английски"
                              v-model="itemNomenclature.nameEn"
                              autocomplete="off"
                              clearable
                              dense />
              </v-col>
              <v-col>
                <base-select label="Активна"
                             v-model="itemNomenclature.isActive"
                             :items="properties.isActiveItems"
                             item-value="key"
                             item-text="value"
                             clearable
                             dense>
                </base-select>
              </v-col>
            </v-row>
            <v-row justify="center" style="margin-top: 30px; margin-bottom: 30px">
              <v-col lg="12" md="12" cols="12">
                <v-btn class="successGradient"
                       @click="updateItemNomenclatureData(selectedNomenclatureId, itemNomenclature)">
                  <v-icon style="margin-right:10px">mdi-account-check</v-icon>
                  Запази
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {
    getItemNomenclatureByNomenclatureIdByItemNomenclatureId,
    updateItemNomenclature,
    createItemNomenclature
  } from "@/api/gis_nomenclatures";

  import {
    ISODateString,
    formatDateTime
  } from '@/utils';

  const filtersModel = Object.freeze({
    Code: null,
    nameBG: null,
    nameEn: null,
    isActive: null,
  });

  export default {
    name: "gisItemNomenclaturePreview",

    components: {},

    data() {
      return {
        properties: {
          isActiveItems: [
            { key: true, value: "Да" },
            { key: false, value: "Не" },
          ],
        },

        filters: Object.assign({}, filtersModel),

        data: [],

        itemNomenclature: {
          id: null,
          code: null,
          nameBg: null,
          nameEn: null,
          isActive: null,
        },

        itemNomenclatureInitData: {},  //we will detect updateable properties by both itemNomenclature, itemNomenclatureInit

        selectedNomenclatureId: null,
        selectedItemNomenclatureId: null,
      };
    },

    mounted() {
      this.selectedNomenclatureId = this.$route.params.nomenclatureSelectedId;
      this.selectedItemNomenclatureId = this.$route.params.itemNomenclatureId;

      if (this.selectedItemNomenclatureId > 0)
        this.getItemNomenclatureByNomenclatureIdByItemNomenclatureId(this.selectedNomenclatureId, this.selectedItemNomenclatureId);
    },

    watch: {},

    computed: {},

    created() { },

    methods: {
      formatDateTime: formatDateTime,
      ISODateString: ISODateString,

      getItemNomenclatureByNomenclatureIdByItemNomenclatureId(nomenclatureId, itemNomenclatureId) {
        getItemNomenclatureByNomenclatureIdByItemNomenclatureId(nomenclatureId, itemNomenclatureId).then(data => {

          this.itemNomenclature = data;

          this.itemNomenclatureInitData = JSON.parse(JSON.stringify(this.itemNomenclature));  //deep copy
        });
      },

      updateItemNomenclatureData(selectedNomenclatureId, itemNomenclatureModifiedData) {
        //only modified data will be send to the server, adding a new item is implemented here too
        var itemNomenclatureModifiedDatLoc = JSON.parse(JSON.stringify(itemNomenclatureModifiedData));  //deep copy

        if (itemNomenclatureModifiedDatLoc.id == null) {//add new item
          createItemNomenclature(selectedNomenclatureId, itemNomenclatureModifiedDatLoc).then(data => {
            var index1 = data.indexOf("(");
            var index2 = data.indexOf(")");
            if (index1 > -1 && index1 < index2) {
              var nomenclatureItemId = parseInt(data.substring(index1 + 1, index2), 10);
              if (nomenclatureItemId > 0) {
                this.$snotify.success(
                  "Данните за номенклатура '" +
                  itemNomenclatureModifiedData.code + "- " + itemNomenclatureModifiedData.nameBg +
                  "' са добавени.");

                this.getItemNomenclatureByNomenclatureIdByItemNomenclatureId(this.selectedNomenclatureId, nomenclatureItemId);
              } else {
                this.$snotify.error(
                  "Данните за номенклатура '" +
                  itemNomenclatureModifiedData.code + "- " + itemNomenclatureModifiedData.nameBg +
                  "' не са добавени. Грешка '" +
                  data.substring(index2 + 1) + ".");
              }
            }
            else {
              this.$snotify.error(
                "Непознати данни от операцията " +
                data +
                ". Проверете резултата чрез обновяване на данните.");
            }
          })
        } else {
          var itemNomenclatureInitData = this.itemNomenclatureInitData;

          var flagModify = false;

          for (var key in itemNomenclatureModifiedData) {
            if (key != "id")
              if (JSON.stringify(itemNomenclatureModifiedDatLoc[key]) == JSON.stringify(itemNomenclatureInitData[key]))
                itemNomenclatureModifiedDatLoc[key] = null;
              else
                flagModify = true;
          }

          if (flagModify == true)
            updateItemNomenclature(selectedNomenclatureId, itemNomenclatureModifiedDatLoc).then(data => {
              var index1 = data.indexOf("(");
              var index2 = data.indexOf(")");
              if (index1 > -1 && index1 < index2) {
                var nomenclatureItemId = parseInt(data.substring(index1 + 1, index2), 10);
                if (nomenclatureItemId > 0) {
                  this.$snotify.success(
                    "Данните за номенклатура '" +
                    itemNomenclatureModifiedData.code + "- " + itemNomenclatureModifiedData.nameBg +
                    "' са обновени.");
                  this.getItemNomenclatureByNomenclatureIdByItemNomenclatureId(this.selectedNomenclatureId, this.selectedItemNomenclatureId);
                } else {
                  this.$snotify.error(
                    "Данните за номенклатура '" +
                    itemNomenclatureModifiedData.code + "- " + itemNomenclatureModifiedData.nameBg +
                    "' не са обновени. Грешка '" +
                    data.substring(index2 + 1) + ".");
                }
              }
              else {
                this.$snotify.error(
                  "Непознати данни от операцията " +
                  data +
                  ". Проверете резултата чрез обновяване на данните.");
              }
            })
        }
      },

      getHeading() {
        if (this.selectedItemNomenclatureId > 0)
          return "Преглед на номенклатура No. " + this.selectedNomenclatureId + "." + this.selectedItemNomenclatureId + ".";
        else
          return "Нов елемент към номенклатура No. " + this.selectedNomenclatureId + ".";
      }
    }
  };

</script>
