// const OSM_CENTER = [26.92, 43.27]; //Shumen
// const OSM_CENTER = [23.08, 42.01]; //Blagoevgard
// const OSM_CENTER = [23.03, 42.60]; //Pernik
// const OSM_CENTER = [23.55, 43.20]; //Vratsa
const OSM_CENTER = [25.96, 43.84]; //Ruse
// const OSM_CENTER = [25.62, 42.42]; //Stara Zagora

const MAP_INIT_LAYER = {
    container: "map",
    style: {
        version: 8,
        name: "AquaGIS Thematic maps",
        sources: {
            ['OSM']: {
                type: "raster",
                tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
                tileSize: 256,
            },
        },
        layers: [
            {
                id: 'land',
                type: "background",
                metadata: {},
                layout: {},
                paint: {
                    "background-color": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        11,
                        "hsl(35, 32%, 91%)",
                        13,
                        "hsl(35, 12%, 89%)",
                    ],
                },
            },
            {
                id: 'OSM',
                type: "raster",
                source: 'OSM',
                metadata: {},
                paint: {},
                layout: {
                    visibility: 'visible'
                },
            },
        ],
        glyphs: "https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf",
        sprite: `${window.location.protocol}//${window.location.host}/icons-source/sprite`,
    },
    center: OSM_CENTER,
    boxZoom: false,
    zoom: 18,
    minZoom: 3,
    maxZoom: 23.99,
    preserveDrawingBuffer: true,
}

const GEOCODER_OPTIONS = {
    limit: 10,
    collapsed: false,
    zoom: 15,
    placeholder: "Търсене по адрес",
    marker: true
};

export default {
    MAP_INIT_LAYER,
    GEOCODER_OPTIONS
}