import Layout from "@/layouts/Layout.vue";
import ImportFileList from "@/views/import_file/list.vue";


let importFileRoute = {
  path: "/import_file",
  component: Layout,
  name: "Импортиране на данни",
  redirect: "/import_file",
  hidden: true,
  meta: {
    actions: ["Администратор"],   //Администратор, Оператор, Потребител
    groups: ["TestMainGroup", "TestDummyGroup"],
  },
  children: [
    {
      path: "/import_file/list",
      name: "Импортиране на данни",
      component: ImportFileList,
      meta: {
        title: "Водомери",
        actions: ["Администратор"],
        groups: ["TestMainGroup", "TestDummyGroup"],
        icon: "mdi-file-download-outline",
        goBackTitle: "Обратно към импортирани файлове",
      },
      hidden: false
    }
  ]
}

export default importFileRoute;
