<template>
  <v-container id="signalsOperPreview"
               ref="signalsOperPreview"
               fluid
               tag="section"
               class="full-height">
    <base-v-component :heading="getHeadingText()"
                      goBackText="Обратно към сигнали"
                      goBackTo="/signals" />

    <v-row class="justify-center">
      <!-- left side card -->
      <v-col class="col-5">
        <v-card style="border-top-right-radius: 30px;
                       border-bottom-right-radius: 30px;
                       border-bottom-left-radius: 30px;"
                class="v-card-profile"
                color="white"
                expandable
                :expanded.sync="expandCardDetails">

          <v-avatar style="border-top-right-radius: 20%;
                           border-bottom-left-radius: 20%;
                           border-bottom-right-radius: 20%;
                           margin-left: 20px;
                           margin-top: -40px;"
                    left
                    color="#263238"
                    size="107">
            <v-icon style="font-size: 400%; color: #ffffff;">mdi-pipe-wrench</v-icon>
          </v-avatar>
          <v-card-text class="text-center">
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Статус"
                              v-model="signalDetails.statusObject"
                              autocomplete="off"
                              readonly
                              dense />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Отдел"
                              v-model="constants.department"
                              autocomplete="off"
                              readonly
                              dense />
              </v-col>
            </v-row>
            <v-row style="height: 80px"
                   v-if="
                signalDetails.statusObject === constants.statusBreakdownName
              ">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Авария №"
                              v-model="objBreakdown.workCardNo"
                              autocomplete="off"
                              readonly
                              dense />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Засегнати протребители"
                              v-model="objBreakdown.waterStopPeople"
                              autocomplete="off"
                              readonly
                              dense />
              </v-col>
            </v-row>
            <v-row style="height: 80px"
                   v-if="signalDetails.statusObject === constants.statusBreakdownName">
              <v-col lg="6" md="6" cols="6">
                <base-material-date-picker v-model="Dirty.signalTimestampDate"
                                           label="Начална дата" />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-menu ref="Dirty.menuSignalTimestampDate"
                        v-model="Dirty.menuSignalTimestampTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="Dirty.signalTimestampDate"
                        transition="scale-transition"
                        offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="Dirty.signalTimestampTime"
                                  label="Час"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-if="Dirty.menuSignalTimestampTime"
                                 v-model="Dirty.signalTimestampTime"
                                 format="24hr"
                                 width="290px"></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row style="height: 80px"
                   v-if="
                signalDetails.statusObject === constants.statusBreakdownName
              ">
              <v-col lg="6" md="6" cols="6">
                <base-material-date-picker v-model="Dirty.repairStartDate"
                                           label="Начало на планиран ремонт" />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-menu ref="Dirty.menuRepairStartDate"
                        v-model="Dirty.menuRepairStartTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="Dirty.repairStartDate"
                        transition="scale-transition"
                        offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="Dirty.repairStartTime"
                                  label="Час"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-if="Dirty.menuRepairStartTime"
                                 v-model="Dirty.repairStartTime"
                                 format="24hr"
                                 width="290px"></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row style="height: 80px"
                   v-if="
                signalDetails.statusObject === constants.statusBreakdownName
              ">
              <v-col lg="6" md="6" cols="6">
                <base-material-date-picker v-model="Dirty.repairEndDate"
                                           label="Край на планиран ремонт" />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-menu ref="Dirty.menuRepairEndDate"
                        v-model="Dirty.menuRepairEndTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="Dirty.repairEndDate"
                        transition="scale-transition"
                        offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="Dirty.repairEndTime"
                                  label="Час"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-if="Dirty.menuRepairEndTime"
                                 v-model="Dirty.repairEndTime"
                                 format="24hr"
                                 width="290px"></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row style="height: 80px"
                   v-if="
                signalDetails.statusObject === constants.statusBreakdownName
              ">
              <v-col lg="6" md="6" cols="6">
                <base-material-date-picker v-model="Dirty.waterStopStartDate"
                                           label="Начало на планиранoто спиране" />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-menu ref="Dirty.menuWaterStopStartDate"
                        v-model="Dirty.menuWaterStopStartTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="Dirty.WaterStopStartDate"
                        transition="scale-transition"
                        offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="Dirty.waterStopStartTime"
                                  label="Час"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-if="Dirty.menuWaterStopStartTime"
                                 v-model="Dirty.waterStopStartTime"
                                 format="24hr"
                                 width="290px"></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row style="height: 80px"
                   v-if="
                signalDetails.statusObject === constants.statusBreakdownName
              ">
              <v-col lg="6" md="6" cols="6">
                <base-material-date-picker v-model="Dirty.waterStopEndDate"
                                           label="Край на планиранoто спиране" />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-menu ref="Dirty.menuWaterStopEndDate"
                        v-model="Dirty.menuWaterStopEndTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="Dirty.WaterStopEndDate"
                        transition="scale-transition"
                        offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="Dirty.waterStopEndTime"
                                  label="Час"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-if="Dirty.menuWaterStopEndTime"
                                 v-model="Dirty.waterStopEndTime"
                                 format="24hr"
                                 width="290px"></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- right side card -->
      <v-col class="col-5">
        <v-card style="border-top-right-radius: 30px;
                       border-bottom-right-radius: 30px;
                       border-bottom-left-radius: 30px;"
                class="v-card-profile"
                color="white"
                expandable
                :expanded.sync="expandCardDetails">

          <v-avatar style="border-top-right-radius: 20%;
                           border-bottom-left-radius: 20%;
                           border-bottom-right-radius: 20%;
                           margin-left: 20px;
                           margin-top: -40px;"
                    left
                    color="#263238"
                    size="107">
            <v-icon style="font-size: 400%; color: #ffffff;">mdi-pipe-wrench</v-icon>
          </v-avatar>
          <v-card-text class="text-center">
            <v-row style="height: 80px"
                   v-if="
                signalDetails.statusObject === constants.statusBreakdownName
              ">
              <v-col lg="6" md="6" cols="6">
                <v-select label="Приключила авария"
                          :items="properties.finishValues"
                          item-value="key"
                          item-text="value"
                          v-model="objBreakdown.finished"
                          @click:clear="clearIt(0, 'priorityId')"
                          clearable
                          dense>
                </v-select>
              </v-col>
            </v-row>
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Последно отговорил"
                              v-model="signalLastEmailSubmitter"
                              autocomplete="off"
                              readonly
                              dense />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-select v-if="
                    signalDetails.statusObject === constants.statusBreakdownName
                  "
                          label="Приоритет"
                          :items="properties.priorities"
                          item-value="key"
                          item-text="value"
                          v-model="objBreakdown.priorityId"
                          @click:clear="clearIt(0, 'objBreakdown.priorityId')"
                          clearable
                          dense>
                </v-select>
                <v-text-field v-else
                              label="Приоритет"
                              v-model="signalDetails.priorityObject"
                              autocomplete="off"
                              readonly
                              dense />
              </v-col>
            </v-row>
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Дата на създаване"
                              v-model="signalDetails.createdLocal"
                              autocomplete="off"
                              readonly
                              dense />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Последен отговор"
                              v-model="signalDetails.lastResponseLocal"
                              autocomplete="off"
                              readonly
                              dense />
              </v-col>
            </v-row>
            <template v-if="
                signalDetails.statusObject === constants.statusBreakdownName
              ">
              <v-row style="height: 80px">
                <v-col lg="6" md="6" cols="6">
                  <v-select label="КЕВР категория"
                            :items="properties.kevrCategories"
                            item-value="key"
                            item-text="value"
                            v-model="objBreakdown.kevrCategory1"
                            @click:clear="clearIt(0, 'objBreakdown.kevrCategory1')"
                            clearable
                            dense>
                  </v-select>
                </v-col>
                <v-col lg="6" md="6" cols="6">
                  <v-select label="КЕВР под-категории"
                            :items="properties.kevrSecondaryCategories"
                            item-value="key"
                            item-text="value"
                            v-model="objBreakdown.kevrCategory2"
                            @click:clear="clearIt(0, 'objBreakdown.kevrCategory2')"
                            clearable
                            dense>
                  </v-select>
                </v-col>
              </v-row>
              <v-row style="height: 80px">
                <v-col lg="6" md="6" cols="6">
                  <v-select label="Община"
                            :items="properties.municipalities"
                            item-value="key"
                            item-text="value"
                            v-model="objBreakdown.municipalityName"
                            @click:clear="clearIt(0, 'objBreakdown.municipalityName')"
                            clearable
                            dense>
                  </v-select>
                </v-col>
                <v-col lg="6" md="6" cols="6">
                  <v-text-field label="Град"
                                v-model="objBreakdown.townName"
                                @click:clear="clearIt(0, 'objBreakdown.townName')"
                                clearable
                                dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row style="height: 80px">
                <v-col lg="6" md="6" cols="6">
                  <v-text-field label="Улица"
                                v-model="objBreakdown.streetName"
                                @click:clear="clearIt(0, objBreakdown.streetName)"
                                clearable
                                dense>
                  </v-text-field>
                </v-col>
                <v-col lg="6" md="6" cols="6"> </v-col>
              </v-row>
            </template>
          </v-card-text>
          </v-card>
          <div style="display: flex; justify-content: flex-start;margin-left: 30px;">
            <div v-show="objBreakdown.id != null"
                 style="margin-top: 10px;">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn class="successGradient"
                         v-on="on"
                         @click="UpdateBreakdown(objBreakdown)">
                    Запази
                  </v-btn>
                </template>
                <span>Запазва данните за аварията, описани в двете секции.</span>
              </v-tooltip>
            </div>
          </div>
</v-col>
    </v-row>
    <v-card style="border-top-right-radius: 30px;
                       border-bottom-right-radius: 30px;
                       border-bottom-left-radius: 30px;"
            class="v-card-profile"
            color="white"
            expandable
            :expanded.sync="expandCardDetails">

      <v-avatar style="border-top-right-radius: 20%;
                           border-bottom-left-radius: 20%;
                           border-bottom-right-radius: 20%;
                           margin-left: 20px;
                           margin-top: -40px;"
                left
                color="#263238"
                size="107">
        <v-icon style="font-size: 400%; color: #ffffff;">mdi-pipe-wrench</v-icon>
      </v-avatar>

      <v-card-text class="text-center">
        <v-row>
          <v-col class="col-5">
            <div style="display: flex; justify-content: center;">
              <div>
                <h3>Смяна на статуса на сигнал.</h3>
                <v-list style="height: 230px;
                               max-width: 450px;
                               margin-top: 45px;"
                        class="overflow-y-auto">
                  <v-list-item-group v-model="newSignalStatusId">
                    <v-list-item v-for="(item, indx) in actions"
                                 :key="indx"
                                 style="text-align: start;">
                      <v-list-item-content>
                        <v-list-item-title v-if="indx == 0"
                                           style="color: #606060;"
                                           v-text="item.value"
                                           class="myTile" />
                        <v-list-item-title v-else
                                           style="color: black;font="
                                           v-text="item.value"
                                           class="myTile" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn style="margin-top: 25px;"
                           class="successGradient"
                           v-on="on"
                           @click="changeSignalStatus(signalId, newSignalStatusId)">
                      Запази
                    </v-btn>
                  </template>
                  <span>
                    Смяна на статуса на сигнал [{{signalDetails.id}}].
                  </span>
                </v-tooltip>
              </div>
            </div>
          </v-col>
          <v-col class="col-2">
            <div style="display: flex; justify-content: center;">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn style="margin-top: 320px;
                                  display: flex;
                                  justify-content: center;"
                         class="successGradient"
                         v-on="on"
                         @click="newSignalFromOperator()">
                    Подай нов сигнал
                  </v-btn>
                </template>
                <span>
                  Вие като оператор можете да подавате сигнали.
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col class="col-5">
            <div style="display: flex; justify-content: center; height: 76%;">
              <div>
                <h3>Преглед на прикачени файлове.</h3>
                <v-list style="max-height: 230px;
                               max-width: 550px;
                               margin-top: 25px;"
                        class="overflow-y-auto"
                        multiple>
                  <v-list-item-group multiple
                                     v-model="selectedAttachments">
                    <v-list-item v-if="allAttachmentsInfo.length == 0">
                      <span style="margin-left: 45px; margin-top: 30px;">Този сигнал няма <br />прикачени файлове.</span>
                    </v-list-item>

                    <v-list-item v-for="item in allAttachmentsInfo"
                                 :key="item.id"
                                 style="text-align: start; margin-top: 30px;">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-list-item-title v-text="item.displayAttDetails"
                                             v-on="on"
                                             class="myTile">
                          </v-list-item-title>
                        </template>
                        <span>{{item.displayAttDetails}} </span>
                      </v-tooltip>

                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </div>
            <v-tooltip v-if="allAttachmentsInfo.length > 0" bottom>
              <template v-slot:activator="{ on }">
                <v-btn style="margin-top: 45px;"
                       class="successGradient"
                       v-on="on"
                       @click="downloadAllAttachments(signalId)">
                  Изтегли
                </v-btn>
              </template>
              <span>
                Сваляне на определен файл [{{signalDetails.id}}].
              </span>
            </v-tooltip>
          </v-col>

        </v-row>
      </v-card-text>
      </v-card>

      <v-card style="border-top-right-radius: 30px;" class="mx-4" cols="4">
        <base-map :isReadonly="true" :isBreakdown="false" :value="{ lat: latitude, lng: longitude }"></base-map>
      </v-card>

      <template>
        <div style="margin: 0 auto">
          <v-card style="border-top-right-radius: 30px;"
                  class="mx-4" cols="4">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">Потребител</div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ clientName }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
        <div style="margin: 0 auto"
             v-for="value in newEmail.allResponses"
             v-bind:key="value.id">
          <v-card style="border-bottom-right-radius: 30px;
                         border-bottom-left-radius: 30px;"
                  class="mx-4 my-12" cols="12">
            <v-card-title>
              <p>
                {{ value.fromClient == 0 ? "Оператор:" : "Потребител:" }}
              </p>
            </v-card-title>
            <v-card-text>
              <div style="margin-top: 10px" class="my-4 text-subtitle-1">
                На: {{ formatDateTime(value.respondedUtc) }}
              </div>
              <div v-if="
                   value.message.From !=null &&
                   value.message.From !=undefined &&
                   value.message.From.length>
                0
                "
                style="margin-top: 10px"
                >
                Ел. поща от: {{ value.message.From }}
              </div>
              <v-divider style="margin-top: 10px" class="mx-lg-4"></v-divider>

              <tiptap-vuetify :disabled="true" :value="value.message" />
            </v-card-text>

            <div style="display: flex; justify-content: start">
              <div style="display: inline-block;">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn style="margin-left: 10px; margin-top: -30px;"
                           v-show="value.attachmentsCount > 0"
                           v-on="on"
                           icon
                           tile
                           large
                           @click="downloadAttachments(value.id, value.selectedAttachments)">
                      <v-icon class="mr-2">mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span v-show="value.attachmentsCount > 1">
                    Това съобщениие има прикачени {{ value.attachmentsCount }} файлa и Вие можете да ги изтеглите от тук.
                  </span>
                  <span v-show="value.attachmentsCount == 1">
                    Това съобщениие има прикачен 1 файл и Вие можете да гo изтеглите от тук.
                  </span>
                </v-tooltip>
              </div>
              <div style="display: inline-block;margin-top: -35px;">
                <v-sheet class="mx-auto"
                         max-width="600px">
                  <v-slide-group multiple
                                 show-arrows>
                    <v-slide-item v-for="(item, indx) in getAttachmentsByReposneseId(value.id, allAttachmentsInfo)"
                                  :key="indx">
                      <v-checkbox class="mx-2"
                                  :label="`${item.displayAttDetails1}`"
                                  @click="selectAttachmentOfThisResponse(value.id, indx + 1)">
                      </v-checkbox>
                    </v-slide-item>
                  </v-slide-group>
                </v-sheet>
              </div>
            </div>

          </v-card>
        </div>
        <span style="color: #283593; font-size: 18px; margin-left: 15px;">Изпрати съобщение</span>

        <editor-component style="margin-left: 15px;
                                           margin-right: 25px;"
                          v-model="messageBody"
                          ref="editorComponent"></editor-component>
      </template>

      <div style="margin-left: 15px; margin-top: 15px;">
        <v-file-input v-model="attachments"
                      placeholder="Прикачи файл към съобщението."
                      multiple
                      show-size
                      style="width: 350px;">
        </v-file-input>
      </div>

      <div style="margin-left: 15px;">
        <v-btn class="successGradient"
               @click="createResponse(signalId, attachments)">
          Изпрати
        </v-btn>
      </div>
</v-container>
</template>

<script>
  import axios from 'axios';
  import config from '@/config'
  import moment from "moment";
  import {
    getSignalDetails,
    changeSignalStatus,
    getAttachmentsInfoBySignalId
  } from "@/api/signals";
  import {
    getResponsesList
  } from "@/api/responses";
  import {
    getUserAsKeyValuePairList
  } from "@/api/users";
  import {
    getSignalPriorityAsKeyValuePairList
  } from "@/api/priorities";
  import {
    getKEVRCategoriesAsKeyValuePairList
  } from "@/api/kevr_category";
  import {
    getKEVRSecondaryCategoriesAsKeyValuePairList
  } from "@/api/kevr_secondary_category";
  import {
    getBreakdownById, updateBreakdown
  } from "@/api/breakdowns";

  import {
    editorComponent
  } from "@/components";
  import {
    TiptapVuetify
  } from "tiptap-vuetify";
  import {
    ISODateString,
    formatDateTime
  } from "@/utils";

  export default {
    name: "signalsOperPreview",
    components: {
      editorComponent,
      TiptapVuetify,
    },
    data() {
      return {
        messageBody: "",
        constants: Object({
          operator: false,
          client: true,
          serverDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
          serverDateTimeFormat1: "YYYY-MM-DDTHH:mm:ssZ",
          userDateTimeFormat: "YYYY-MM-DD HH:mm:ssZ",
          userDateFormat: "YYYY-MM-DD", //it will be reevaluated again in getUseDateAndUserTimeFormatFromMomentDefaultFormat()
          userTimeFormat: "HH:mm:ss", //it will be reevaluated again in getUseDateAndUserTimeFormatFromMomentDefaultFormat()
          operatorEmail: "aquagis@legit.bg",
          department: "гр. София",
          useMessage: 0,
          useEmail: 1,
          statusBreakdownName: "Авария",
        }),

        actions: [
          { key: "Signal", value: "Сигнал" },
          { key: "Breakdown", value: "Авария" },
          { key: "Rejected", value: "Отхвърлен" },
          { key: "Closed", value: "Затворен" },
        ],

        data: [],

        signalDetails: {
          attachment: null,
          closedLocal: null,
          createdLocal: null,
          id: null,
          lastResponseLocal: null,
          latutude: 0.0,
          longitude: 0.0,
          needReply: null,
          priorityObject: null,
          statusObject: null,
          responses: null,
          userId: null,
          breakdownId: null,
        },

        objBreakdown: {
          id: null,
          finished: null,
          signalTimestamp: null,
          repairStart: null,
          repairEnd: null,
          waterStopStart: null,
          waterStopEnd: null,
          waterStopPeople: null,
          kevrCategory1: null,
          kevrCategory1Display: null,
          kevrCategory2: null,
          kevrCategory2Display: null,
          regionName: null,
          municipalityName: null,
          priorityId: null,
          townName: null,
          streetName: null,
          streetDescription: null,
        },

        objBreakdownInitData: null,

        waterMeterInitData: {}, //we will detect updateable properties by both objBreakdown, waterMeterInitData

        properties: {
          kevrCategories: [],
          kevrSecondaryCategories: [],
          municipalities: [],
          priorities: [],
          finishValues: [
            { key: true, value: "Да" },
            { key: false, value: "Не" },
          ],
        },

        responseDetails: {
          signalId: null,
          message: null,
          fromClient: null,
        },

        newEmail: {
          subject: null,
          to: null,
          body: null,
          attachedFilePaths: "",
          allResponses: [],
        },

        users: [],
        signalId: null,
        clientName: null,
        signalLastEmailSubmitter: null, //"Потребител"/ "Оператор"
        attachments: [],

        Dirty: {
          menuRepairStartDate: null,
          menuRepairStartTime: null,
          repairStartDate: null,
          repairStartTime: null,

          menuRepairEndDate: null,
          menuRepairEndTime: null,
          repairEndDate: null,
          repairEndTime: null,

          menuWaterStopStartDate: null,
          menuWaterStopStartTime: null,
          waterStopStartDate: null,
          waterStopStarTime: null,

          menuWaterStopEndDate: null,
          menuWaterStopEndTime: null,
          waterStopEndDate: null,
          waterStopEndTime: null,

          menuSignalTimestampDate: null,
          menuSignalTimestampTime: null,
          signalTimestampDate: null,
          signalTimestampTime: null,
        },
        allAttachmentsInfo: [],
        selectedAttachments: [],
        currentResponseId: null,

        newSignalStatusId: null,
        oldSignalStatusId: null,

        latitude: 0.0,
        longitude: 0.0,
        expandCardDetails: false,
      };
    },

    mounted() {
      this.getUseDateAndUserTimeFormatFromMomentDefaultFormat();

      this.signalId = this.$route.params.id;

      this.getSignalDetails(this.signalId);
      this.getResponsesByThisSignalId(this.signalId);
      this.getAttachmentsInfoBySignalId(this.signalId);
    },

    watch: {},

    computed: {},

    created() {
      this.getPrioritiesList();
      this.getKEVRCategoriesList();
      this.getKEVRSecondaryCategoriesList();
      this.getMunicipalitiesList();
    },

    methods: {
      formatDateTime: formatDateTime,
      ISODateString: ISODateString,

      getResponsesByThisSignalId(signalId) {
        var query = { signalId: signalId };
        getResponsesList(query).then((data) => {

          this.newEmail.allResponses = data.items;

          this.newEmail.allResponses.forEach((item) => {
            item.message = "" + JSON.parse(item.message);

            item.attachmentsCount = item.attachmentToResponses.length;
            item.selectedAttachments = [];
          });

          this.signalLastEmailSubmitter = GetLastResponseOwner(
            this.newEmail.allResponses
          );
        });
      },

      changeSignalStatus(signalId, newSignalStatusId) {
        if (signalId > 0 && status != undefined && newSignalStatusId != null && newSignalStatusId != this.oldSignalStatusId) {


          if (newSignalStatusId == 0) {
            this.$snotify.success("Не е позволено промяна на статуса от '" +
              this.actions[this.oldSignalStatusId].value +
              "' към '" +
              this.actions[newSignalStatusId].value + "' ");

            this.newSignalStatusId = this.oldSignalStatusId;
            return;
          }

          //var newSignalStatusBgName = GetSignalStatusBgName(this.actions, newSignalStatusId)
          var newSignalStatusEnName = this.actions[newSignalStatusId].key;

          changeSignalStatus(signalId, newSignalStatusEnName).then((data) => {
            if (data !== null) {
              if (data.indexOf("(" + signalId + ")") == 0) {
                //no errors

                this.oldSignalStatusId = newSignalStatusId;

                this.$snotify.success(data);

                this.getSignalDetails(signalId);
                this.$forceUpdate();
              } else {
                this.$snotify.error(data);
              }
            }
          });
        }
      },

      getSignalDetails(signalId) {
        getSignalDetails(signalId).then((data) => {
          this.signalDetails = data;

          this.latitude = this.signalDetails.latitude + 0.00000;
          this.longitude = this.signalDetails.longitude + 0.00000;

          this.newSignalStatusId = GetSignalStatusId(this.actions, data.statusObject);
          this.oldSignalStatusId = this.newSignalStatusId;

          if (this.signalDetails.statusObject === this.constants.statusBreakdownName) {
            //this is a signal with brekadown status
            if (this.signalDetails.breakdownId != null) {
              //this signal has bound to an existining breakdown
              this.getBreakdownById(this.signalDetails.breakdownId);
            }
          }

          if (this.signalDetails.closedLocal !== null)
            this.signalDetails.closedLocal = moment(
              this.signalDetails.closedLocal, this.constants.userDateFormat
            ).format(this.constants.userDateFormat);

          if (this.signalDetails.createLocal !== null)
            this.signalDetails.createdLocal = moment(
              this.signalDetails.createdLocal, this.constants.userDateFormat
            ).format(this.constants.userDateFormat);

          if (this.signalDetails.lastResponseLocal !== null)
            this.signalDetails.lastResponseLocal = moment(
              this.signalDetails.lastResponseLocal, this.constants.userDateFormat
            ).format(this.constants.userDateFormat);

          this.getUserList(this.signalDetails.userId);
        });
      },

      getUserList(userId) {
        getUserAsKeyValuePairList(userId).then((data) => {
          this.users = data;

          this.clientName = GetUserName(this.users, userId);
        });
      },

      async createResponse(signalId, attachments) {
        if (signalId > 0) {
          var clientMessage = this.$refs.editorComponent.content;
          var fromClient = this.constants.operator;

          let formData = new FormData();
          for (var i = 0; i < attachments.length; i++) {
            formData.append('attachments', attachments[i]);
          }

          var url = 'api/response/CreateResponse/';
          url += '?signalId=' + signalId;
          url += '&message=' + clientMessage;
          url += '&fromClient=' + fromClient;

          await axios.post(url,
            formData, {
            baseURL: config.apiBaseURL,
            headers: { 'Content-Type': 'multipart/form-data' },
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            timeout: config.requestTimeout * 60000
          })
            .then(response => {
              var index1 = response.data.indexOf('(');
              var index2 = response.data.indexOf(')');
              if (index1 > -1 && index1 < index2) {
                var responseId = parseInt(response.data.substring(index1 + 1, index2), 10);
                if (responseId > 0) {
                  this.getResponsesByThisSignalId(signalId);
                  this.getAttachmentsInfoBySignalId(signalId);
                  this.$snotify.success('Съобщението е изпратено.');
                }
              } else {
                this.$snotify.error(
                  'Съобщението не прието, опитайте пак.'
                );
              }

              this.messageBody = null;
              this.attachments = [];
              this.$refs.editorComponent.content = null;
              this.$forceUpdate();
            })
            .catch(error => {
              this.$snotify.error(
                'Съобщението не прието, грешка ' + error + '.'
              );

              this.messageBody = null;
              this.attachments = [];
            });
        }
      },

      getHeadingText() {
        return "Преглед на сигнал " + this.signalDetails.id + " от оператор";
      },

      clearIt(item, propName) {
        this.itemSelectedMarker = this.itemSelectedMarker - item;
        this.filters[propName] = null;
      },

      getPrioritiesList() {
        getSignalPriorityAsKeyValuePairList().then((data) => {
          data.splice(0, 1); //will remove 0 index (this is "AllItems")
          this.properties.priorities = data;
        });
      },

      getKEVRCategoriesList() {
        getKEVRCategoriesAsKeyValuePairList().then((data) => {
          data.splice(0, 1); //will remove 0 index (this is "AllItems")
          this.properties.kevrCategories = data;
        });
      },

      getKEVRSecondaryCategoriesList() {
        getKEVRSecondaryCategoriesAsKeyValuePairList().then((data) => {
          data.splice(0, 1); //will remove 0 index (this is "AllItems")

          this.properties.kevrSecondaryCategories = data;
        });
      },

      getMunicipalitiesList() {
        //getKEVRSecondaryCategoriesAsKeyValuePairList().then(data => {
        //
        //  this.properties.municipalities = data;
        //})
      },

      getBreakdownById(breakdownId) {
        getBreakdownById(breakdownId).then((data) => {
          this.objBreakdown = data;

          this.objBreakdown.kevrCategory1Display =
            this.objBreakdown.kevrCategory1Navigation.code +
            " - " +
            this.objBreakdown.kevrCategory1Navigation.nameBg;
          this.objBreakdown.kevrCategory2Display =
            this.objBreakdown.kevrCategory2Navigation.code +
            " - " +
            this.objBreakdown.kevrCategory2Navigation.nameBg;

          this.objBreakdownInitData = JSON.parse(
            JSON.stringify(this.objBreakdown)
          ); //deep copy

          this.objBreakdownInitData.signalTimestamp = moment(
            this.objBreakdownInitData.signalTimestamp
          ).format(this.constants.serverDateTimeFormat);
          this.objBreakdownInitData.repairStart = moment(
            this.objBreakdownInitData.repairStart
          ).format(this.constants.serverDateTimeFormat);
          this.objBreakdownInitData.repairEnd = moment(
            this.objBreakdownInitData.repairEnd
          ).format(this.constants.serverDateTimeFormat);
          this.objBreakdownInitData.waterStopStart = moment(
            this.objBreakdownInitData.waterStopStart
          ).format(this.constants.serverDateTimeFormat);
          this.objBreakdownInitData.waterStopEnd = moment(
            this.objBreakdownInitData.waterStopEnd
          ).format(this.constants.serverDateTimeFormat);

          this.Dirty.signalTimestampDate = null;
          this.Dirty.signalTimestampTime = null;
          this.Dirty.repairStartDate = null;
          this.Dirty.repairStartTime = null;
          this.Dirty.repairEndDate = null;
          this.Dirty.repairEndTime = null;
          this.Dirty.waterStopStartDate = null;
          this.Dirty.waterStopStartTime = null;
          this.Dirty.waterStopEndDate = null;
          this.Dirty.waterStopEndTime = null;

          if (this.objBreakdown.signalTimestamp !== null) {
            this.objBreakdown.signalTimestamp = moment(
              this.objBreakdown.signalTimestamp
            ).format();
            this.Dirty.signalTimestampDate = moment(
              this.objBreakdown.signalTimestamp
            ).format(this.constants.userDateFormat);
            this.Dirty.signalTimestampTime = moment(
              this.objBreakdown.signalTimestamp
            ).format(this.constants.userTimeFormat);
          }

          if (this.objBreakdown.repairStart !== null) {
            this.objBreakdown.repairStart = moment(
              this.objBreakdown.repairStart, this.constants.serverDateTimeFormat
            ).format(this.constants.userDateTimeFormat);
            this.Dirty.repairStartDate = moment(
              this.objBreakdown.repairStart
            ).format(this.constants.userDateFormat);
            this.Dirty.repairStartTime = moment(
              this.objBreakdown.repairStart
            ).format(this.constants.userTimeFormat);
          }

          if (this.objBreakdown.repairEnd !== null) {
            this.objBreakdown.repairEnd = moment(
              this.objBreakdown.repairEnd
            ).format();
            this.Dirty.repairEndDate = moment(this.objBreakdown.repairEnd).format(
              this.constants.userDateFormat
            );
            this.Dirty.repairEndTime = moment(this.objBreakdown.repairEnd).format(
              this.constants.userTimeFormat
            );
          }

          if (this.objBreakdown.waterStopStart !== null) {
            this.objBreakdown.waterStopStart = moment(
              this.objBreakdown.waterStopStart
            ).format();
            this.Dirty.waterStopStartDate = moment(
              this.objBreakdown.waterStopStart
            ).format(this.constants.userDateFormat);
            this.Dirty.waterStopStartTime = moment(
              this.objBreakdown.waterStopStart
            ).format(this.constants.userTimeFormat);
          }

          if (this.objBreakdown.waterStopEnd !== null) {
            this.objBreakdown.waterStopEnd = moment(
              this.objBreakdown.waterStopEnd
            ).format();
            this.Dirty.waterStopEndDate = moment(
              this.objBreakdown.waterStopEnd
            ).format(this.constants.userDateFormat);
            this.Dirty.waterStopEndTime = moment(
              this.objBreakdown.waterStopEnd
            ).format(this.constants.userTimeFormat);
          }
        });
      },

      UpdateBreakdown(objBreakdown) {
        //only modified data will be send to the server

        objBreakdown.signalTimestamp = moment(
          new Date(
            moment(
              this.Dirty.signalTimestampDate,
              this.constants.userDateFormat
            ).year(),
            moment(
              this.Dirty.signalTimestampDate,
              this.constants.userDateFormat
            ).month(),
            moment(
              this.Dirty.signalTimestampDate,
              this.constants.userDateFormat
            ).date(),
            moment(
              this.Dirty.signalTimestampTime,
              this.constants.userTimeFormat
            ).hour(),
            moment(
              this.Dirty.signalTimestampTime,
              this.constants.userTimeFormat
            ).minute(),
            0
          )
        ).format(this.constants.serverDateTimeFormat);

        objBreakdown.repairStart = moment(
          new Date(
            moment(
              this.Dirty.repairStartDate,
              this.constants.userDateFormat
            ).year(),
            moment(
              this.Dirty.repairStartDate,
              this.constants.userDateFormat
            ).month(),
            moment(
              this.Dirty.repairStartDate,
              this.constants.userDateFormat
            ).date(),
            moment(
              this.Dirty.repairStartTime,
              this.constants.userTimeFormat
            ).hour(),
            moment(
              this.Dirty.repairStartTime,
              this.constants.userTimeFormat
            ).minute(),
            0
          )
        ).format(this.constants.serverDateTimeFormat);

        objBreakdown.repairEnd = moment(
          new Date(
            moment(
              this.Dirty.repairEndDate,
              this.constants.userDateFormat
            ).year(),
            moment(
              this.Dirty.repairEndDate,
              this.constants.userDateFormat
            ).month(),
            moment(
              this.Dirty.repairEndDate,
              this.constants.userDateFormat
            ).date(),
            moment(
              this.Dirty.repairEndTime,
              this.constants.userTimeFormat
            ).hour(),
            moment(
              this.Dirty.repairEndTime,
              this.constants.userTimeFormat
            ).minute(),
            0
          )
        ).format(this.constants.serverDateTimeFormat);

        objBreakdown.waterStopStart = moment(
          new Date(
            moment(
              this.Dirty.waterStopStartDate,
              this.constants.userDateFormat
            ).year(),
            moment(
              this.Dirty.waterStopStartDate,
              this.constants.userDateFormat
            ).month(),
            moment(
              this.Dirty.waterStopStartDate,
              this.constants.userDateFormat
            ).date(),
            moment(
              this.Dirty.waterStopStartTime,
              this.constants.userTimeFormat
            ).hour(),
            moment(
              this.Dirty.waterStopStartTime,
              this.constants.userTimeFormat
            ).minute(),
            0
          )
        ).format(this.constants.serverDateTimeFormat);

        objBreakdown.waterStopEnd = moment(
          new Date(
            moment(
              this.Dirty.waterStopEndDate,
              this.constants.userDateFormat
            ).year(),
            moment(
              this.Dirty.waterStopEndDate,
              this.constants.userDateFormat
            ).month(),
            moment(
              this.Dirty.waterStopEndDate,
              this.constants.userDateFormat
            ).date(),
            moment(
              this.Dirty.waterStopEndTime,
              this.constants.userTimeFormat
            ).hour(),
            moment(
              this.Dirty.waterStopEndTime,
              this.constants.userTimeFormat
            ).minute(),
            0
          )
        ).format(this.constants.serverDateTimeFormat);

        var objBreakdownLoc = JSON.parse(JSON.stringify(objBreakdown)); //deep copy
        var objBreakdownInitData = this.objBreakdownInitData;

        var flagModify = false;
        for (var key in objBreakdownLoc) {
          if (key != "id")
            if (
              JSON.stringify(objBreakdownLoc[key]) ==
              JSON.stringify(objBreakdownInitData[key])
            ) {
              objBreakdownLoc[key] = null;
            }

            else flagModify = true;
        }

        if (flagModify == true) {
          updateBreakdown(objBreakdownLoc).then((data) => {
            if (data.id == undefined) {
              var index1 = data.indexOf("(");
              var index2 = data.indexOf(")");
              if (index1 > -1 && index1 < index2) {
                this.$snotify.error(
                  "Данните за авария " +
                  objBreakdown.workCardNo +
                  " не e обновени. Грешка " +
                  data.substring(index2 + 1) +
                  "."
                );
              }
            } else if (data.id > 0) {
              this.$snotify.success(
                "Данните за авария " + data.workCardNo + " са обновени успешно."
              );
              this.objBreakdown = data;
            }
          });
        }
      },

      getUseDateAndUserTimeFormatFromMomentDefaultFormat() {
        //extract UserDateFormat and UserTime=Format on base of moment.defaultFormat, which can be changeable.
        var momentDefaultFormatDate = moment({ year: 1, month: 1, day: 3, hour: 4, minute: 5, seconds: 6 }).format(); //one very good pattern

        var leadZeros = "",
          userDateFormat = "",
          userTimeFormat = "",
          flagParsingCounter = 0;

        for (var i = 0; i < momentDefaultFormatDate.length; i++) {
          if (flagParsingCounter == 6) break;

          var token = momentDefaultFormatDate[i];

          if (token == "0") leadZeros += "0";
          else if (token >= "1" && token <= "3") {
            if (token == "1") {
              //year
              userDateFormat += leadZeros.replace(/0/g, "Y") + "Y";
            } else if (token == "2") {
              //month
              userDateFormat += leadZeros.replace(/0/g, "M") + "M";
            } else if (token == "3") {
              //day
              userDateFormat += leadZeros.replace(/0/g, "D") + "D";
            }
            leadZeros = "";
            flagParsingCounter++;
          } else if (token >= "4" && token <= "6") {
            if (token == "4") {
              //hour
              userTimeFormat += leadZeros.replace(/0/g, "H") + "H";
            } else if (token == "5") {
              //minute
              userTimeFormat += leadZeros.replace(/0/g, "m") + "m";
            } else if (token == "6") {
              //second
              userTimeFormat += leadZeros.replace(/0/g, "s") + "s";
            }
            leadZeros = "";
            flagParsingCounter++;
          } else {
            if (flagParsingCounter >= 1 && flagParsingCounter < 3)
              userDateFormat += token;
            else if (flagParsingCounter >= 4 && flagParsingCounter < 6)
              userTimeFormat += token;
          }
        }

        this.constants.userDateFormat = userDateFormat;
        this.constants.userTimeFormat = userTimeFormat;

        return;
      },

      downloadAttachments(responseId, selectedAttachmentsOfCurrentResponse) {
        if (responseId <= 0 || selectedAttachmentsOfCurrentResponse.length <= 0)
          return;

        let currentIndex = 0;
        const intervalId = setInterval(() => {
          if (currentIndex === selectedAttachmentsOfCurrentResponse.length - 1) clearInterval(intervalId);

          var currentAttOrderNumber = selectedAttachmentsOfCurrentResponse[currentIndex];

          var url = config.apiBaseURL + 'api/Attachment/DownloadAttachmentFile/';
          url += '?responseId=' + responseId;
          url += '&attachmentOrderNumber=' + currentAttOrderNumber;

          window.location.href = url;
          currentIndex++;
        }, 1000);
      },

      downloadSelectedAttachments(signalId, selectedAttachments) {
        //selectedAttachments is an array of integers 0 or greater than 0

        if (signalId == undefined || signalId <= 0 || selectedAttachments.length <= 0)
          return;

        let currentIndex = 0;
        const intervalId = setInterval(() => {
          if (currentIndex === selectedAttachments.length - 1) clearInterval(intervalId);

          var url = config.apiBaseURL + 'api/Attachment/DownloadAttachmentFileBySignalId/';
          url += '?signalId=' + signalId;
          url += '&attachmentOrderNumber=' + selectedAttachments[currentIndex];

          window.location.href = url;
          currentIndex++;
        }, 1000);
      },

      downloadAllAttachments(signalId) {
        if (signalId != 0) {
          var url = config.apiBaseURL + 'api/Attachment/DownloadAllAttachmentsBySignalId/';
          url += '?signalId=' + signalId;

          window.location.href = url;
        }
        else {
          return;
        }
      },

      newSignalFromOperator() {
        this.$router.push({ path: `/signal_from_oper/preview` })
        this.$forceUpdate();

       // this.$router.push({ path: `/breakdowns/add` });
       // this.$forceUpdate();
      },

      getAttachmentsInfoBySignalId(signalId) {
        if (signalId > 0) {
          getAttachmentsInfoBySignalId(signalId).then(data => {
            for (var i = 0; i < data.length; i++) {

              data[i].displayAttDetails = ((i + 1) + '. ').padStart(5 - (i + '. ').length, ' ')
                + data[i].fileName + ' от '
                + moment(data[i].createdOn).format(this.constants.dateTimeFormt);

              data[i].displayAttDetails1 = data[i].fileName;

            }

            this.allAttachmentsInfo = data;
          });
        }
      },

      getAttachmentsByReposneseId(responseId, allAttachmentsInfo) {
        var attachments = [];
        allAttachmentsInfo.forEach((item) => {
          if (item.responseId == responseId) {
            attachments.push(item);
          }
        })

        return attachments;
      },

      selectAttachmentOfThisResponse(responseId, attachmentOrderNumber) {
        var response = GetResponseByResponseId(responseId, this.newEmail.allResponses);

        if (this.currentResponseId != responseId)
          response.selectedAttachments = [];

        this.currentResponseId = responseId;

        var index = response.selectedAttachments.indexOf(attachmentOrderNumber)
        if (index == -1)
          response.selectedAttachments.push(attachmentOrderNumber);
        else
          response.selectedAttachments.splice(index, 1);
      }
    },
  };

  function GetUserName(users, userId) {
    if (users != null && users.length > 0) {
      var res = users.find((kvp) => kvp.key == userId);
      if (res != undefined) return res.value.trim();
    }

    return "-";
  }

  function GetLastResponseOwner(responses) {
    if (responses != null && responses.length > 0) {
      var res = responses[responses.length - 1].fromClient;
      if (res != undefined && res == true) {
        return "Потребител";
      } else {
        return "Оператор";
      }
    }
    return "-";
  }

  function GetSignalStatusId(actions, signalStatusBgName) {
    //0,1,2,3
    for (var i = 0; i < actions.length; i++) {
      if (signalStatusBgName == actions[i].value) {
        return i;
      }
    }
    return null;
  }

  function GetResponseByResponseId(responseId, allResponses) {
    for (var i = 0; i < allResponses.length; i++) {
      if (allResponses[i].id == responseId)
        return allResponses[i];
    }

    return null;
  }

</script>
