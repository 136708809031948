import request from '@/utils/request';
import { filterQuery } from '@/utils/index';

export function createSignal(signalData) {
  return request({
    url: '/api/signal/CreateSignal',
    method: 'post',
    data: signalData
  });
}

export function getSignalList(query) {
  return request({
    url: '/api/signal/GetSignalList',
    method: 'get',
    params: filterQuery(query)
  });
}

export function changeSignalStatus(signalId, status) {
  return request({
    url: '/api/signal/ChangeSignalStatus?signalId=' + signalId + "&status=" + status,
    method: 'post',
  });
}

export function getSignalDetails(signalId) {
  return request({
    url: '/api/signal/GetSignalDetails?signalId=' + signalId,
    method: 'get',
  });
}

export function getSignalListByBreakdownId(breakdownId) {
  return request({
    url: '/api/signal/GetSignalListByBreakdownId?breakdownId=' + breakdownId,
    method: 'get',
  });
}

export function saveBreakdownBoundSignals(breakdownId, selectedSignalsIds, unSelectedSignalsIds) {
  return request({
    url: '/api/signal/SaveBreakdownBoundSignals?breakdownId=' + breakdownId +
      "&selectedSignalsIds=" + selectedSignalsIds +
      "&unSelectedSignalsIds=" + unSelectedSignalsIds,
    method: 'post',
  });
}

export function getAttachmentsInfoBySignalId(signalId) {
  return request({
    url: '/api/signal/getAttachmentsInfoBySignalId?signalId=' + signalId,
    method: 'get',
  });
}

