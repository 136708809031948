import router from './router';
import store from './store';
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/getPageTitle';
import config from "@/config";

router.beforeEach(async (to, from, next) => {
  // set page title
  document.title = getPageTitle(to.meta.title + " :: " + config.title);

  // determine whether the user has logged in
  const hasToken = getToken();

  // determine whether the user has obtained his permission roles through getInfo
  const hasActions = store.getters.actions && store.getters.actions.length > 0;

  if (hasActions) {
    store.dispatch('permission/addLastRoute', from);
    next();
  } else {
    try {
      // get user info
      // note: actions must be an array such as: ['Admin', 'Oper', 'User'] etc..
      const { allowedActions, allowedGroups } = await store.dispatch('user/getInfo');

      // generate accessible routes map based on roles
      const accessRoutes = await store.dispatch('permission/generateRoutes', { allowedActions, allowedGroups });
      // dynamically add accessible routes
      router.addRoutes(accessRoutes);

      // generate accessible dashboard links based on roles/ groups
      await store.dispatch('permission/generateDashboards', allowedGroups);

      // check if user has access to the requested route by its actions... if not - catch first available route and redirect user there
      // it is possible to get an error in 'router.resolve' so that's the reason of catch block
      let goTo;
      try {
        let isRouteExists = router.resolve({ path: to.path });
        if (isRouteExists.resolved.matched.length === 0 || isRouteExists.resolved.matched[0].path === '*') {
          for (let route of router.options.routes) {
            if (route.path !== '/' && route.path !== '/authentication' && route.path != '/*' && route.path != '*') {
              goTo = route;
              break;
            }
          }
          next({ path: goTo.path });
          return;
        }
      } catch (e) {
        // we are here because of router.resolve :)
        for (let route of router.options.routes) {
          if (route.path !== '/' && route.path !== '/authentication' && route.path != '/*' && route.path != '*') {
            goTo = route;
            break;
          }
        }
        next({ path: goTo.path });
        return;
      }

      // user has access to the route.. so redirect it
      next({ ...to, replace: true })
    } catch (error) {
      next(`${to.path}`);
    }
  }
});