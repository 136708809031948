<template>
  <v-container id="waterMeterPreview" ref="waterMeterPreview" fluid tag="section" class="full-height">
    <base-v-component :heading="headingText()" goBackText="Обратно към водомери" goBackTo="/water_meters" />

    <v-row class="justify-center">
      <v-col>
        <v-card style="
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            border-bottom-left-radius: 30px;
          " class="v-card--material pa-3" color="white">
          <v-avatar style="
              border-top-right-radius: 20%;
              border-bottom-left-radius: 20%;
              border-bottom-right-radius: 20%;
              margin-left: 20px;
              margin-top: -40px;
            " left color="#263238" size="107">
            <v-icon style="font-size: 400%; color: #ffffff">mdi-gauge</v-icon>
          </v-avatar>

          <v-card-text class="text-center" >
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Водомер №" height="30" v-model="waterMeterData.waterMeterFabricNo" autocomplete="off"
                  disabled dense />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Дата последен ремонт" height="30" autocomplete="off"
                  v-model="waterMeterData.lastDateOfRepair" disabled dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row style="height: 80px" v-if="false">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Гео-координати, latitude" v-model="waterMeterData.latitude" autocomplete="off"
                  placeholder="Използвайте картата за избор (doube-click)." readonly dense />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Гео-координати, longitude" height="30" autocomplete="off"
                  v-model="waterMeterData.longitude" placeholder="Използвайте картата за избор (doube-click)." readonly
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row style="height: 80px; margin-top: -20px">
              <v-col lg="6" md="6" cols="6">
                <v-menu v-model="menuInstalledDateTime" v-bind:nudge-right="40" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field label="Дата на инсталация" height="30" autocomplete="off" v-on="on"
                      v-model="waterMeterData.installationDate" readonly dense>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="waterMeterData.installationDate" @@input="validFromMenu = false" persistent-hint
                    color="primary" v-bind:first-day-of-week="1" locale="bg-bg">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-menu v-model="menuCalibrationDateTime" v-bind:nudge-right="40" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field label="Дата на калибриране" height="30" autocomplete="off" v-on="on"
                      v-model="waterMeterData.calibrationDate" readonly dense>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="waterMeterData.calibrationDate" @@input="validFromMenu = false" persistent-hint
                    color="primary" v-bind:first-day-of-week="1" locale="bg-bg">
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Дата последно засичане" height="30" autocomplete="off"
                  v-model="waterMeterData.lastDateOfCheck" disabled dense>
                </v-text-field>
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-menu v-model="menuNextCalibrationDateTime" v-bind:nudge-right="40" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field label="Дата на следваща подмяна" height="30" autocomplete="off" v-on="on"
                      v-model="waterMeterData.nextCalibrationDate" dense>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="waterMeterData.nextCalibrationDate" @@input="validFromMenu = false"
                    persistent-hint color="primary" v-bind:first-day-of-week="1" locale="bg-bg">
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Засечена стойност" height="30" v-model="waterMeterData.checkValue" autocomplete="off"
                  disabled dense />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Следващо засичане на" height="30" autocomplete="off"
                  v-model="waterMeterData.nextDateOfCheck" disabled dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <base-select label="Обш / Индивидуален" height="30" :items="nomenclatures.waterMeterOwnershipTypes"
                  item-text="value" item-value="key" v-model="waterMeterData.waterMeterOwnershipTypeId" disabled dense>
                </base-select>
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Създаден / последно променен" height="30"
                  v-model="waterMeterData.createdOnModifiedOn" autocomplete="off" disabled dense />
              </v-col>
            </v-row>
            <!-- <v-col lg="6" md="6" cols="6">
                <base-select label="Тип водомер" height="30" :items="nomenclatures.waterMeterTypes" item-text="value"
                  item-value="key" v-model="waterMeterData.waterMeterTypeId" disabled dense>
                </base-select>
              </v-col> -->

            <!-- <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <base-select label="Производител" height="30" :items="nomenclatures.manufacturers" item-text="value"
                  item-value="key" v-model="waterMeterData.manufacturerId" disabled dense>
                </base-select>
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Апартаменти ползващи водомера" height="30"
                  v-model="waterMeterData.apartamentsTotal" autocomplete="off" disabled dense />
              </v-col>
            </v-row> -->
            <!-- <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Хора ползващи водомера" height="30" v-model="waterMeterData.customersTotal"
                  autocomplete="off" disabled dense />
              </v-col>
              
              <v-col lg="6" md="6" cols="6"> </v-col>
            </v-row> -->
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Инкасатор номер" v-if="waterMeterData.auditor !== null"
                  v-model="waterMeterData.auditor.auditorNumber" autocomplete="off" readonly dense />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Инкасатор име" v-if="waterMeterData.auditor !== null"
                  v-model="waterMeterData.auditor.name" autocomplete="off" readonly dense />
              </v-col>
              <v-col lg="6" md="6" cols="6"> </v-col>
            </v-row>
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="СВО идентификатор" v-model="waterMeterData.svoId" autocomplete="off" readonly
                  dense />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Общ водомер №" v-if="waterMeterData.waterMeterOwnershipTypeId !== 2 &&
                  waterMeterData.mainWaterMeterId !== null
                  " v-model="waterMeterData.mainWaterMeter.waterMeterFabricNo" autocomplete="off" readonly dense />
              </v-col>
            </v-row>
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6"> </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-btn class="successGradient" width="140px" style="float: right"
                  @click="updateWaterMeter(waterMeterData)">
                  <v-icon style="margin-right: 10px">mdi-content-save</v-icon>
                  <span>Запази</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card style="
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            border-bottom-left-radius: 30px;
          " class="v-card--material pa-3" color="white">
          <v-avatar style="
              border-top-right-radius: 20%;
              border-bottom-left-radius: 20%;
              border-bottom-right-radius: 20%;
              margin-left: 20px;
              margin-top: -40px;
            " left color="#263238" size="107">
            <v-icon style="font-size: 400%; color: #ffffff">mdi-account-outline</v-icon>
          </v-avatar>

          <v-card-text class="text-center">
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field v-show="clientData.clientNumber != null" height="30" label="Клиентски номер"
                  v-model="clientData.clientNumber" autocomplete="off" disabled dense />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-text-field v-show="clientData.clientNumber != null" height="30" label="Име" v-model="clientData.name"
                  autocomplete="off" disabled dense />
              </v-col>
            </v-row>
            <v-row style="height: 80px" v-show="clientData.clientNumber != null">
              <v-col lg="5" md="5" cols="5">
                <v-text-field label="Тип клиент" height="30" v-model="clientData.clientTypeName" autocomplete="off"
                  disabled dense />
              </v-col>
            </v-row>
            <v-col>
              <v-btn class="successGradient" width="120px" @click="clientPreview(waterMeterData.clientId)">
                <v-icon style="margin-right: 10px">mdi-account-check</v-icon>
                <span>Преглед клиент</span>
              </v-btn>
              <v-btn class="successGradient" width="120px" @click="addressPreview(waterMeterData.addressId)">
                <v-icon style="margin-right: 10px">mdi-account-check</v-icon>
                <span>Преглед адрес</span>
              </v-btn>
              <v-btn class="successGradient" width="120px" @click="goMapLocation">
                <v-icon style="margin-right: 10px">mdi-account-check</v-icon>
                <span>Преглед на карта </span>
              </v-btn>
            </v-col>

            <v-row style="height: 80px" align="center" justify="space-around" v-show="clientData.clientNumber == null">
              <v-btn class="successGradient" width="120px" @click="addressPreview(waterMeterData.addressId)">
                <v-icon style="margin-right: 10px">mdi-account-check</v-icon>
                <span>Преглед адрес</span>
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card style="
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            border-bottom-left-radius: 30px;
            margin-top: 45px;
          " class="v-card--material pa-3" color="white">
          <v-avatar style="
              border-top-right-radius: 20%;
              border-bottom-left-radius: 20%;
              border-bottom-right-radius: 20%;
              margin-left: 20px;
              margin-top: -40px;
            " left color="#263238" size="107">
            <v-icon style="font-size: 400%; color: #ffffff">mdi-home-outline</v-icon>
          </v-avatar>

          <v-card-text class="text-center" style="overflow-y: hidden;">
            <v-row style="height: 80px; margin-top: 5px;">
              <v-col lg="12" md="12" cols="12">
                <v-text-field label="Описание на адрес" v-model="addressData.addressDescription" autocomplete="off"
                  :disabled="addressDropdowns.description.disabled" dense @clear="onAddressDropdownClear('description')"
                  @change="onAddressDropdownChanges('description')" />
              </v-col>
            </v-row>
            <v-row style="height: 80px; margin-top: 5px;">
              <v-col lg="3" md="3" cols="3">
                <v-text-field label="Блок" v-model="addressData.block" autocomplete="off"
                  disabled dense />
              </v-col>
              <v-col lg="3" md="3" cols="3">
                <v-text-field label="Вход" v-model="addressData.entrance" autocomplete="off"
                  disabled dense />
              </v-col>
              <v-col lg="3" md="3" cols="3">
                <v-text-field label="Етаж" v-model="addressData.floor" autocomplete="off"
                  disabled dense />
              </v-col>
              <v-col lg="3" md="3" cols="3">
                <v-text-field label="Апартамент" v-model="addressData.apartment" autocomplete="off"
                  disabled dense />
              </v-col>
            </v-row>
    
      
            
            <!-- form for addres update starts here  -->
            <!-- <v-row style="height: 80px">
              <v-col lg="12" md="12" cols="12">
                <v-autocomplete v-model="addressData.streetName" :items="addressDropdowns.streetName.items"
                  item-text="value" item-value="key" label="Улица" autocomplete="on"
                  :disabled="addressDropdowns.streetName.disabled" @clear="onAddressDropdownClear('streetName')"
                  @change="onAddressDropdownChanges('streetName')" clearable></v-autocomplete>
              </v-col>
            </v-row>
            <v-row style="height: 80px">
              <v-col lg="3" md="3" cols="3">
                <v-autocomplete v-model="addressData.block" :items="addressDropdowns.block.items" item-text="value"
                  item-value="key" label="Блок" autocomplete="on" :disabled="addressDropdowns.block.disabled"
                  @clear="onAddressDropdownClear('block')" @change="onAddressDropdownChanges('block')"
                  clearable></v-autocomplete>
              </v-col>
              <v-col lg="3" md="3" cols="3">
                <v-autocomplete v-model="addressData.entrance" :items="addressDropdowns.entrance.items" item-text="value"
                  item-value="key" label="Вход" autocomplete="on" :disabled="addressDropdowns.entrance.disabled"
                  @clear="onAddressDropdownClear('entrance')" @change="onAddressDropdownChanges('entrance')"
                  clearable></v-autocomplete>
              </v-col>
              <v-col lg="3" md="3" cols="3">
                <v-autocomplete v-model="addressData.floor" :items="addressDropdowns.floor.items" item-text="value"
                  item-value="key" label="Етаж" autocomplete="on" :disabled="addressDropdowns.floor.disabled"
                  @clear="onAddressDropdownClear('floor')" @change="onAddressDropdownChanges('floor')"
                  clearable></v-autocomplete>
              </v-col>
              <v-col lg="3" md="3" cols="3">
                <v-autocomplete v-model="addressData.apartment" :items="addressDropdowns.apartment.items"
                  item-text="value" item-value="key" label="Апартамент" autocomplete="on"
                  :disabled="addressDropdowns.apartment.disabled" @clear="onAddressDropdownClear('apartment')"
                  @change="onAddressDropdownChanges('apartment')" clearable></v-autocomplete>
              </v-col>
            </v-row> -->
            <!-- <v-row style="height: 80px">
              <v-col lg="4" md="4" cols="4">
                <v-text-field label="Улица с код" v-model="addressData.streetGraoCodeStreetName" autocomplete="off"
                  disabled dense />
              </v-col>
              <v-col lg="4" md="4" cols="4">
                <v-text-field label="Карнет/страница по адрес" v-model="addressData.carnetAndPage" autocomplete="off"
                  disabled dense />
              </v-col>
              <v-col lg="4" md="4" cols="4">
                <v-text-field label="Гео Координати" v-model="addressData.latitudeLongitude" autocomplete="off"
                  disabled dense />
              </v-col>
            </v-row>
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Създаден / последно променен" v-model="addressData.createdOnModifiedOn"
                  autocomplete="off" disabled dense />
              </v-col>
            </v-row> -->
            <v-row style="height: 5px"> </v-row>
          </v-card-text>
          <v-card-actions class="justify-center">
            <!--<v-btn class="success"
                     width="220px"
                     @click="restoreAddress">
                <v-icon style="margin-right: 10px">mdi-restore</v-icon>
                <span>Възстанови оригинал</span>
              </v-btn>-->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn  v-if=false
                 class="success" width="220px" v-on="on" @click="saveAddressData(addressData)">
                  <v-icon style="margin-right: 10px">mdi-content-save</v-icon>
                  <span>Запази промените</span>
                </v-btn>
              </template>
              <span>Сменя адреса на избрания водомер.</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="#212833" width="220px" v-on="on" @click="redirectToClientWatermeter(addressData.id)"
                  v-show="addressData.latitudeLongitude">
                  <v-icon style="margin-right: 10px">mdi-open-in-new</v-icon>
                  <span>Към клиент</span>
                </v-btn>
              </template>
              <span>Виж клиента на картата</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- DEPRECATED -->
    <!-- <v-card style="border-top-right-radius: 30px;" class="mx-4" cols="4" v-show="show" height="520">
      <v-card-actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="show = !show" v-on="on">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </template>
          <span>Скрий картата</span>
        </v-tooltip>
        <h1>Гео-координати</h1>
      </v-card-actions>
      <v-expand-transition>
        <v-container v-if="show">
          <base-map :model="waterMeterData" :isReadonly="false" :isBreakdown="false">
          </base-map>
        </v-container>
      </v-expand-transition>
    </v-card>
    <v-card style="border-top-right-radius: 30px;" class="mx-4" cols="4" v-show="!show" height="60">
      <v-card-actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="show = !show" v-on="on">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <span>Разгърни картата</span>
        </v-tooltip>
        <h1>Гео-координати </h1>
      </v-card-actions>
    </v-card> -->

    <v-row justify="center" align="center">
      <v-col cols="12" lg="6" sm="12">
        <base-material-card style="
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            border-bottom-left-radius: 30px;
          ">
          <template v-slot:heading>
            <h1 class="display-2 text-center">Отчети</h1>
          </template>
          <v-data-table ref="auditTable" :headers="auditTableProps.headers" :items="auditTableProps.auditData"
            disable-pagination hide-default-footer :sort-by.sync="customSortColumn" :sort-desc.sync="customSortDesc"
            multi-sort style="max-height: 400px; overflow: auto">
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getWaterMeterlById,
  updateWaterMeter,
  getAddressPropertyDescriptionDropdownItemsAsKeyValuePairList,
  getAddressPropertyStreetNameDropdownItemsAsKeyValuePairList,
  getAddressPropertyBlockDropdownItemsAsKeyValuePairList,
  getAddressPropertyEntranceDropdownItemsAsKeyValuePairList,
  //getWaterMeterAddressDataDropdown,
  getAddressPropertyFloorDropdownItemsAsKeyValuePairList,
  getAddressPropertyApartmentDropdownItemsAsKeyValuePairList,
  //getAddressDropdownsLogic
  updateWaterMeterAddress,
} from "@/api/water_meters";

import { getAuditorsAsKeyValuePairList } from "@/api/gis_audit";

import { ISODateString, formatDateTime, roundNumber } from "@/utils";
import config from "@/config";

import moment from "moment";

import {
  getWaterMeterTypesAsKeyValuePairList,
  getManufacturersAsKeyValuePairList,
  getWaterMeterOwnershipTypesAsKeyValuePairList,
} from "@/api/gis_nomenclatures";

import { getCarnetsAsKeyValuePairList } from "@/api/gis_nomenclatures";
import { dashboardRoutes } from "@/router";

const filtersModel = Object.freeze({
  waterMeterFabricNo: null,
  geoRegion: null,
  lastDateOfRepairFrom: null,
  lastDateOfRepairТо: null,
  lastDateOfCheckFrom: null,
  lastDateOfCheckТо: null,
  checkValueFrom: null,
  checkValueTo: null,
  nextDateOfCheckFrom: null,
  nextDateOfCheckТо: null,
  //closed: null,
  //closedDateFrom: null,
  //closedDateТо: null,
  customersTotalFrom: null,
  customersTotalТо: null,
  apartamentsTotalFrom: null,
  apartamentsTotalTo: null,
  createdOnFrom: null,
  createdOnТо: null,
  modifiedOnFrom: null,
  modifiedOnТо: null,
  waterMeterTypeCode: null,
  manufacturerCode: null,
  clientNumber: null,
  clientName: null,
  clientEGN: null,
  clientTypeId: null,
  district: null,
  municipality: null,
  townGraoCode: null,
  street: null,
  block: null,
  entrance: null,
  floor: null,
  apartment: null,
  zipCode: null,
  addressCreatedOnFrom: null,
  addressCreatedOnТо: null,
  addressModifiedOnFrom: null,
  addressModifiedOnТо: null,
  addressCarnetNo: null,
  addressCarnetPage: null,
  addressGeoRegion: null,
});

export default {
  name: "waterMeterPreview",
  components: {},
  data() {
    return {
      properties: {
        waterMeterTypes: [],
        manufacturers: [],
        clientTypes: [],
        districts: [],
        municipalities: [],
        townGraoCodes: [],
        streets: [],
      },
      addressChangeButton: false,
      backupAddress: null,
      addressDropdowns: {
        description: { disabled: false, items: [] },
        streetName: { disabled: false, items: [] },
        //caarnetPage: { disabled: false, items: [] },
        //longLat: { disabled: false, items: [] },
        block: { disabled: false, items: [] },
        entrance: { disabled: false, items: [] },
        floor: { disabled: false, items: [] },
        apartment: { disabled: false, items: [] },
      },

      dialog: false,

      auditTableProps: {
        headers: [
          {
            text: "№",
            value: "id",
            valueType: "text",
            width: "60px",
            fixed: true,
          },
          {
            text: "Дата",
            value: "date",
            valueType: "date",
            width: "80px",
            fixed: true,
          },
          {
            text: "Показание",
            value: "waterMeterValue",
            valueType: "waterMeterValue",
            width: "120px",
            fixed: true,
            align: "end",
          },
          { text: "Инкасатор", value: "auditorName", valueType: "text" },
        ],

        auditData: [],

        filters: {},
        hasPaging: false,

        noms: {
          statuses: [],
          priorities: [],
          users: [],
        },

        pagination: {
          itemsPerPage: 10,
          page: 1,
          perPageOptions: [5, 10, 25, 50, 100],
          total: 0,
        },

        props: {
          id: {
            type: String,
            default: "",
          },
          printAll: false,
          color: "primary",
        },

        sortColumn: [],
        sortDescending: [],

        lastAuditDate: null,
        lastAuditValue: null,
        auditorId: null,
      },

      marginTopAuditPanel: 0,

      filters: Object.assign({}, filtersModel),

      data: [],

      waterMeterData: {
        id: null,
        addressId: null,
        apartamentsTotal: null,
        auditor: {}, //null,
        checkValue: null,
        clientId: null,
        customersTotal: null,
        latitude: null,
        lastDateOfCheck: null,
        lastDateOfRepair: null,
        longitude: null,
        manufacturerId: null,
        nextDateOfCheck: null,
        waterMeterFabricNo: null,
        waterMeterTypeId: null,
        waterMeterOwnershipTypeId: null,
        mainWaterMeter: {},
      },

      addressData: {
        id: null,
        waterMeterId: null,
        districtName: null,
        municipalityName: null,
        fullAddress: null,
        townGraoCode: null,
        townName: null,
        townGraoCodeTownName: null,
        streetGraoCode: null,
        streetName: null,
        streetGraoCodeStreetName: null,
        streetNumber: null,
        addressDescription: null,
        block: null,
        entrance: null,
        floor: null,
        apartment: null,
        blockEntranceFloorApartament: null,
        zipCode: null,
        carnet: null,
        page: null,
        carnetAndPage: null,
        createdOn: null,
        modifiedOn: null,
        createdOnModifiedOn: null,
        latitude: null,
        longitude: null,
        latitudeLongitude: null,
      },

      clientData: {
        clientId: null,
        clientNumber: null,
        egn: null,
        name: null,
        clientType: null,
        clientTypeName: null,
      },

      waterMeterInitData: {}, //we will detect updateable properties by both waterMeterData, waterMeterInitData

      nomenclatures: {
        waterMeterTypes: [],
        manufacturers: [],
        auditors: [],
        carnets: [],
        waterMeterOwnershipTypes: [],
      },

      menuInstalledDateTime: false,
      menuCalibrationDateTime: false,
      menuNextCalibrationDateTime: false,

      waterMeterId: null,
      show: true,
    };
  },

  mounted() {
    this.show = false;
    this.waterMeterId = this.$route.params.id;
    this.getWaterMeterlById(this.waterMeterId);
    //this.getWaterMeterAddressDataDropdown(this.addressData);
  },

  watch: {
    $route(to) {
      // react to route changes...
      const this_path = to.path.includes('/water_meters/preview');
      if (this_path) {
        this.getWaterMeterlById(this.waterMeterId)
      }
    }
  },

  computed: {
    customSortColumn: {
      get: function () {
        return this.auditTableProps.sortColumn;
      },
      set: function (value) {
        this.auditTableProps.sortColumn = value;
        this.$emit("update:sortBy", value);
        this.$forceUpdate();
      },
    },

    customSortDesc: {
      get: function () {
        return this.auditTableProps.sortDesc;
      },
      set: function (value) {
        this.auditTableProps.sortDescending = value;
        this.$emit("update:sortDesc", value);
        this.$forceUpdate();
      },
    },
  },

  created() {
    this.getWaterMeterTypesList();
    this.getManufacturersList();
    this.getAuditorsList();
    this.getCarnetList();
    this.getWaterMeterOwnershipTypeList();
  },

  methods: {
    formatDateTime: formatDateTime,
    ISODateString: ISODateString,

    getCarnetList() {
      getCarnetsAsKeyValuePairList().then((data) => {
        this.nomenclatures.carnets = data;
      });
    },

    getWaterMeterTypesList() {
      getWaterMeterTypesAsKeyValuePairList().then((data) => {
        //will remove 0 index (this is "AllItems")
        data.splice(0, 1);

        this.nomenclatures.waterMeterTypes = data;
      });
    },

    getManufacturersList() {
      getManufacturersAsKeyValuePairList().then((data) => {
        //will remove 0 index (this is "AllItems")
        data.splice(0, 1);

        this.nomenclatures.manufacturers = data;
      });
    },

    getAuditorsList() {
      getAuditorsAsKeyValuePairList().then((data) => {
        this.nomenclatures.auditors = data;
      });
    },

    getWaterMeterOwnershipTypeList() {
      getWaterMeterOwnershipTypesAsKeyValuePairList().then((data) => {
        //will remove 0 index (this is "AllItems")
        data.splice(0, 1);

        this.nomenclatures.waterMeterOwnershipTypes = data;
      });
    },

    getWaterMeterlById(waterMeterId) {
      getWaterMeterlById(waterMeterId).then((data) => {
        this.waterMeterData = data;

        if (this.waterMeterData.lastDateOfRepair != null)
          this.waterMeterData.lastDateOfRepair = moment(
            this.waterMeterData.lastDateOfRepair
          ).format("YYYY-MM-DD");

        if (this.waterMeterData.lastDateOfCheck != null)
          this.waterMeterData.lastDateOfCheck = moment(
            this.waterMeterData.lastDateOfCheck
          ).format("YYYY-MM-DD");

        if (this.waterMeterData.installationDate != null)
          this.waterMeterData.installationDate = moment(
            this.waterMeterData.installationDate
          ).format("YYYY-MM-DD");

        if (this.waterMeterData.calibrationDate != null)
          this.waterMeterData.calibrationDate = moment(
            this.waterMeterData.calibrationDate
          ).format("YYYY-MM-DD");

        if (this.waterMeterData.nextCalibrationDate != null)
          this.waterMeterData.nextCalibrationDate = moment(
            this.waterMeterData.nextCalibrationDate
          ).format("YYYY-MM-DD");

        if (this.waterMeterData.nextDateOfCheck != null)
          this.waterMeterData.nextDateOfCheck = moment(
            this.waterMeterData.nextDateOfCheck
          ).format("YYYY-MM-DD");

        this.waterMeterData.createdOnModifiedOn =
          moment(data.createOn).format("YYYY-MM-DD HH:mm:ss") +
          " / " +
          moment(data.modifiedOn).format("YYYY-MM-DD HH:mm:ss");

        this.getAddressById(this.waterMeterData);
        this.getClientById(this.waterMeterData);
        this.GetAuditDataListByWaterMeterId(this.waterMeterData);

        this.waterMeterInitData = JSON.parse(
          JSON.stringify(this.waterMeterData)
        ); //deep copy
      });
    },

    getAddressPropertyDescriptionDropdownItemsAsKeyValuePairList(query) {
      getAddressPropertyDescriptionDropdownItemsAsKeyValuePairList(query).then(
        (data) => {
          this.addressDropdowns.description.items = data;
        }
      );
    },

    getAddressPropertyStreetNameDropdownItemsAsKeyValuePairList(query) {
      getAddressPropertyStreetNameDropdownItemsAsKeyValuePairList(query).then(
        (data) => {
          this.addressDropdowns.streetName.items = data;
        }
      );
    },

    getAddressPropertyBlockDropdownItemsAsKeyValuePairList(query) {
      getAddressPropertyBlockDropdownItemsAsKeyValuePairList(query).then(
        (data) => {
          this.addressDropdowns.block.items = data;
        }
      );
    },

    getAddressPropertyEntranceDropdownItemsAsKeyValuePairList(query) {
      getAddressPropertyEntranceDropdownItemsAsKeyValuePairList(query).then(
        (data) => {
          this.addressDropdowns.entrance.items = data;
        }
      );
    },

    //getWaterMeterAddressDataDropdown(query) {
    //  getWaterMeterAddressDataDropdown(query).then((data) => {
    //
    //  });
    //},

    getAddressPropertyFloorDropdownItemsAsKeyValuePairList(query) {
      getAddressPropertyFloorDropdownItemsAsKeyValuePairList(query).then(
        (data) => {
          this.addressDropdowns.floor.items = data;
        }
      );
    },

    getAddressPropertyApartmentDropdownItemsAsKeyValuePairList(query) {
      getAddressPropertyApartmentDropdownItemsAsKeyValuePairList(query).then(
        (data) => {
          this.addressDropdowns.apartment.items = data;
        }
      );
    },

    onAddressDropdownChanges(source) {
      switch (source) {
        case "description":
          this.getAddressPropertyStreetNameDropdownItemsAsKeyValuePairList(
            this.addressData
          );

          this.addressDropdowns.streetName.disabled = this.addressData
            .addressDescription
            ? false
            : true;
          this.addressDropdowns.block.disabled = true;
          this.addressDropdowns.entrance.disabled = true;
          this.addressDropdowns.floor.disabled = true;
          this.addressDropdowns.apartment.disabled = true;

          this.addressDropdowns.block.items = [];
          this.addressDropdowns.entrance.items = [];
          this.addressDropdowns.floor.items = [];
          this.addressDropdowns.apartment.items = [];

          this.addressData.block = null;
          this.addressData.entrance = null;
          this.addressData.floor = null;
          this.addressData.apartment = null;
          break;
        case "streetName":
          this.getAddressPropertyBlockDropdownItemsAsKeyValuePairList(
            this.addressData
          );

          this.addressDropdowns.block.disabled = this.addressData.streetName
            ? false
            : true;
          this.addressDropdowns.entrance.disabled = true;
          this.addressDropdowns.floor.disabled = true;
          this.addressDropdowns.apartment.disabled = true;

          this.addressDropdowns.entrance.items = [];
          this.addressDropdowns.floor.items = [];
          this.addressDropdowns.apartment.items = [];

          this.addressData.entrance = null;
          this.addressData.floor = null;
          this.addressData.apartment = null;
          break;
        case "block":
          this.getAddressPropertyEntranceDropdownItemsAsKeyValuePairList(
            this.addressData
          );

          this.addressDropdowns.entrance.disabled = this.addressData.block
            ? false
            : true;
          this.addressDropdowns.floor.disabled = true;
          this.addressDropdowns.apartment.disabled = true;

          this.addressDropdowns.floor.items = [];
          this.addressDropdowns.apartment.items = [];

          this.addressData.floor = null;
          this.addressData.apartment = null;
          break;
        case "entrance":
          this.getAddressPropertyFloorDropdownItemsAsKeyValuePairList(
            this.addressData
          );

          this.addressDropdowns.floor.disabled = this.addressData.entrance
            ? false
            : true;
          this.addressDropdowns.apartment.disabled = true;

          this.addressDropdowns.apartment.items = [];

          this.addressData.apartment = null;
          break;
        case "floor":
          this.getAddressPropertyApartmentDropdownItemsAsKeyValuePairList(
            this.addressData
          );

          this.addressDropdowns.apartment.disabled = this.addressData.floor
            ? false
            : true;
          break;
        case "apartment":
          break;
        default:
      }

      this.$forceUpdate();
    },

    onAddressDropdownClear(source) {
      return source;
    },

    getAddressById(data) {
      this.addressData = data.address;
      this.backupAddress = data.address;
    },

    saveAddressData(addressData) {
      var changeWaterMeterAddress = {};
      changeWaterMeterAddress.addressDescription =
        addressData.addressDescription == undefined
          ? null
          : addressData.addressDescription;
      changeWaterMeterAddress.streetName =
        addressData.streetName == undefined ? null : addressData.streetName;
      changeWaterMeterAddress.block =
        addressData.block == undefined ? null : addressData.block;
      changeWaterMeterAddress.entrance =
        addressData.entrance == undefined ? null : addressData.entrance;
      changeWaterMeterAddress.floor =
        addressData.floor == undefined ? null : addressData.floor;
      changeWaterMeterAddress.apartment =
        addressData.apartment == undefined ? null : addressData.apartment;
      changeWaterMeterAddress.waterMeterId = this.waterMeterData.id;

      updateWaterMeterAddress(changeWaterMeterAddress).then((data) => {
        if (data == undefined || data == null) {
          this.$snotify.error("Грешка");
        } else {
          //success
          this.$snotify.success(
            "Адресът е сменен на водомер: " + data.waterMeterFabricNo + "."
          );
          this.$forceUpdate();
        }
      });
    },
    getAllDropdowns(address) {
      this.getAddressPropertyDescriptionDropdownItemsAsKeyValuePairList(
        address
      );
      this.getAddressPropertyStreetNameDropdownItemsAsKeyValuePairList(address);
      this.getAddressPropertyBlockDropdownItemsAsKeyValuePairList(address);
      this.getAddressPropertyEntranceDropdownItemsAsKeyValuePairList(address);
      //this.getWaterMeterAddressDataDropdown(this.addressData);
      this.getAddressPropertyFloorDropdownItemsAsKeyValuePairList(address);
      this.getAddressPropertyApartmentDropdownItemsAsKeyValuePairList(address);
    },
    restoreAddress() {
      this.addressData = this.backupAddress;
      this.getAllDropdowns(this.addressData);
      this.$forceUpdate();
    },

    getClientById(data) {
      this.clientData = data.client;

      this.clientData.clientTypeName =
        this.clientData.clientType.code +
        " - " +
        this.clientData.clientType.nameBg;
    },

    GetAuditDataListByWaterMeterId(data) {
      if (data != null) {
        for (var i = 0; i < data.audits.length; i++) {
          //a small step against the giants
          var currentDate = data.audits[i].date;
          if (currentDate) {
            // eslint-disable-next-line no-case-declarations
            let d = moment();

            if (
              (typeof currentDate === "number" &&
                currentDate.toString().length === 8) ||
              (typeof currentDate === "string" && currentDate.length === 8)
            ) {
              d = moment(currentDate, "DDMMYYYY");
              if (!d.isValid()) d = moment(currentDate, "YYYYMMDD");
            } else if (
              (currentDate === "number" &&
                currentDate.toString().length === 14) ||
              (typeof currentDate === "string" && currentDate.length === 14)
            ) {
              d = moment(currentDate, "YYYYMMDDHHmmss");
            } else {
              d = moment(currentDate);
            }

            if (d.isValid()) {
              data.audits[i].date = ISODateString(d.toISOString());
            } else {
              data.audits[i].date = "—";
            }
          } else data.audits[i].date = "—";

          data.audits[i].waterMeterValue = roundNumber(
            data.audits[i].waterMeterValue,
            16,
            16,
            3
          );

          data.audits[i].auditorName = data.audits[i].auditor.auditorNumber
            ? data.audits[i].auditor.auditorNumber +
            "-" +
            data.audits[i].auditor.name //not good, but works
            : data.audits[i].auditor.name; //not good, but works
        }

        this.auditTableProps.auditData = data.audits;

        this.auditTableProps.lastAuditDate =
          data.audits.length == 0 ? null : data.audits[0].date;
        this.auditTableProps.lastAuditValue =
          data.audits.length == 0 ? null : data.audits[0].waterMeterValue;

        this.marginTopAuditPanel =
          Math.sign(this.auditTableProps.auditData.length) * 50;
      }
    },

    clientPreview(clientId) {
      if (clientId > 0) {
        this.$router.push({ path: `/gis_clients/clientPreview/${clientId}` });
        this.$forceUpdate();
      }
    },

    addressPreview(addressId) {
      if (addressId > 0) {
        this.$router.push({
          path: `/gis_addresses/addressPreviewOnly/${addressId}`,
        });
        this.$forceUpdate();
      }
    },
    goMapLocation() {
      
      let urlStr=""
      if(this.waterMeterData.address.client.clientTypeId == 1){
      urlStr = "&watermeters_residential=on"
      }else{ urlStr = "&watermeters_public=on"}
     
      const generateUrl = `${process.env.VUE_APP_THEMATIC_MAPS}?zoom=18&lat=${this.waterMeterData.address.latitude}&long=${this.waterMeterData.address.longitude}${urlStr}&watermeter_id=${this.waterMeterData.id}`;

      window.open(generateUrl, "_blank");
    },
    headingText() {
      return `Водомер № ${this.waterMeterData.waterMeterFabricNo}`;
    },

    updateWaterMeter(waterMeterData) {
      //this is a waterMeterData updatable fields white list (id, installationDate, calibrationDate, nextCalibrationDate, longitude, latitude)
      var waterMeterUpdateModel = JSON.parse(JSON.stringify(waterMeterData)); //deep copy

      Object.keys(waterMeterUpdateModel).forEach((item) => {
        if (waterMeterUpdateModel[item] != null) {
          if (
            item != "id" &&
            item != "installationDate" &&
            item != "calibrationDate" &&
            item != "nextCalibrationDate" &&
            item != "longitude" &&
            item != "latitude"
          ) {
            if (waterMeterUpdateModel[item].isArray) {
              waterMeterUpdateModel[item] = [];
            } else {
              waterMeterUpdateModel[item] = null;
            }
          }
        }
      });

      updateWaterMeter(waterMeterUpdateModel).then((data) => {
        if (data.id == undefined) {
          var index1 = data.indexOf("(");
          var index2 = data.indexOf(")");
          if (index1 > -1 && index1 < index2) {
            this.$snotify.error("Грешка " + data.substring(index2 + 1) + ".");
          }
        } else if (data.id > 0) {
          //success
          this.$snotify.success("Успешно запазване.");
          this.$forceUpdate();
        }
      });
    },

    redirectToClientWatermeter(watermeterID) {
      dashboardRoutes;
      const link = document.createElement("a");

      link.href = `${config.valvesBaseURL}?watermeterID=${watermeterID}`;

      link.target = "_blank";
      link.click();
    },
  },
};
</script>

<style>
#virtual-scroll-table {
  max-height: 400px;
  overflow: auto;
}
</style>
