<template>
  <base-material-filter
    title="Търсене на грешки"
    :hasFilters="hasActiveFilters"
    v-on:do-filter="doFilter"
    v-on:remove-filter="clearFilters" 
  >
    <div @keypress.enter="doFilter" style="width:100%" class="px-5">
      <v-row v-if="false">
        <v-col cols="12" lg="2" offset-lg="5" xl="2" offset-xl="5">
          <v-text-field 
            label="Име на потребител"
            autocomplete="off"
            v-model="filters.username"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" xl="12">
          <v-row>
            <v-col cols="12">
              <h4>За период от</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <base-select
                :items="properties.days"
                item-text="name"
                item-value="value"
                v-model="filters.fromDay"
                label="Ден"
              >
              </base-select>
            </v-col>
            <v-col cols="12" md="3">
              <base-select
                :items="properties.months"
                item-text="name"
                item-value="value"
                v-model="filters.fromMonth"
                label="Месец"
              >
              </base-select>
            </v-col>
            <v-col cols="12" md="3">
              <base-select
                :items="properties.years"
                item-text="name"
                item-value="value"
                v-model="filters.fromYear"
                label="Година"
              >
              </base-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="menuFrom"
                v-model="fromTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="filters.fromTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.fromTime"
                    label="От час"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="fromTimeMenu"
                  v-model="filters.fromTime"
                  format="24hr"
                  full-width
                  @click:minute="$refs.menuFrom.save(filters.fromTime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h4>до</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <base-select
                :items="properties.days"
                item-text="name"
                item-value="value"
                v-model="filters.toDay"
                label="Ден"
              >
              </base-select>
            </v-col>
            <v-col cols="12" md="3">
              <base-select
                :items="properties.months"
                item-text="name"
                item-value="value"
                v-model="filters.toMonth"
                label="Месец"
              >
              </base-select>
            </v-col>
            <v-col cols="12" md="3">
              <base-select
                :items="properties.years"
                item-text="name"
                item-value="value"
                v-model="filters.toYear"
                label="Година"
              >
              </base-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="menuTo"
                v-model="toTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="filters.toTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.toTime"
                    label="До час"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="toTimeMenu"
                  v-model="filters.toTime"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menuTo.save(filters.toTime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </base-material-filter>
</template>

<script>

import { generateYears, isEmptyObject } from "@/utils";

const filtersModel = Object.freeze({
  username: '',
  fromTime: '00:00',
  fromDay: '',
  fromMonth: '',
  fromYear: '',
  toTime: '23:59',
  toDay: '',
  toMonth: '',
  toYear: ''
})

export default {
  name: "exceptionLogsFilter",
  data(){
    return {
      properties: {
        days: [],
        months: [],
        years: [],
        activityTypes: []
      },
      toTimeMenu: false,
      fromTimeMenu: false,
      filters: Object.assign({}, filtersModel),
      hasActiveFilters: false
    }
  },
  mounted(){
    this.generateYears(2021);
    this.generateMonths();
    this.generateDays();
    this.generateFromToFilter();
  },
  methods: {
    generateFromToFilter(){
      let date = new Date();

      this.filters.fromDay = date.getDate();
      this.filters.fromMonth = date.getMonth()+1;
      this.filters.fromYear = date.getFullYear();

      this.filters.toDay = date.getDate();
      this.filters.toMonth = date.getMonth()+1;
      this.filters.toYear = date.getFullYear();

      this.$forceUpdate();
    },
    generateYears(year){
      this.properties.years = generateYears(year);
    },
    generateMonths(){
      let arr = [];
      let monthNames = [ "Януари", "Февруари", "Март", "Април", "Май", "Юни", "Юли", "Август", "Септември", "Октомври", "Ноември", "Декември" ];

      for(let i=0;i<monthNames.length;i++){
        arr.push({name: monthNames[i], value: i+1})
      }

      this.properties.months = arr;
    },
    generateDays(){
      for(let i=0;i<31;i++){
        this.properties.days.push({name: i+1, value: i+1})
      }
    },
    doFilter(){
      if(!isEmptyObject(this.filters)){
        this.filters.page = 1;
        this.hasActiveFilters = true;
        this.$emit("doFilter");
        delete this.filters.page;
      } else {
        if(this.hasActiveFilters === true)
          this.$emit("doFilter");
          
        this.hasActiveFilters = false;
      }
    },
    clearFilters(){
      this.filters = Object.assign({}, filtersModel);

      this.hasActiveFilters = false;

      this.$emit("doFilter", true);
    },
  }
}
</script>