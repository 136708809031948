<template>
  <base-material-filter title="Търсене на аварии"
                        style="border-top-right-radius: 30px;"
                        :hasFilters="hasActiveFilters"
                        v-on:do-filter="doFilter()"
                        v-on:remove-filter="clearFilters">
    <v-row @keypress.enter="doFilter" class="mx-1">
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Пореден номер"
                      :loading="doFilterIsActivated && ((itemSelectedMarker & 1) == 1)"
                      height="30"
                      autocomplete="off"
                      v-model="filters.workCardNo"
                      clearable
                      @click:clear="clearIt(1, 'workCardNo')"
                      @click="clickFilterItems(1)">
        </v-text-field>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-select label="КЕВР категории"
                  :items="properties.kevrCategories"
                  :loading="doFilterIsActivated && ((itemSelectedMarker & 2) == 2)"
                  item-value="key"
                  item-text="value"
                  v-model="filters.kevrCategory1"
                  clearable
                  @click:clear="clearIt(2, 'kevrCategory1')"
                  @click="clickFilterItems(2)">
        </v-select>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-select label="КЕВР под-категории"
                  :items="properties.kevrSecondaryCategories"
                  :loading="doFilterIsActivated && ((itemSelectedMarker & 4) == 4)"
                  item-value="key"
                  item-text="value"
                  v-model="filters.kevrCategory2"
                  clearable
                  @click:clear="clearIt(4, 'kevrCategory3')"
                  @click="clickFilterItems(4)">
        </v-select>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Град"
                      :loading="doFilterIsActivated && ((itemSelectedMarker & 8) == 8)"
                      height="30"
                      autocomplete="off"
                      v-model="filters.townName"
                      clearable
                      @click:clear="clearIt(8, 'townName')"
                      @click="clickFilterItems(8)">
        </v-text-field>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Улица"
                      :loading="doFilterIsActivated && ((itemSelectedMarker & 16) == 16)"
                      height="30"
                      autocomplete="off"
                      v-model="filters.streetName"
                      clearable
                      @click:clear="clearIt(16, filters.streetName)"
                      @click="clickFilterItems(16)">
        </v-text-field>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-select label="Приоритет"
                  :items="properties.priorities"
                  :loading="doFilterIsActivated && ((itemSelectedMarker & 32) == 32)"
                  item-value="key"
                  item-text="value"
                  v-model="filters.priorityId"
                  clearable
                  @click:clear="clearIt(32, 'priorityId')"
                  @click="clickFilterItems(32)">
        </v-select>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu v-model="menuBreakdownDateTimeFrom"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Аварии от дата"
                          :loading="doFilterIsActivated && ((itemSelectedMarker & 64) == 64)"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.brekadownDateTimeFrom"
                          clearable
                          @click:clear="clearIt(64, 'brekadownDateTimeFrom')"
                          @click="clickFilterItems(64)">
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.brekadownDateTimeFrom"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu v-model="menuBreakdownDateTimeTo"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Аварии до дата"
                          :loading="doFilterIsActivated && ((itemSelectedMarker & 128) == 128)"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.brekadownDateTimeTo"
                          clearable
                          @click:clear="clearIt(128, 'brekadownDateTimeTo')"
                          @click="clickFilterItems(128)">
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.brekadownDateTimeTo"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu v-model="menuRepairDateTimeFrom"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Ремонти от дата"
                          :loading="doFilterIsActivated && ((itemSelectedMarker & 256) == 256)"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.repairDateTimeFrom"
                          clearable
                          @click:clear="clearIt(256, 'repairDateTimeFrom')"
                          @click="clickFilterItems(256)">
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.repairDateTimeFrom"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu v-model="menuRepairDateTimeTo"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Ремонти до дата"
                          :loading="doFilterIsActivated && ((itemSelectedMarker & 512) == 512)"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.repairDateTimeTo"
                          clearable
                          @click:clear="clearIt(512, 'repairDateTimeTo')"
                          @click="clickFilterItems(512)">
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.repairDateTimeTo"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu v-model="menuNoWaterDateTimeFrom"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Без вода от дата"
                          :loading="doFilterIsActivated && ((itemSelectedMarker & 1024) == 1024)"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.waterStopStartDateTimeFrom"
                          clearable
                          @click:clear="clearIt(1024, 'waterStopStartDateTimeFrom')"
                          @click="clickFilterItems(1024)">
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.waterStopStartDateTimeFrom"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu v-model="menuNoWaterDateTimeTo"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Без вода до дата"
                          :loading="doFilterIsActivated && ((itemSelectedMarker & 2048) == 2048)"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.waterStopEndDateTimeTo"
                          clearable
                          @click:clear="clearIt(2048, 'waterStopEndDateTimeTo')"
                          @click="clickFilterItems(2048)">
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.waterStopEndDateTimeTo"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-select label="Аварии с геолокация"
                  :items="properties.answerYesNo"
                  :loading="doFilterIsActivated && ((itemSelectedMarker & 4096) == 4096)"
                  item-value="key"
                  item-text="value"
                  v-model="filters.geolocationId"
                  clearable
                  @click:clear="clearIt(4096, 'priorityId')"
                  @click="clickFilterItems(4096)">
        </v-select>
      </v-col>
    </v-row>
  </base-material-filter>
</template>

<script>

  import { isEmptyObject } from "@/utils";
  import { getSignalPriorityAsKeyValuePairList } from '@/api/priorities';
  import { getKEVRCategoriesAsKeyValuePairList } from '@/api/kevr_category';
  import { getKEVRSecondaryCategoriesAsKeyValuePairList } from '@/api/kevr_secondary_category';
                                                              
  const filtersModel = Object.freeze({
    workCardNo: null,
    kevrCategory1: null,
    kevrCategory2: null,
    townName: null,
    streetName: null,
    priorityId: null,
    brekadownDateTimeFrom: null,
    brekadownDateTimeTo: null,
    repairDateTimeFrom: null,
    repairDateTimeTo: null,
    waterStopStartDateTimeFrom: null,
    waterStopEndDateTimeTo: null,
    geolocationId: null
  });

  export default {
    name: "breakdownsFilter",
    data() {
      return {
        properties: {
          kevrCategories: [],
          kevrSecondaryCategories: [],
          priorities: [],
          answerYesNo: [
            { key: null, value: "с/без геолокация" },
            { key: true, value: "с геолокация" },
            { key: false, value: "без геолокация" },
          ],
        },

        filters: Object.assign({}, filtersModel),
        hasActiveFilters: false,
        menuBreakdownDateTimeFrom: false,
        menuBreakdownDateTimeTo: false,
        menuRepairDateTimeFrom: false,
        menuRepairDateTimeTo: false,
        menuNoWaterDateTimeFrom: false,
        menuNoWaterDateTimeTo: false,

        itemSelectedMarker: 0,

        doFilterIsActivated: false
      }
    },
    created() {
      this.getPriorityList();
      this.getKEVRCategoriesList();
      this.getKEVRSecondaryCategoriesList();
    },

    methods: {
      getPriorityList() {
        getSignalPriorityAsKeyValuePairList().then(data => {
          this.properties.priorities = data;
        })
      },

      getKEVRCategoriesList() {
        getKEVRCategoriesAsKeyValuePairList().then(data => {

          this.properties.kevrCategories = data;
        })
      },

      getKEVRSecondaryCategoriesList() {
        getKEVRSecondaryCategoriesAsKeyValuePairList().then(data => {

          this.properties.kevrSecondaryCategories = data;
        })
      },

      doFilter() {
        if (!isEmptyObject(this.filters) ) {
          this.filters.page = 1;
          this.hasActiveFilters = true;
          this.$emit("doFilter");
          delete this.filters.page;
        } else {
          if (this.hasActiveFilters === true)
            this.$emit("doFilter");

          this.hasActiveFilters = false;
        }
      },

      clearFilters() {
        this.filters = Object.assign({}, filtersModel);

        this.itemSelectedMarker = 0;
        this.hasActiveFilters = false;
        this.$emit("doFilter", true);
      },

      getFilters() {
        this.filters;
      },

      clickFilterItems(item) {
        this.itemSelectedMarker = this.itemSelectedMarker | item;
      },

      clearIt(item, propName) {
        this.itemSelectedMarker = this.itemSelectedMarker - item;
        this.filters[propName] = null;
      }
    }
  }
</script>
