<template>
  <v-container id="addUser"
               fluid
               tag="section"
               class="full-height">

    <base-v-component goBackText="Обратно към потребители"
                      goBackTo="/administration/listUsers" />

    <v-form v-model="isFormValid">
      <v-row class="justify-center">
        <v-col cols="8">
          <v-card style="border-top-right-radius: 30px;
                       border-bottom-right-radius: 30px;
                       border-bottom-left-radius: 30px;"
                  class="v-card-profile"
                  color="white">

            <v-avatar style="border-top-right-radius: 20%;
                           border-bottom-left-radius: 20%;
                           border-bottom-right-radius: 20%;
                           margin-left: 20px;
                           margin-top: -40px;"
                      left
                      color="#263238"
                      size="100">
              <v-icon style="font-size: 400%; color: #ffffff;">mdi-account-plus-outline</v-icon>
            </v-avatar>
            <v-card-text>
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-text-field v-model="model.username"
                                label="Потребителско име"
                                :rules="[rules.empty]"
                                clearable autocomplete="off">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-text-field v-model="model.email"
                                label="Е-поща"
                                :rules="[rules.empty, rules.isValidMail]"
                                clearable autocomplete="off">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-text-field v-model="model.firstName"
                                label="Име"
                                :rules="[rules.empty]"
                                clearable autocomplete="off">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-text-field v-model="model.lastName"
                                label="Фамилия"
                                :rules="[rules.empty]"
                                clearable autocomplete="off">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-text-field v-model="model.userPassword.value"
                                :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPass1 ? 'text' : 'password'"
                                :rules="[rules.empty, rules.isValidPassword]"
                                label="Парола"
                                @click:append="showPass1 = !showPass1"
                                counter clearable autocomplete="off">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-text-field v-model="model.confirmPassword"
                                :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPass2 ? 'text' : 'password'"
                                :rules="[rules.empty, rules.isConfirmed]"
                                label="Потвърди парола"
                                @click:append="showPass2 = !showPass2"
                                counter clearable autocomplete="off">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-switch v-model="model.enabled"
                            color="success"
                            :label="model.enabled ? 'Активен' : 'Неактивен'"
                            hide-details>
                  </v-switch>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row class="justify-center">
              <v-col cols="4">
                <v-btn block
                       class="ma-2 success"
                       @click="onSubmit"
                       :disabled="!isFormValid">
                  Запази
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>

  import { validateEmail, validatePassword } from '@/utils';
  import { addUserKeycloak } from "@/api/users";

  export default {
    name: "addUser",
    components: {
    },
    data() {
      return {
        model: {
          username: '',
          email: '',
          firstName: '',
          lastName: '',
          enabled: true,
          userPassword: {
            type: 'password',
            value: null,
            temporary: false
          },
          confirmPassword: '',
          groups: [],
          attributes: {}
        },
        rules: {
          empty: val => (val ? true : false) || 'Задължително поле',
          isValidMail: val => (val ? validateEmail(val) : false) || 'Невалидна е-поща',
          isValidPassword: val => (val ? validatePassword(val) : false) || 'Паролата трябва да съдържа малки латински букви, цифри, специален символ и да е поне 6 символа',
          isConfirmed: val => (val ? val === this.model.userPassword.value : false) || 'Невалидно потвърждение на парола'
        },
        showPass1: false,
        showPass2: false,
        isFormValid: false
      }
    },
    watch: {},
    computed: {},
    mounted() {

    },
    created() { },
    methods: {
      onSubmit() {
        addUserKeycloak(this.model).then((data) => {
          if (data.includes('(0)')) {

             //Notification for failed operation
            this.$snotify.error(data.substring(4));

          } else if (data.includes('({0})')) {

            //Notification for successfull operation
            this.$snotify.success(data.substring(6));

            //After update clean all the fields from the model
            this.model = {
              username: '',
              email: '',
              firstName: '',
              lastName: '',
              enabled: true,
              userPassword: {
                type: 'password',
                value: null,
                temporary: false
              },
              confirmPassword: null,
              groups: [],
              attributes: {}
            };

            //Redirect to all users list
            this.$router.push({ path: `/administration/listUsers` });
          }
        });
      }
    }
  };
</script>
