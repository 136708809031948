<template>
  <div style="height: 100%; width: 100%;margin: 0;" class="dashboard">
    <div></div>
    <v-container  class="flex-wrap">
      <v-row align="start" justify="start">
        <template v-for="(item, i) in dashboardRoutes">
          <v-col class="col-3" :key="`link-${i}`">
            <template v-if="item.target == true">
              <a :href="getSpecialPaths(item)" target="_blank">
                <s-v-g-loader
                  :iconn="item.meta.icon"
                  v-if="item.meta.icon"
                ></s-v-g-loader>
                 <i
                  v-if="item.meta.icon_fa"
                  :class="item.meta.icon_fa + ' dashboardIcon'"
                ></i>
                {{ dashboardRoutes[i].name }}
             
              </a>
            </template>
            <template v-else>
              <router-link :to="item.path">
                <s-v-g-loader
                  :iconn="item.meta.icon"
                  v-if="item.meta.icon"
                ></s-v-g-loader>
                <i
                  v-if="item.meta.icon_fa"
                  :class="'fas fa-map-marked-alt'"
                ></i>
                {{ dashboardRoutes[i].name }}
              </router-link>
            </template>
            <template v-if="i % 2 == 0">
              <v-spacer></v-spacer>
            </template>
          </v-col>
        </template>
      </v-row>
      <v-row v-if="1 == 2">
        <v-col class="col-2">
          <a :href="idEditorURL" target="_blank">
            <s-v-g-loader icon="mapEditor"></s-v-g-loader>
            Редакция
          </a>
        </v-col>

        <v-col class="col-2">
          <router-link to="/users">
            <s-v-g-loader icon="users"></s-v-g-loader>
            Потребители
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "dashboard",
  components: {},
  computed: {
    ...mapGetters([
      "isLogged",
      "last_map_center",
      "last_map_zoom",
      "dashboardRoutes"
    ])
  },
  data() {
    return { };
  },
  methods: {
    getSpecialPaths(menu) {
      const urlKeys = [":zoom:", ":lat:", ":lng:"];
      const urlValues = [
        this.$store.getters.last_map_zoom,
        this.$store.getters.last_map_center.lat,
        this.$store.getters.last_map_center.lng
      ];
      return replaceSpecialKeysInURL(menu.path, urlKeys, urlValues);
    }
  }
};

export function replaceSpecialKeysInURL(url, keys, values) {
  if (keys.length >= values.length && keys.length > 0) {
    keys.forEach((key, index) => {
      if (url.indexOf(key) != -1) {
        url = url.replaceAll(key, values[index]);
      }
    });
  }

  return url;
}
</script>
