<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    flat
    height="75"
  >
    <v-btn
      class="mr-3 black--text hamburger-menu-btn"
      text
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : setDrawer(!drawer)"
    >
      <span class="logo-normal">
                <v-img
                  class="cnt-logo"
                  width="20"
                  position="center center"
                  :src="require(`@/assets/menu-burger.svg`)"
                ></v-img>
              </span>

    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3 theme--light" />

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
      class="theme--light"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2 white--text theme--light"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <span>
                <v-img
                  width="20"
                  position="center center"
                  :src="require(`@/assets/account-icon.svg`)"
                ></v-img>
              </span>
          <span class="account-name">{{ username }}</span>
        </v-btn>
      </template>

      <v-list  dense
      >
         <template v-for="(p, i) in profile">
          <v-divider
            v-if="p.divider"
            :key="`divider-${i}`"
            class="mb-2 mt-2"
          />

          <v-list-item
            v-else :key="`item-${i}`"
            @click="p.method"
            link
          >
            <v-list-item-title v-text="p.title" />
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapGetters } from 'vuex'
import Vue from "vue"


export default {
  name: "CoreAppBar",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data(){
      return {
        profile: []
      }
    },
 
  mounted(){
     this.profile  = [ { title: 'Изход', method: this.signOut }]
    if(!this.$vuetify.breakpoint.smAndDown){
      const vue = this;
      setTimeout(() => {
        vue.$store.state.app.drawer = true;
        vue.$forceUpdate();
      }, 0)
    } else {
      const vue = this;
      setTimeout(() => {
        vue.$store.state.app.drawer = false;
        vue.$forceUpdate();
      }, 0)
    }
  },
  computed: {
    ...mapGetters(['username', 'drawer'])
  },
  methods: {
    setDrawer(val){
      this.$store.commit('app/SET_DRAWER', val)
    },
    signOut(){
      this.$loading(true);
      Vue.prototype.$keycloak.logout();
    }
  }
}
</script>
