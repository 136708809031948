import { getAppEnvironment } from "@/api/account";

const state = {
  loading: true,
  drawer: false,
  production: false,
  last_map_center: {lat: 42.698334, lng: 23.319941},
  last_map_zoom: null,
}

const mutations = {
  TOGGLE_LOADING: state => {
    state.loading = !state.loading;
  },
  STOP_LOADING: state => {
    state.loading = false;
  },
  START_LOADING: state => {
    state.loading = true;
  },
  SET_DRAWER: (state, payload) => {
    state.drawer = payload;
  },
  SET_MAP_CENTER: (state, center) => {
    state.last_map_center = center;
  },
  SET_MAP_ZOOM: (state, zoom) => {
    state.last_map_zoom = zoom;
  },
  SET_PRODUCTION: (state, value) => {
    state.production = value;
  }
}

const actions = {
  toggleLoading({ commit }) {
    commit('TOGGLE_LOADING')
  },
  stopLoading({ commit }) {
    commit('STOP_LOADING');
  },
  startLoading({ commit }) {
    commit('START_LOADING');
  },
  // get app environment
  getAppEnvironment({ commit, state }) {
    return new Promise((resolve, reject) => {
      getAppEnvironment().then(data => {

        commit('SET_PRODUCTION', data);
        resolve(data);
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}