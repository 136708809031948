import Vue from 'vue'
import Keycloak from 'keycloak-js'
import Cookies from 'js-cookie';
import config from "@/config";
import { setToken } from '@/utils/auth';

const options = {
  url: config.keycloakURL,
  realm: 'master',
  clientId: 'demo-problem-reporting-frontend',
  onLoad: 'login-required',
  checkLoginIframe: false,
  responseMode: 'query',
  silentCheckSsoRedirectUri: window.location.origin
}

const _keycloak = Keycloak(options)

const Plugin = {
  install(Vue) {
    Vue.$keycloak = _keycloak
  }
}

Plugin.install = Vue => {
  Vue.$keycloak = _keycloak
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak
      }
    }
  })
}

Vue.use(Plugin)

Vue.$keycloak.init(options).then((auth) => {
  if (!auth) {
    window.location.reload();
    Vue.$log.info("Not Authenticated");
  } else {
    Vue.$log.info("Authenticated");
  }

  if (Vue.$keycloak.token && Vue.$keycloak.idToken && Vue.$keycloak.token != '' && Vue.$keycloak.idToken != '') {

    Cookies.set("Auth_Token", Vue.$keycloak.token);      //Token Refresh 
    setInterval(() => {
      Vue.$keycloak.updateToken(70).then((refreshed) => {
        if (refreshed) {
          Vue.$log.info('Token refreshed' + refreshed);
        } else {
          Vue.$log.warn('Token not refreshed, valid for '
            + Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
        }
      }).catch(() => {
        Vue.$log.error('Failed to refresh token');
      });
    }, 6000)

  }
}).catch(() => {
  Vue.$log.error("Authentication Failed");
});

export default Plugin;