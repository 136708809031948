<template>
  <div>
    <iframe
      :src="iframeSrc"
      frameBorder="0"
      width="100%"
      height="600px"
      title="Versions"
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: "VersionsIframe",

  computed: {
    iframeSrc() {
      const isDevelopment = process.env.NODE_ENV === "development";
      return isDevelopment
        ? "http://localhost:8080/versions.html"
        : process.env.VUE_APP_VALVES_URL + "/versions.html";
    },
  },
};
</script>
