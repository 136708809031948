import Layout from "@/layouts/Layout.vue";
import SignalsOperList from "@/views/signals_oper/list.vue";
import SignalsOperPreview from "@/views/signals_oper/preview.vue";

let signalsOperRoute = {
  path: "/signals_oper",
  component: Layout,
  name: "Преглед сигнали",
  redirect: "/signals_oper",
  hidden: true,
  meta: {
    actions: ["Оператор"],
    groups: ["TestMainGroup"],
  },
  children: [
    {
      path: "/signals_oper/list",
      name: "Преглед сигнали",
      component: SignalsOperList,
      meta: {
        title: "Преглед сигнали",
        actions: ["Оператор"],
        groups: ["TestMainGroup"],
        icon: "signals_preview.png",
        goBackTitle: "Обратно към преглед сигнали",
      },
      hidden: false
    },
    {
      path: "/signals_oper/preview/:id",
      name: "Преглед сигнали от оператор",
      component: SignalsOperPreview,
      meta: {
        title: "Сигнали оператор",
        actions: ["Оператор"],
        groups: ["TestMainGroup"],
        icon: "mdi-account-alert",
        goBackTitle: "Обратно към преглед сигнали",
      },
      hidden: true
    }
  ]
}

export default signalsOperRoute;
