<template>
  <v-main>
    <keep-alive :exclude="['UserRolesList', 'UserRolePreview']">
      <router-view :key="$route.fullPath" />
    </keep-alive>

    <core-app-footer />
  </v-main>
</template>

<script>
import CoreAppFooter from "./Footer";

export default {
  name: 'CoreAppView',

  components: {
    CoreAppFooter
  },
}
</script>
