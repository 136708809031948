<template>
  <base-material-filter style="border-top-right-radius: 30px;"
                        title="Търсене на логове"
                        :hasFilters="hasActiveFilters"
                        v-on:do-filter="doFilter()"
                        v-on:remove-filter="clearFilters">
    <v-row @keypress.enter="doFilter" class="mx-1">
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Пореден No."
                      :loading="doFilterIsActivated && ((itemSelectedMarker & 1) == 1)"
                      height="30"
                      autocomplete="off"
                      v-bind:value="filters.id"
                      clearable
                      @click:clear="clearIt(1, 'id')"
                      @click="clickFilterItems(1)">
        </v-text-field>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Грешка описание"
                      :loading="doFilterIsActivated && ((itemSelectedMarker & 2) == 2)"
                      height="30"
                      autocomplete="off"
                      v-bind:value="filters.errorMessage"
                      clearable
                      @click:clear="clearIt(2, 'errorMessage')"
                      @click="clickFilterItems(2)">
        </v-text-field>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Модул име"
                      :loading="doFilterIsActivated && ((itemSelectedMarker & 4) == 4)"
                      height="30"
                      autocomplete="off"
                      v-bind:value="filters.modulName"
                      clearable
                      @click:clear="clearIt(4, 'modulName')"
                      @click="clickFilterItems(4)">
        </v-text-field>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Процедура име"
                      :loading="doFilterIsActivated && ((itemSelectedMarker & 8) == 8)"
                      height="30"
                      autocomplete="off"
                      v-bind:value="filters.procedureName"
                      clearable
                      @click:clear="clearIt(8, 'procedureName')"
                      @click="clickFilterItems(8)">
        </v-text-field>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu v-model="menuCreatedFrom"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Дата на създаване от"
                          :loading="doFilterIsActivated && ((itemSelectedMarker & 16) == 16)"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.createdOnFrom"
                          clearable
                          @click:clear="clearIt(16, 'createdOnFrom')"
                          @click="clickFilterItems(16)">
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.createdOnFrom"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu v-model="menuCreatedTo"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Дата на създаване до"
                          :loading="doFilterIsActivated && ((itemSelectedMarker & 32) == 32)"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.createdOnTo"
                          clearable
                          @click:clear="clearIt(32, 'createdOn')"
                          @click="clickFilterItems(32)">
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.createdOnTo"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Импорт файл No."
                      :loading="doFilterIsActivated && ((itemSelectedMarker & 64) == 64)"
                      height="30"
                      autocomplete="off"
                      v-bind:value="filters.importFileId"
                      clearable
                      @click:clear="clearIt(64, 'importFileId')"
                      @click="clickFilterItems(64)">
        </v-text-field>
      </v-col>
    </v-row>
  </base-material-filter>
</template>

<script>

  import { isEmptyObject } from "@/utils";

  const filtersModel = Object.freeze({
    createdUTCFrom: null,
    createdUTCTo: null,
    closedUTCFrom: null,
    closedUTCTo: null,
    lastResponseUTCFrom: null,
    lastResponseUTCTo: null,
    statusId: null,
    priorityId: null,
    needsReply: ""
  });

  export default {
    name: "logsFilter",
    data() {
      return {
        properties: {
          priorities: [],
          statuses: [],
          needsReply: [
            { name: "Не", value: false },
            { name: "Да", value: true },
            { name: "Всички", value: null }
          ]
        },
        filters: Object.assign({}, filtersModel),
        hasActiveFilters: false,
        menuCreatedFrom: false,
        menuCreatedTo: false,
        itemSelectedMarker: 0,
        doFilterIsActivated: false
      }
    },

    created() { },

    methods: {
      doFilter() {
        if (!isEmptyObject(this.filters) || (this.filters.needsReply != "")) {  //a simple patch for our private deals
          this.filters.page = 1;
          this.hasActiveFilters = true;
          this.$emit("doFilter");
          delete this.filters.page;
        } else {
          if (this.hasActiveFilters === true)
            this.$emit("doFilter");

          this.hasActiveFilters = false;
        }
      },

      clearFilters() {
        this.filters = Object.assign({}, filtersModel);

        this.itemSelectedMarker = 0;
        this.hasActiveFilters = false;

        this.$emit("doFilter", true);
      },

      getFilters() {
        this.filters;
      },

      clickFilterItems(item) {
        this.itemSelectedMarker = this.itemSelectedMarker | item;
      },

      clearIt(item, propName) {
        this.itemSelectedMarker = this.itemSelectedMarker - item;
        this.filters[propName] = null;
      }
    }
  }
</script>
