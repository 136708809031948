<template>
  <base-material-filter style="border-top-right-radius: 30px;"
                        title="Търсене на адрес"
                        :hasFilters="hasActiveFilters"
                        v-on:do-filter="doFilter()"
                        v-on:remove-filter="clearFilters">
    <v-row @keypress.enter="doFilter" class="mx-1">
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(1, 0)">
        <v-text-field label="Град/Село"
                      v-model="filters.townName"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(1, 0, 'townName')"
                      @click="clickFilterItems(1, 0)"
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Адрес"
                      v-model="filters.addressDescription"
                      autocomplete="off"
                      clearable
                      hint="например КВ 32 УПИ І-61742 ПЪТЯ ЗА С.ДИВОТИНО   Вие можете да въведете само част от адреса!"
                      @click:clear="clearIt(2, 0, 'addressDescription')"
                      @click="clickFilterItems(2, 0)"
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Улица/булевард"
                      v-model="filters.streetName"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(4, 0, 'streetName')"
                      @click="clickFilterItems(4, 0)"
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Номер"
                      v-model="filters.streetNumber"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(8, 0, 'streetNumber')"
                      @click="clickFilterItems(8, 0)"
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Блок"
                      v-model="filters.block"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(16, 0, 'block')"
                      @click="clickFilterItems(16, 0)"
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Вход"
                      v-model="filters.entrance"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(32, 0, 'entrance')"
                      @click="clickFilterItems(32, 0)"
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Етаж"
                      v-model="filters.floor"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(64, 0, 'floor')"
                      @click="clickFilterItems(64, 0)"
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Апартамент"
                      v-model="filters.apartment"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(128, 0, 'apartment')"
                      @click="clickFilterItems(128, 0)"
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6" v-show="getVisibility(1, 0)">
        <v-text-field label="Клиентски №"
                      v-model="filters.clientNumber"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(256, 0, 'clientNumber')"
                      @click="clickFilterItems(256, 0)"
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Име на клиент"
                      v-model="filters.clientName"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(512, 0, 'clientName')"
                      @click="clickFilterItems(512, 0)"
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="ЕГН"
                      v-model="filters.egn"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(1024, 0, 'egn')"
                      @click="clickFilterItems(1024, 0)"
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Водомер №"
                      v-model="filters.waterMeterFabricNo"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(2048, 0, 'waterMeterFabricNo')"
                      @click="clickFilterItems(2048, 0)"
                      dense />
      </v-col>
    </v-row>
  </base-material-filter>
</template>

<script>

  import { isEmptyObject } from "@/utils";

  const filtersModel = Object.freeze({
    townName: null,
    addressDescription: null,
    streetName: null,
    streetNumber: null,
    block: null,
    entrance: null,
    floor: null,
    apartment: null,
    clientNumber: null,
    clientName: null,
    egn: null,
    waterMeterFabricNo: null,
  });

  export default {
    name: "addressesSearchFilter",
    data() {
      return {
        filters: Object.assign({}, filtersModel),
        hasActiveFilters: false,

        itemSelectedMarker1: 0,
        itemSelectedMarker2: 0,
        itemVisibilityMarker1: 4095, //itemVisibilityMarker1, itemVisibilityMarker2 are in block when one bit is up this control is visible, else - is hidden
        itemVisibilityMarker2: 0, //this data comes from parent, for adjusting the filter elements visibility
      };
    },
    created() {},

    methods: {
      doFilter() {
        if (!isEmptyObject(this.filters)) {  //a simple patch for our private deals
          this.filters.page = 1;
          this.hasActiveFilters = true;
          this.$emit("doFilter");
          delete this.filters.page;
        } else {
          if (this.hasActiveFilters === true) 
            this.$emit("doFilter");

          this.hasActiveFilters = false;
        }
      },

      clearFilters() {
        this.filters = Object.assign({}, filtersModel);

        this.itemSelectedMarker = 0;
        this.hasActiveFilters = false;

        this.$emit("doFilter", true);
      },

      getFilters() {
        this.filters;
      },

      clickFilterItems(item1, item2) {
        this.itemSelectedMarker1 = this.itemSelectedMarker1 | item1;
        this.itemSelectedMarker2 = this.itemSelectedMarker2 | item2;
      },

      clearIt(item1, item2, propName) {
        this.itemSelectedMarker1 = this.itemSelectedMarker1 - item1;
        this.itemSelectedMarker2 = this.itemSelectedMarker2 - item2;
        this.filters[propName] = null;
      },

      getVisibility(item1, item2) {
        var visibilityMarker1 = this.itemVisibilityMarker1 & item1;
        var visibilityMarker2 = this.itemVisibilityMarker2 & item2;

        return visibilityMarker1 > 0 || visibilityMarker2 > 0;
      },
    }
  }
</script>
