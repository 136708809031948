<template>
    <v-card id="sidebar-layer" class="sidebar-layer right collapsed sidebar-layer-content rounded-rect flex-center">
        <v-card-text>
            <v-expansion-panels v-model="panel" multiple style="margin-top: 50px;">
                <v-expansion-panel>
                    <v-expansion-panel-header>Подложки</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-checkbox v-for="(layer, i) in getWmsLayers" :key="i" v-model="selectedWMSLayers"
                            :label="layer.text" color="primary" :value="layer.id" @click="toggleWMSLayers" dense
                            multiple></v-checkbox>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
        <v-card-actions id="sidebar-layer-toggle">
            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" class="sidebar-layer-toggle rounded-rect right" dark v-bind="attrs" v-on="on"
                        @click.stop="toggleSidebar">
                        <v-icon dark> {{ toggleIcon }} </v-icon>
                    </v-btn>
                </template>
                <span>Слоеве</span>
            </v-tooltip>
        </v-card-actions>
        <v-btn id="btnClose" color="primary" @click.stop="toggleSidebar">Затвори</v-btn>
    </v-card>
</template>

<script>
export default {
    name: "sidebarLayers",
    components: {
    },
    props: {
        wsmlayers: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            selectedWMSLayers: [],
            toggleIcon: "mdi-layers-outline",
            panel: [0]
        }
    },
    watch: {
        wsmlayers: {
            handler(val) {
                this.selectedWMSLayers = val.filter(x => x["selected"]).map(x => x["id"]);
            }
        }
    },
    computed: {
        getWmsLayers: {
            get: function () {                
                return this.wsmlayers;
            }
        },
    },
    mounted() {
    },
    created() {
    },
    methods: {
        toggleSidebar() {
            const elem = document.getElementById("sidebar-layer");

            if (elem) {
                const classes = elem.className.split(" ");
                const collapsed = classes.indexOf("collapsed") !== -1;
                if (collapsed) {
                    classes.splice(classes.indexOf("collapsed"), 1);

                    setTimeout(() => {
                        this.toggleIcon = "mdi-arrow-collapse-right";
                    }, 100);
                } else {
                    classes.push("collapsed");

                    setTimeout(() => {
                        this.toggleIcon = "mdi-layers-outline";
                    }, 550);
                }
                elem.className = classes.join(" ");
            }
        },
        toggleWMSLayers() {
            this.$emit("toggle-wms-layer", this.selectedWMSLayers);
        }
    }
}
</script>

<style >
/* html {
    overflow: hidden;
} */

.v-card {
    display: flex;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.rounded-rect {
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 50px -25px black;
}

.flex-center {
    position: absolute;
    justify-content: center;
    align-items: center;
}

.flex-center.left {
    left: 0px;
}

.flex-center.right {
    right: 5px;
}

.right.collapsed {
    transform: translateX(410px);
}

#btnClose {
    display: none;
}

.v-card__actions#sidebar-layer-toggle {
    position: absolute;
}

.sidebar-layer-content {
    margin-top: 10px !important;
    width: 95%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 32px;
    color: gray;
}

.sidebar-layer-toggle {
    position: relative;
    width: 2em !important;
    height: 2em !important;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5em;
}

.sidebar-layer-toggle.right {
    left: -25em;
}

.sidebar-layer-toggle:hover {
    color: black;
    cursor: pointer;
}

.sidebar-layer {
    position: absolute;
    transition: transform 1s;
    z-index: 2;
    width: 400px;
    height: 95%;
}

@media (pointer:coarse) {
    #btnClose {
        display: flex
    }

    .sidebar-layer-toggle {
        position: relative;
        width: 1.3em;
        height: 1.3em;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5em;
    }
}
</style>