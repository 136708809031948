import Layout from "@/layouts/Layout.vue";
import logsList from "@/views/logs/list.vue";

let logsRoute = {
  path: "/logs",
  component: Layout,
  name: "Логове",
  redirect: "/logs",
  hidden: true,
  meta: {
    actions: ["Администратор"],
    groups: ["TestMainGroup", "TestDummyGroup"],
  },
  children: [
    {
      path: "/logs/list",
      name: "Логове",
      component: logsList,
      meta: {
        title: "Логове",
        actions: ["Администратор"],
        groups: ["TestMainGroup", "TestDummyGroup"],
        icon: "logs.png",
        goBackTitle: "Обратно към aварии",
      },
      hidden: false
    },    
  ]
}

export default logsRoute;
