import request from '@/utils/request';
import { filterQuery } from '@/utils/index';

export function getLogsList(query) {
  return request({
    url: '/api/Logs/GetLogsList',
    method: 'get',
    params: filterQuery(query)
  });
}


