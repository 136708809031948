<template>
  <v-form v-model="valid" ref="form">
    <v-container id="breakdownsAdd"
                 ref="breakdownsAdd"
                 fluid
                 tag="section"
                 class="full-height">
      <base-v-component goBackText="Обратно към аварии"
                        goBackTo="/breakdowns" />
      <v-row class="justify-center">
        <v-col cols="7">
          <v-card style="border-top-right-radius: 30px;
                       border-bottom-right-radius: 30px;
                       border-bottom-left-radius: 30px;"
                  class="v-card-profile"
                  color="white">

            <v-avatar style="border-top-right-radius: 20%;
                           border-bottom-left-radius: 20%;
                           border-bottom-right-radius: 20%;
                           margin-left: 20px;
                           margin-top: -40px;"
                      left
                      color="#263238"
                      size="107">
              <v-icon style="font-size: 400%; color: #ffffff;">mdi-water-off</v-icon>
            </v-avatar>
            <template v-slot:after-heading>
              <div>Създаване на авария</div>
            </template>
            <v-card-text class="text-center">
              <v-card-text class="text-center">
                <v-row>
                  <v-col lg="4" md="4" cols="4" style="margin-top: 5px">
                    <v-text-field v-model="objectBreakdown.waterStopPeople"
                                  label="Бр. засегнати потребители"
                                  :rules="requiredFieldRules"></v-text-field>
                  </v-col>
                </v-row>
                <v-row style="height: 60px">
                  <v-col lg="" md="4" cols="4">
                    <v-select style="margin-top: 11px"
                              :items="properties.priorities"
                              item-value="key"
                              item-text="value"
                              v-model="objectBreakdown.priorityId"
                              label="Приоритет"
                              clearable
                              dense
                              flat
                              :rules="requiredFieldRules">
                    </v-select>
                  </v-col>
                  <v-col lg="4" md="4" cols="4">
                    <v-text-field v-model="objectBreakdown.workCardNo"
                                  label="Авария №:"
                                  :rules="requiredFieldRules"></v-text-field>
                  </v-col>
                </v-row>
                <v-row style="height: 60px">
                  <v-col lg="4" md="4" cols="4">
                    <v-text-field v-model="objectBreakdown.regionName"
                                  label="Област"></v-text-field>
                  </v-col>
                  <v-col lg="4" md="4" cols="4">
                    <v-text-field v-model="objectBreakdown.municipalityName"
                                  label="Община"></v-text-field>
                  </v-col>
                  <v-col lg="4" md="4" cols="4">
                    <v-text-field v-model="objectBreakdown.townName"
                                  label="Град"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="4" md="4" cols="4">
                    <v-text-field v-model="objectBreakdown.streetName"
                                  label="Улица"></v-text-field>
                  </v-col>
                  <v-col lg="4" md="4" cols="4">
                    <v-text-field v-model="objectBreakdown.streetDescription"
                                  label="Описание на улица"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="4" md="4" cols="4">
                    <v-select style="margin-top: 11px"
                              :items="properties.kevrCategories"
                              item-value="key"
                              item-text="value"
                              v-model="objectBreakdown.kevrCategory1"
                              label="КЕВР категория 1"
                              clearable
                              dense
                              flat
                              :rules="requiredFieldRules">
                    </v-select>
                  </v-col>
                  <v-col lg="8" md="8" cols="8">
                    <v-select style="margin-top: 11px"
                              :items="properties.kevrSecondaryCategories"
                              item-value="key"
                              item-text="value"
                              v-model="objectBreakdown.kevrCategory2"
                              label="КЕВР категория 2"
                              clearable
                              dense
                              flat
                              :rules="requiredFieldRules">
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col clos="5">
          <v-card style="border-top-right-radius: 30px;
                         border-bottom-right-radius: 30px;
                         border-bottom-left-radius: 30px;"
                  height="520px"
                  class="v-card-profile">
            <v-card-text class="text-center">
              <v-card-text class="text-center">
                <v-row>
                  <v-col lg="6" md="6" cols="4" style="margin-top: 5px">
                    <v-menu v-model="menuSignalTimestamp"
                            v-bind:nudge-right="40"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field label="Начална дата"
                                      height="30"
                                      v-on="on"
                                      autocomplete="off"
                                      v-bind:value="objectBreakdown.signalTimestamp"
                                      clearable
                                      dense>
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="objectBreakdown.signalTimestamp"
                                     @@input="validFromMenu = false"
                                     persistent-hint
                                     color="primary"
                                     v-bind:first-day-of-week="1"
                                     locale="bg-bg">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col lg="6" md="6" cols="4">
                    <v-menu v-model="menuSignalTimestampTime"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="objectBreakdown.signalTimestamp"
                            transition="scale-transition"
                            offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="objectBreakdown.signalTimestampTime"
                                      label="Час"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-model="objectBreakdown.signalTimestampTime"
                                     format="24hr"
                                     width="290px"></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6" md="6" cols="4" style="margin-top: 5px">
                    <v-menu v-model="menuRepairStart"
                            v-bind:nudge-right="40"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field label="Начало планиран ремонт"
                                      height="30"
                                      v-on="on"
                                      autocomplete="off"
                                      v-bind:value="objectBreakdown.repairStart"
                                      clearable
                                      dense>
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="objectBreakdown.repairStart"
                                     @@input="validFromMenu = false"
                                     persistent-hint
                                     color="primary"
                                     v-bind:first-day-of-week="1"
                                     locale="bg-bg">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col lg="6" md="6" cols="4">
                    <v-menu v-model="menuRepairStartTime"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="objectBreakdown.repairStart"
                            transition="scale-transition"
                            offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="objectBreakdown.repairStartTime"
                                      label="Час"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-model="objectBreakdown.repairStartTime"
                                     format="24hr"
                                     width="290px"></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6" md="6" cols="4" style="margin-top: 5px">
                    <v-menu v-model="menuRepairEnd"
                            v-bind:nudge-right="40"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field label="Край планиран ремонт"
                                      height="30"
                                      v-on="on"
                                      autocomplete="off"
                                      v-bind:value="objectBreakdown.repairEnd"
                                      clearable
                                      dense>
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="objectBreakdown.repairEnd"
                                     @@input="validFromMenu = false"
                                     persistent-hint
                                     color="primary"
                                     v-bind:first-day-of-week="1"
                                     locale="bg-bg">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col lg="6" md="6" cols="4">
                    <v-menu v-model="menuRepairEndTime"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="objectBreakdown.repairEnd"
                            transition="scale-transition"
                            offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="objectBreakdown.repairEndTime"
                                      label="Час"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-model="objectBreakdown.repairEndTime"
                                     format="24hr"
                                     width="290px"></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6" md="6" cols="4" style="margin-top: 5px">
                    <v-menu v-model="menuWaterStopStart"
                            v-bind:nudge-right="40"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field label="Начало на планиранoто спиране на водата"
                                      height="30"
                                      v-on="on"
                                      autocomplete="off"
                                      v-bind:value="objectBreakdown.waterStopStart"
                                      clearable
                                      dense>
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="objectBreakdown.waterStopStart"
                                     @@input="validFromMenu = false"
                                     persistent-hint
                                     color="primary"
                                     v-bind:first-day-of-week="1"
                                     locale="bg-bg">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col lg="6" md="6" cols="4">
                    <v-menu v-model="menuWaterStopStartTime"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="objectBreakdown.waterStopStart"
                            transition="scale-transition"
                            offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="objectBreakdown.waterStopStartTime"
                                      label="Час"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-model="objectBreakdown.waterStopStartTime"
                                     format="24hr"
                                     width="290px"></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6" md="6" cols="4" style="margin-top: 5px">
                    <v-menu v-model="menuWaterStopEnd"
                            v-bind:nudge-right="40"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field label="Край на планиранoто спиране на водата"
                                      height="30"
                                      v-on="on"
                                      autocomplete="off"
                                      v-bind:value="objectBreakdown.waterStopEnd"
                                      clearable
                                      dense>
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="objectBreakdown.waterStopEnd"
                                     @@input="validFromMenu = false"
                                     persistent-hint
                                     color="primary"
                                     v-bind:first-day-of-week="1"
                                     locale="bg-bg">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col lg="6" md="6" cols="4">
                    <v-menu v-model="menuWaterStopEndTime"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="objectBreakdown.waterStopEnd"
                            transition="scale-transition"
                            offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="objectBreakdown.waterStopEndTime"
                                      label="Час"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-model="objectBreakdown.waterStopEndTime"
                                     format="24hr"
                                     width="290px"></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col lg="6" md="6" cols="4">
                    <v-text-field v-model="objectBreakdown.latitude"
                                  label="Latitude координати">
                    </v-text-field>
                  </v-col>
                  <v-col lg="6" md="6" cols="4">
                    <v-text-field v-model="objectBreakdown.longitude"
                                  label="Longitude координати"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-btn class="successGradient"
             width="140px"
             @click="createBreakdown(objectBreakdown)">
        <v-icon style="margin-right: 10px">mdi-content-save</v-icon>
        <span>Запази данните</span>
      </v-btn>

      <base-map :model="objectBreakdown" :isReadonly="false" :isBreakdown="true"></base-map>

    </v-container>
  </v-form>
</template>

<script>
  import { createBreakdown } from "@/api/breakdowns";
  import { getSignalPriorityAsKeyValuePairList } from "@/api/priorities";
  import { getKEVRCategoriesAsKeyValuePairList } from "@/api/kevr_category";
  import { getKEVRSecondaryCategoriesAsKeyValuePairList } from "@/api/kevr_secondary_category";
  import { ISODateString, formatDateTime } from "@/utils";

  export default {
    name: "breakdownsAdd",
    components: {},
    data() {
      return {
        valid: false,
        requiredFieldRules: [(v) => !!v || "Полето е задължително"],

        menuSignalTimestamp: false,
        menuSignalTimestampTime: false,
        menuRepairStart: false,
        menuRepairStartTime: false,
        menuRepairEnd: false,
        menuRepairEndTime: false,
        menuWaterStopStart: null,
        menuWaterStopStartTime: null,
        menuWaterStopEnd: null,
        menuWaterStopEndTime: null,

        objectBreakdown: {
          id: 0,
          finished: false,
          signalTimestamp: null,
          signalTimestampTime: null,
          repairStart: null,
          repairStartTime: null,
          repairEnd: null,
          repairEndTime: null,
          waterStopStart: null,
          waterStopStartTime: null,
          waterStopEnd: null,
          waterStopEndTime: null,
          waterStopPeople: null,
          kevrCategory1: 0,
          kevrCategory2: 0,
          regionName: null,
          municipalityName: null,
          priorityId: 0,
          townName: null,
          streetName: null,
          streetDescription: null,
          description: null,
          workCardNo: null,
          latitude: 0,
          longitude: 0,
        },

        properties: {
          kevrCategories: [],
          kevrSecondaryCategories: [],
          municipalities: [],
          priorities: [],
          finishValues: [
            { key: true, value: "Да" },
            { key: false, value: "Не" },
          ],
        },
      };
    },

    watch: {},
    computed: {},

    mounted() { },

    created() {
      this.getPrioritiesList();
      this.getKEVRCategoriesList();
      this.getKEVRSecondaryCategoriesList();
    },

    methods: {
      formatDateTime: formatDateTime,
      ISODateString: ISODateString,

      getPrioritiesList() {
        getSignalPriorityAsKeyValuePairList().then((data) => {
          data.splice(0, 1); //will remove 0 index (this is "AllItems")
          this.properties.priorities = data;
        });
      },
      getKEVRCategoriesList() {
        getKEVRCategoriesAsKeyValuePairList().then((data) => {
          data.splice(0, 1); //will remove 0 index (this is "AllItems")
          this.properties.kevrCategories = data;
        });
      },
      getKEVRSecondaryCategoriesList() {
        getKEVRSecondaryCategoriesAsKeyValuePairList().then((data) => {
          data.splice(0, 1); //will remove 0 index (this is "AllItems")
          this.properties.kevrSecondaryCategories = data;
        });
      },
      createBreakdown(objectBreakdown) {
        this.$refs.form.validate(); // validate form before posting
        if (this.valid) {
          createBreakdown(objectBreakdown).then((data) => {
            if (data.id == undefined) {
              var index1 = data.indexOf("(");
              var index2 = data.indexOf(")");
              if (index1 > -1 && index1 < index2) {
                this.$snotify.error(
                  "Нова авария не е създадена." +
                  "Грешка " +
                  data.substring(index2 + 1) +
                  "."
                );
              }
            } else if (data.id > 0) {
              //success
              this.$snotify.success(
                "Открита е нова авария с номер " +
                objectBreakdown.workCardNo +
                "."
              );
              this.$forceUpdate();
            }
          });
        }
      },
    },
  };
</script>
