<template>
  <base-material-filter style="border-top-right-radius: 30px;"
                        :title="'Търсене на импортирани файлове'"
                        :hasFilters="hasActiveFilters"
                        v-on:do-filter="doFilter()"
                        v-on:remove-filter="clearFilters"
                        :marginBottom="true">
    <v-row @keypress.enter="doFilter" class="mx-1" style="margin-top: 10px">
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Име на файл"
                      height="30"
                      autocomplete="off"
                      v-bind:value="filters.fileName"
                      clearable
                      @click:clear="clearIt(1, 'fileName')">
        </v-text-field>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-select label="Формат на съдържание"
                  :items="properties.contentMimeTypes"
                  item-value="key"
                  item-text="value"
                  v-model="filters.contentMimeType"
                  clearable
                  @click:clear="clearIt(2, 'contentMimeType')">
        </v-select>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu v-model="menuCreatedOnFrom"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Добавен на от"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.createdOnFrom"
                          clearable
                          @click:clear="clearIt(4, 'reatedUTCFrom')">
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.createdOnFrom"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu v-model="menuCreatedOnTo"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Добавен на до"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.createdUTCFrom"
                          clearable
                          @click:clear="clearIt(16, 'createdOnTo')">
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.createdOnTo"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-select label="Добавен от"
                  :items="properties.users"
                  item-value="key"
                  item-text="value"
                  v-model="filters.attachedByUser"
                  clearable
                  @click:clear="clearIt(32, 'attachedByUser')">
        </v-select>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-select label="Изтеглен"
                  :items="properties.downloadedStatuses"
                  item-text="key"
                  item-value="value"
                  v-model="filters.downloaded"
                  clearable
                  @click:clear="clearIt(64, 'downloaded')">
        </v-select>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-combobox label="Обработен"
                    v-model="properties.selectedItems"
                    :items="properties.processedStatuses" 
                    multiple
                    chips
                    clearable
                    @click:clear="clearIt(128, 'processedMask')">
        </v-combobox>
      </v-col>
    </v-row>
  </base-material-filter>
</template>

<script>

  import { isEmptyObject } from "@/utils";
  import { getUserAsKeyValuePairList } from '@/api/users';

  const filtersModel = Object.freeze({
    fileName: null,
    contentMimeType: null,
    createOnFrom: null,
    createOnTo: null,
    attachedByUser: null,
    downloaded: null,
    processedMask: [],
  });

  export default {
    name: "filterImportFile",
    data() {
      return {
        properties: {
          downloadedStatuses: [
            { key: "Всички", value: null },
            { key: "Не", value: false },
            { key: "Да", value: true }

          ],

          selectedItems: [],

          processedStatuses: [
            'Address',
            'Client',
            'Auditor',
            'WaterMeter',
            'Audit',
            'Carnet'
          ],

          users: [],

          contentMimeTypes: [
            { key: null, value: 'Всички типове файлове' },
            { key: '.csv', value: 'CSV' },
            { key: '.txt', value: 'text' }, 
          ],
        },

        filters: Object.assign({}, filtersModel),

        hasActiveFilters: false,

        menuCreatedOnFrom: false,
        menuCreatedOnTo: false,
      }
    },

    created() {
      this.getUserList();
    },

    methods: {
      getUserList() {
        getUserAsKeyValuePairList().then(data => {
          this.properties.users = data;
        })
      },

      doFilter() {
        if (!isEmptyObject(this.filters)) {  
          this.filters.page = 1;
          this.hasActiveFilters = true;
          this.$emit("doFilter");
          delete this.filters.page;
        } else {
          if (this.hasActiveFilters === true)
            this.$emit("doFilter");

          this.hasActiveFilters = false;
        }
      },

      clearFilters() {
        this.filters = Object.assign({}, filtersModel);

        this.hasActiveFilters = false;

        this.$emit("doFilter", true);
      },

      getFilters() {
        this.filters;
      },

      clearIt(item, propName) {
        this.itemSelectedMarker = this.itemSelectedMarker - item;
        this.filters[propName] = null;
      }
    }
  }
</script>
