import { getToken, setToken, removeToken, setCookie } from '@/utils/auth';
import router, { resetRouter } from '@/router';
import { getUserInfo } from '@/api/account';

const state = {
  token: getToken(),
  name: '',
  username: '',
  roles: [],
  actions: [],
  groups: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ACTIONS: (state, actions) => {
    state.actions = actions
  },
  SET_GROUPS: (state, groups) => {
    state.groups = groups
  },
  SET_MAP_CENTER: (state, center) => {
    state.last_map_center = center;
  },
  SET_MAP_ZOOM: (state, zoom) => {
    state.last_map_zoom = zoom;
  },
}


const actions = {
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserInfo().then(response => {
        if (!response) { reject('Сесията Ви е изтекла.'); }

        var { member_of, name, allowedActions, isProduction, allowedGroups } = response;

        // allowedActions must be a non-empty array
        if (!allowedActions || allowedActions.length <= 0) {
          reject('Нямате приложени позволени действия към Вашата роля!');
        }

        commit('SET_NAME', name);
        commit('SET_ROLES', member_of);
        commit('SET_ACTIONS', allowedActions);
        commit('SET_GROUPS', allowedGroups);

        const data = response;
        if (data.last_map_center_lat != null) {
          commit('SET_MAP_CENTER', { lat: data.last_map_center_lat, lng: data.last_map_center_lng });
        } else {
          commit('SET_MAP_CENTER', { lat: 42.698334, lng: 23.319941 });
        }

        commit('SET_MAP_ZOOM', data.map_last_zoom);
        commit('app/SET_PRODUCTION', isProduction, { root: true })
        resolve(response);

      }).catch(error => {
        reject(error)
      });
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}