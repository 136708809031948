import request from '@/utils/request';

export function getUserAsKeyValuePairList(userId = 0) {

  return request({
    url: '/api/user/GetUserAsKeyValuePairList?userId=' + userId,
    method: 'get',
  });
}

export function getAllUsersKeycloak() {
  return request({
    url: '/api/Account/GetUsersFromKeycloak',
    method: 'get',
  });
}

export function getUserInfoKeycloak(userId) {
  return request({
    url: '/api/Account/GetUserByIdFromKeycloak?Id=' + userId,
    method: 'get',
  });
}

export function addUserKeycloak(user) {
  return request({
    url: '/api/Account/CreateUserForKeycloak',
    data: user,
    method: 'post',
  });
}

export function updateUserKeycloak(user) {
  return request({
    url: '/api/Account/UpdateUserForKeycloak',
    data: user,
    method: 'put',
  });
}

export function deactivateUserKeycloak(user) {
  return request({
    url: '/api/Account/UpdateUserForKeycloak',
    data: user,
    method: 'put',
  });
}

export function activateUserKeycloak(user) {
  return request({
    url: '/api/Account/UpdateUserForKeycloak',
    data: user,
    method: 'put',
  });
}
