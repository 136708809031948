<template>
  <v-container id="addGroup"
               fluid
               tag="section"
               class="full-height">

    <base-v-component goBackText="Обратно към групи"
                      goBackTo="/administration/listGroups" />

    <v-form v-model="isFormValid">
      <v-row class="justify-center">
        <v-col cols="8">
          <v-card style="border-top-right-radius: 30px;
                       border-bottom-right-radius: 30px;
                       border-bottom-left-radius: 30px;"
                  class="v-card-profile"
                  color="white">

            <v-avatar style="border-top-right-radius: 20%;
                           border-bottom-left-radius: 20%;
                           border-bottom-right-radius: 20%;
                           margin-left: 20px;
                           margin-top: -40px;"
                      left
                      color="#263238"
                      size="100">
              <v-icon style="font-size: 400%; color: #ffffff;">mdi-account-multiple-plus</v-icon>
            </v-avatar>
            <v-card-text>
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-text-field v-model="model.name"
                                label="Име"
                                :rules="[rules.empty]"
                                clearable autocomplete="off">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row class="justify-center">
              <v-col cols="4">
                <v-btn block
                       class="ma-2 success"
                       @click="onSubmit"
                       :disabled="!isFormValid">
                  Запази
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

  </v-container>
</template>

<script>

  import { createGroupForKeycloak } from '@/api/groups'

  export default {
    name: "addGroup",
    components: {
    },
    data() {
      return {
        model: {
          name: null
        },
        rules: {
          empty: val => (val ? true : false) || 'Задължително поле'
        },
        isFormValid: false
      }
    },
    watch: { },
    computed: { },
    mounted() { },
    created() { },
    methods: {
      onSubmit() {
        createGroupForKeycloak(this.model).then((data) => {
          this.notification(data, true);
        });
      },
      notification(data, doAditionalActions) {
        if (data.includes('(0)')) {

          //Notification for failed operation
          this.$snotify.error(data.substring(4));

        } else if (data.includes('({0})')) {

          //Notification for successfull operation
          this.$snotify.success(data.substring(6));

          if (doAditionalActions) {
            //After update clean the model
            this.model.name = null;

            //Redirect to all users list
            this.$router.push({ path: `/administration/listGroups` });
          }
        }
      }
    }
  };
</script>
