<template>
  <v-container id="editUser"
               fluid
               tag="section"
               class="full-height">

    <base-v-component goBackText="Обратно към потребители"
                      goBackTo="/administration/listUsers" />

    <v-form v-model="isFormValid">
      <v-row class="justify-center">
        <v-col cols="8">
          <v-card style="border-top-right-radius: 30px;
                       border-bottom-right-radius: 30px;
                       border-bottom-left-radius: 30px;"
                  class="v-card-profile"
                  color="white">

            <v-avatar style="border-top-right-radius: 20%;
                           border-bottom-left-radius: 20%;
                           border-bottom-right-radius: 20%;
                           margin-left: 20px;
                           margin-top: -40px;"
                      left
                      color="#263238"
                      size="100">
              <v-icon style="font-size: 400%; color: #ffffff;">mdi-pencil-outline</v-icon>
            </v-avatar>

            <v-card-text>
              <v-row class="justify-end">
                <v-col cols="6"></v-col>
                <v-col cols="3" class="text-right">
                  <v-btn title="Роли" @click="redirectToUserRoles" class="unactiveGradient v-size--x-small success" style="width: 40px !important">
                    <v-icon class="mr-2">mdi-align-horizontal-right</v-icon>
                    Роли
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn title="Групи" @click="redirectToUserGroups" class="unactiveGradient v-size--x-small success" style="width: 40px !important">
                    <v-icon class="mr-2">mdi-account-group</v-icon>
                    Групи
                  </v-btn>
                </v-col>
              </v-row>
              <br />
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-text-field v-model="model.username"
                                label="Потребителско име"
                                :rules="[rules.empty]"
                                clearable autocomplete="off"
                                disabled>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-text-field v-model="model.email"
                                label="Е-поща"
                                :rules="[rules.empty, rules.isValidMail]"
                                clearable autocomplete="off">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-text-field v-model="model.firstName"
                                label="Име"
                                :rules="[rules.empty]"
                                clearable autocomplete="off">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-text-field v-model="model.lastName"
                                label="Фамилия"
                                :rules="[rules.empty]"
                                clearable autocomplete="off">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="8">
                  <v-switch v-model="model.enabled"
                            color="success"
                            :label="model.enabled ? 'Активен' : 'Неактивен'"
                            hide-details>
                  </v-switch>
                </v-col>
              </v-row>
              <template v-if="!changePassword">
                <v-row class="justify-center">
                  <v-col cols="2">
                    <v-btn block
                           class="ma-2 error"
                           small
                           @click="prepareFormForPasswordChange">
                      <v-icon>mdi-filter-variant</v-icon>
                      Смяна на парола
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row class="justify-center">
                  <v-col cols="8">
                    <v-text-field v-model="model.userPassword.value"
                                  :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                                  :type="showPass1 ? 'text' : 'password'"
                                  :rules="[rules.empty, rules.isValidPassword]"
                                  label="Нова парола"
                                  @click:append="showPass1 = !showPass1"
                                  counter clearable autocomplete="off">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="8">
                    <v-text-field v-model="model.confirmPassword"
                                  :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                                  :type="showPass2 ? 'text' : 'password'"
                                  :rules="[rules.empty, rules.isConfirmed]"
                                  label="Потвърди нова парола"
                                  @click:append="showPass2 = !showPass2"
                                  counter clearable autocomplete="off">
                    </v-text-field>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
            <v-divider></v-divider>
            <v-row class="justify-center">
              <v-col cols="4">
                <v-btn block
                       class="ma-2 success"
                       @click="onSubmitChanges"
                       :disabled="!isFormValid">
                  Запази промени
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>

  import { validateEmail, validatePassword } from '@/utils';
  import { getUserInfoKeycloak, updateUserKeycloak } from "@/api/users";

  export default {
    name: "editUser",
    components: {
    },
    data() {
      return {
        model: {
          id: '',
          username: '',
          email: '',
          firstName: '',
          lastName: '',
          enabled: true,
          userPassword: null,
          confirmPassword: null,
          groups: [],
          attributes: {}
        },
        rules: {
          empty: val => (val ? true : false) || 'Задължително поле',
          isValidMail: val => (val ? validateEmail(val) : false) || 'Невалидна е-поща',
          isValidPassword: val => (val ? validatePassword(val) : false) || 'Паролата трябва да съдържа малки и големи букви латински, цифри, специален символ и да е поне 8 символа',
          isConfirmed: val => (val ? val === this.model.userPassword.value : false) || 'Невалидно потвърждение на парола'
        },
        showPass1: false,
        showPass2: false,
        isFormValid: false,
        changePassword: false
      }
    },
    watch: {},
    computed: {},
    mounted() {
      let vue = this;

      vue.getData(vue.$route.params.userID);
    },
    created() { },
    methods: {
      getData(userID) {
        getUserInfoKeycloak(userID).then((data) => {
          let vue = this;
          Object.assign(vue.model, data);
          vue.$forceUpdate();
        });
      },
      onSubmitChanges() {
        updateUserKeycloak(this.model).then((data) => {
          if (data.includes('(0)')) {

            //Notification for failed operation
            this.$snotify.error(data.substring(4));

          } else if (data.includes('({0})')) {

            //Notification for successfull operation
            this.$snotify.success(data.substring(6));

            //After update clean the passwords fro the model
            this.model.userPassword = null;
            this.model.confirmPassword = null;
            this.changePassword = false;

            //Redirect to all users list
            this.$router.push({ path: `/administration/listUsers` });
          }
        });
      },
      prepareFormForPasswordChange() {
        this.changePassword = true;
        this.model.userPassword = {
          type: 'password',
          value: null,
          temporary: false
        };
      },
      redirectToUserRoles() {
        this.$router.push({ path: `/administration/editUserRole/${this.$route.params.userID}` })
      },
      redirectToUserGroups() {
        this.$router.push({ path: `/administration/editUserGroup/${this.$route.params.userID}` })
      }
    }
  };
</script>
