import request from '@/utils/request';

// get user info
export function getUserInfo() {
  return request({
    url: '/api/Account/GetCurrentUserInfo',
    method: 'get'
  })
}

// get app environment
export function getAppEnvironment(){
  return request({
    url: '/api/account/IsProductionEnvironment',
    method: 'get'
  })
}