<template>
  <section class="mb-4 mt-4 d-print-none">
    <v-row>
      <v-col md="6" class="py-0">
        <v-btn text @click="goBackToPath" class="no-hover hover-primary px-2" v-if="goBackTo && goBackText">
          <v-icon class="mr-3" :ripple="false">mdi-arrow-left-thick</v-icon>
          {{previousRouteTitle}}
        </v-btn>
      </v-col>
      <v-col md="6" class="py-0 text-right">
        <h1
          class="font-weight-light mb-2 headline"
          v-text="heading"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    name: 'VComponent',

    props: {
      heading: {
        type: String,
        default: '',
      },
      goBackText: {
        type: String,
        default: '',
      },
      goBackTo: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters([
        'lastRoute'
      ]),
      previousRouteTitle() {
        return this.lastRoute && this.lastRoute.meta && this.lastRoute.meta.goBackTitle && this.lastRoute.path !== '/' ? this.lastRoute.meta.goBackTitle : this.goBackText;
      },
    },
    methods: {
      goBackToPath(){
        if(this.lastRoute && this.lastRoute.meta && this.lastRoute.meta.goBackTitle && this.lastRoute.path !== '/'){
          this.$store.dispatch("permission/goBack");
        } else {
          this.$router.push({path: this.goBackTo})
        }
      }
    }
  }
</script>
