import { getAddressGoogle } from "@/api/map";

export default class Geocode {
  getApiSource() {
    const geocode = {
      forwardGeocode: async (config) => {
        let features = [];
        try {
            features = await this.callGoogleEngine(config, features);

        } catch (e) {
          //console.error(`Address for found: ${e}`);
        }
        return {
          features: features,
        };
      },
    };
    return geocode;
  }

  async callGoogleEngine(config, features) {
    const response = await getAddressGoogle(config.query);
    const data = await response;
    
    const geojsonGoogle = JSON.parse(data.google);
    const geojsonHere = JSON.parse(data.hire);

    let googleItems = [];
    if (geojsonGoogle) googleItems = geojsonGoogle;

    let hereItems = [];
    if (geojsonHere) hereItems = geojsonHere.items;

    features = this.interpretGoogleItems(googleItems, features);
    features = this.interpretHereItems(hereItems, features);

    return features;
  }

  interpretGoogleItems(items, features) {
    for (const item of items) {
      const center = [item.geometry.location.lng, item.geometry.location.lat];
      const point = {
        type: item.geometry.location_type,
        geometry: {
          type: "Point",
          coordinates: center,
        },
        place_name: item.formatted_address,
        properties: item,
        text: item.formatted_address,
        center: center,
      };
      features.push(point);
    }
    return features;
  }

  interpretHereItems(items, features) {
    for (const item of items) {
      const center = [item.position.lng, item.position.lat];
      const point = {
        type: item.resultType,
        geometry: {
          type: "Point",
          coordinates: center,
        },
        place_name: item.title,
        properties: item,
        text: item.title,
        center: center,
      };
      features.push(point);
    }
    return features;
  }
}
