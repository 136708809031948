<template>
  <v-container id="groupList"
               ref="groupList"
               fluid
               tag="section"
               class="full-height">

    <base-material-card hover-reveal
                        color="primary"
                        icon="mdi-account-group"
                        inline
                        class="px-5 py-5 mt-15">

      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn color="success" @click="onAddGroup" class="successGradient">
            <v-icon>mdi-plus</v-icon>
            Добави група
          </v-btn>
        </v-col>
      </v-row>

      <base-data-table :headers="table.headers"
                       :data="table.data"
                       @getData="getData"
                       @doAction="doTableAction"
                       :inlineSearch="true"
                       :hasPaging="false" />

    </base-material-card>
  </v-container>
</template>

<script>

  import { getAllGroupsFromKeycloak, deleteGroupFromKeycloak } from "@/api/groups";

  export default {
    name: "groupList",
    components: {
    },
    data() {
      return {
        table: {
          search: '',
          headers: [
            { text: 'Име', value: 'name', valueType: "text" },
            {
              sortable: false,
              text: '',
              value: 'actions',
              valueType: "button",
              class: 'text-center cols cols-4',
              style: 'width:250px; padding: 10px;',
              buttons: [
                {
                  title: 'Редакция',
                  icon: 'mdi-pencil-outline',
                  size: 'x-small',
                  class: 'success',
                  click: 'editGroup',
                  id: 'editGroup',
                  gradient: 'successGradient',
                  style: 'width: 20px !important;height: 30px !important;padding: unset!important; margin-right: 5px !important;'
                },
                {
                  title: 'Изтриване',
                  icon: 'mdi-trash-can-outline',
                  size: 'x-small',
                  class: 'error',
                  click: 'deleteGroup',
                  id: 'deleteGroup',
                  gradient: 'errorGradient',
                  style: 'width: 20px !important;height: 30px !important;padding: unset!important; margin-right: 5px !important;'
                },
              ],
            }
          ],
          data: [],
        }
      }
    },
    watch: {
      '$route.path': 'getData'
    },
    computed: {},
    mounted() {
      let vue = this;
      vue.getData();
    },
    created() { },
    methods: {
      getData() {
        getAllGroupsFromKeycloak().then((data) => {
          this.table.data = data;
        });
      },
      doTableAction(actionData) {
        switch (actionData.action) {
          case 'editGroup':
            this.$router.push({ path: `/administration/editGroup/${actionData.item.id}` })
            break;
          case 'deleteGroup':
            this.deleteGroup(actionData.item.id)
            break;
        }
      },
      onAddGroup() {
        this.$router.push({ path: `/administration/addgroup` })
      },
      deleteGroup(id) {
        if (confirm(`Сигурни ли сте, че искате да изтриете група ${this.table.data.filter(x => x.id === id)[0].name}?`)) {
          deleteGroupFromKeycloak(id).then((data) => {
            this.notification(data)
            let index = this.table.data.findIndex(x => x.id === id);
            this.table.data.splice(index, 1);
          });
        }
      },
      notification(data) {
        if (data.includes('(0)')) {

          //Notification for failed operation
          this.$snotify.error(data.substring(4));

        } else if (data.includes('({0})')) {

          //Notification for successfull operation
          this.$snotify.success(data.substring(6));
        }
      }
    }
  };
</script>
