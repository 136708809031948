<template>
  <v-card v-bind="$attrs" :class="classes" class="v-card--material pa-3">
    <div class="d-flex grow flex-wrap login-mc-innerdiv">
      <v-avatar
        v-if="avatar"
        size="128"
        class="mx-auto v-card--material__avatar elevation-6 login-mc-title"
        color="white"
      >
        <v-img :src="avatar" />
      </v-avatar>

      <v-sheet
        v-else
        :class="{
          cardIconHolder: true,
          'pa-4 mb-n3': smIcon,
          'pa-7 mb-n6': !smIcon,
          //'pa-7': !$slots.image
        }"
        :color="color"
        :max-height="icon ? 90 : undefined"
        :width="inline || icon ? 'auto' : '100%'"
        elevation="6"
        class="`text-start v-card--material__heading"
        dark
      >
        <slot v-if="$slots.heading" name="heading" />

        <slot v-else-if="$slots.image" name="image" />

        <slot v-else-if="$slots.svg" name="svg" />

        <div
          v-else-if="title && !icon"
          class="display-1 font-weight-light"
          v-text="title"
        />

        <template v-else-if="icon">
          <v-icon v-if="!icon.includes('svg')" size="32" v-text="icon" />
          <v-img
            v-else
            width="40"
            :src="require(`@/assets/${icon}`)"
            class="mt-2"
            :style="icon.includes('.svg') ? 'height: 15px;' : ''"
          />
        </template>

        <div v-if="text" class="headline font-weight-thin" v-text="text" />
      </v-sheet>

      <v-row v-if="$slots['after-heading']" class="ml-3">
        <template v-if="fullTitle">
          <v-col cols="12" class="cardTitle display-2 font-weight-light">
            <slot name="after-heading" />
          </v-col>
        </template>
        <template v-else>
          <v-col
            cols="12"
            md="12"
            class="cardTitle display-2 font-weight-light"
          >
            <slot name="after-heading" />
          </v-col>
          <v-col cols="12" md="3" class="buttonContainer text-right">
            <template v-if="expandable">
              <template v-if="areAllCardsExpanded != null">
                <v-tooltip top v-if="areAllCardsExpanded">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      style="padding: 0 !important; min-width: auto !important"
                      @click="collapseAllCards"
                    >
                      <v-icon>mdi-eye-off-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Свий всички карти</span>
                </v-tooltip>
                <v-tooltip top v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      style="padding: 0 !important; min-width: auto !important"
                      @click="expandAllCards"
                    >
                      <v-icon>mdi-eye-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Покажи всички карти</span>
                </v-tooltip>
              </template>
              <v-tooltip top v-if="expanded">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    style="padding: 0 !important; min-width: auto !important"
                    @click="toggleExpand"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </template>
                <span
                  >Свий <strong>{{ expandableTitle }}</strong></span
                >
              </v-tooltip>
              <v-tooltip top v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    style="padding: 0 !important; min-width: auto !important"
                    @click="toggleExpand"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <span
                  >Покажи <strong>{{ expandableTitle }}</strong></span
                >
              </v-tooltip>
            </template>
            <template v-else>
              <slot name="after-heading-button" />
            </template>
          </v-col>
        </template>
      </v-row>

      <div v-if="$slots['after-heading'] && false" class="ml-6">
        <slot name="after-heading" />
      </div>

      <v-col
        v-if="hoverReveal"
        cols="12"
        class="hoverableIcon text-center py-0 mt-n12"
      >
        <slot name="reveal-actions" />
      </v-col>

      <div v-else-if="icon && title" class="ml-4">
        <div class="card-title font-weight-light" v-text="title" />
      </div>
    </div>

    <template v-if="expandable">
      <slot v-if="!expanded" name="show-when-collapsed" />

      <v-expand-transition v-if="expanded">
        <div>
          <slot />
        </div>
      </v-expand-transition>
    </template>
    <template v-else>
      <slot />
    </template>

    <template v-if="$slots.actions">
      <v-divider class="mt-2" />

      <v-card-actions class="pb-0">
        <div class="text-right" style="width: 100%">
          <slot name="actions" />
        </div>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
export default {
  name: "MaterialCard",

  props: {
    avatar: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "success",
    },
    hoverReveal: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    smIcon: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    fullTitle: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    expandableTitle: {
      type: String,
      default: "",
    },
    expanded: {
      type: Boolean,
      default: true,
    },
    areAllCardsExpanded: {
      type: Boolean,
      default: null,
    },
  },

  computed: {
    classes() {
      return {
        "v-card--material--has-heading": this.hasHeading,
        "v-card--material--hover-reveal": this.hoverReveal && !this.smIcon,
        "v-card--material--hover-reveal-mini": this.hoverReveal && this.smIcon,
      };
    },
    hasHeading() {
      return Boolean(this.$slots.heading || this.title || this.icon);
    },
    hasAltHeading() {
      return Boolean(this.$slots.heading || (this.title && this.icon));
    },
  },
  methods: {
    collapseAllCards() {
      this.$emit("collapseAllCards");
    },
    expandAllCards() {
      this.$emit("expandAllCards");
    },
    toggleExpand() {
      this.$emit("update:expanded", !this.expanded);
    },
  },
};
</script>

<style lang="sass">
.v-card--material
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

    &__heading
      position: relative
      top: -40px
      transition: .3s ease
      z-index: 1

    &.v-card--material--hover-reveal:hover
      .v-card--material__heading
        transform: translateY(-30px)

    &.v-card--material--hover-reveal-mini:hover
      .v-card--material__heading
        transform: translateY(-15px)
</style>
