
const getters = {
  loading: (state) => state.app.loading,
  drawer: (state) => state.app.drawer,
  isProduction: (state) => state.app.production,
  token: (state) => state.user.token,
  username: (state) => state.user.name,
  isLogged: (state) => state.user.isLogged,
  roles: (state) => state.user.roles,
  actions: (state) => state.user.actions,
  groups: (state) => state.user.groups,
  permissionRoutes: (state) => state.permission.routes,
  dashboardRoutes: (state) => state.permission.dashboards,
  lastRoute: (state) => state.permission.lastRoute,
  last_map_center: (state) => state.user.last_map_center,
  last_map_zoom: (state) => state.user.last_map_zoom,
  actionsAccess: function(state) {
    return {
      Operator: state.user.actions.includes('Operator'),
      ActivityLog: state.user.actions.includes('ActivityLog'),
      ErrorsLog: state.user.actions.includes('ErrorsLog'),
      Roles: state.user.actions.includes('Roles'),
    }
  }
}

export default getters;
