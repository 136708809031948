var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"breakdownsList",staticClass:"full-height",attrs:{"id":"breakdownsList","fluid":"","tag":"section"}},[[_c('div',{staticClass:"export-print-container"},[_c('v-btn',{staticClass:"no-print",attrs:{"color":"success "},on:{"click":_vm.downloadFile}},[_c('v-icon',[_vm._v("mdi-file-download")])],1),_c('v-btn',{staticClass:"no-print",attrs:{"color":"success "},on:{"click":_vm.printPage}},[_c('v-icon',[_vm._v("mdi-printer-pos-edit-outline")])],1)],1),_c('div',[_c('filter-breakdowns',{ref:"breakdownsFilter",on:{"doFilter":function($event){return _vm.getData()}}}),_c('v-data-table',{ref:"table",staticClass:"elevation-2",staticStyle:{"border-bottom-right-radius":"30px","border-bottom-left-radius":"30px","margin-top":"-15px"},attrs:{"headers":_vm.tableProps.headers,"items":_vm.tableProps.data,"id":"print-table","disable-pagination":"","hide-default-footer":"","sort-by":_vm.customSortColumn,"sort-desc":_vm.customSortDesc,"multi-sort":"","disable-sort":!_vm.tableProps.data || !_vm.tableProps.data.length},on:{"update:sortBy":function($event){_vm.customSortColumn=$event},"update:sort-by":function($event){_vm.customSortColumn=$event},"update:sortDesc":function($event){_vm.customSortDesc=$event},"update:sort-desc":function($event){_vm.customSortDesc=$event}},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',[(items && items.length)?_vm._l((items),function(item,idx){return _c('tr',{key:`row_${idx}`,on:{"click":function($event){return _vm.setBreakdownSelected(item)}}},[_vm._l((_vm.tableProps.headers),function(cell,idx){return [(
                      cell.valueType !== 'button' &&
                      cell.valueType !== 'status' &&
                      cell.valueType !== 'textBalance' &&
                      cell.valueType !== 'checkbox'
                    )?_c('td',{key:'td_' + idx,class:cell.classItem,style:(cell.style)},[_vm._v(" "+_vm._s(_vm.getValue(item, cell))+" ")]):_vm._e(),(cell.valueType === 'textBalance')?_c('td',{key:'td_' + idx,class:cell.classItem,style:(cell.style)},[_c('span',{staticClass:"blue--text darken-4"},[_vm._v(" "+_vm._s(_vm.getValue(item, cell))+" ")])]):_vm._e(),(cell.valueType === 'status')?_c('td',{key:'td_' + idx,class:cell.classItem +
                      ' ' +
                      (cell.active === item[cell.displayValue || cell.value]
                        ? cell.activeClass
                        : cell.notActive ===
                          item[cell.displayValue || cell.value]
                        ? cell.notActiveClass
                        : ''),style:(cell.style)},[(cell.statusIcons)?[(
                          item[cell.displayValue || cell.value] ===
                          cell.active
                        )?_c('v-icon',{staticClass:"success--text"},[_vm._v("mdi-check")]):_vm._e(),(
                          item[cell.displayValue || cell.value] ===
                          cell.notActive
                        )?_c('v-icon',{staticClass:"error--text"},[_vm._v("mdi-close")]):_vm._e()]:[(
                          item[cell.displayValue || cell.value] == cell.active
                        )?[_vm._v(" "+_vm._s(cell.activeText)+" ")]:(
                          item[cell.displayValue || cell.value] ==
                          cell.notActive
                        )?[_vm._v(" "+_vm._s(cell.notActiveText)+" ")]:[_vm._v(" "+_vm._s(_vm.getValue(item, cell))+" ")]]],2):_vm._e(),(cell.valueType === 'checkbox')?_c('td',{key:'td_' + idx,class:cell.classItem,style:(cell.style)},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"primary","value":cell.checked,"false-value":cell.unchecked,"hide-details":""},on:{"change":function($event){return _vm.changeCheckboxValue(item)}},model:{value:(item[cell.displayValue || cell.value]),callback:function ($$v) {_vm.$set(item, cell.displayValue || cell.value, $$v)},expression:"item[cell.displayValue || cell.value]"}})],1):_vm._e(),(cell.valueType === 'button')?_c('td',{key:'td_' + idx,staticClass:"d-print-none",class:cell.classItem,style:(cell.style)},[_vm._l((cell.buttons),function(button,bIdx){return [(_vm.checkButton(button, item))?_c('v-btn',{key:'td_button_' + bIdx,class:`v-size--${button.size} ` + button.class,style:(button.style),attrs:{"block":button.block,"title":button.title},on:{"click":function($event){return _vm.doAction(button.click, item)}}},[(
                            button.icon && button.icon.length ? true : false
                          )?_c('v-icon',{class:button.label ? 'mr-2' : 'mr-0'},[_vm._v(_vm._s(button.icon))]):_vm._e(),_vm._v(" "+_vm._s(button.label)+" ")],1):_vm._e()]})],2):_vm._e()]})],2)}):[_c('tr',[_c('td',{staticClass:"text-center px-8",attrs:{"colspan":_vm.tableProps.headers.length}},[_c('v-row',[_c('v-col',{staticClass:"pa-7",attrs:{"cols":"12"}},[_c('h4',{staticClass:"display-1 font-weight-medium blue-grey--text text--darken-2"},[_vm._v(" Няма намерени резултати ")])])],1)],1)])]],2)]}}])}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticStyle:{"display":"inline","padding-top":"20px","width":"150px"}},[(_vm.tableProps.hasPaging)?_c('v-select',{attrs:{"items":_vm.tableProps.pagination.perPageOptions,"item-text":"Value","item-value":"Key","placeholder":"-- Избери --","label":"Редове на страница","color":_vm.tableProps.color},on:{"change":function($event){return _vm.changeRowsPerPage()}},model:{value:(_vm.tableProps.pagination.itemsPerPage),callback:function ($$v) {_vm.$set(_vm.tableProps.pagination, "itemsPerPage", $$v)},expression:"tableProps.pagination.itemsPerPage"}}):_vm._e()],1),_c('div',{staticStyle:{"display":"inline"}},[(_vm.tableProps.hasPaging)?_c('v-pagination',{staticClass:"d-print-none",attrs:{"color":_vm.tableProps.props.color,"length":Math.ceil(
                _vm.tableProps.pagination.total /
                  _vm.tableProps.pagination.itemsPerPage
              ),"total-visible":10},on:{"input":function($event){return _vm.changedPagination()}},model:{value:(_vm.tableProps.pagination.page),callback:function ($$v) {_vm.$set(_vm.tableProps.pagination, "page", $$v)},expression:"tableProps.pagination.page"}}):_vm._e()],1),_c('div',{staticStyle:{"display":"inline","padding-top":"30px"}},[_c('span',[_vm._v("Общо: "),_c('strong',[_vm._v(_vm._s(_vm.tableProps.pagination.total))]),_vm._v(" записа.")])])])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }