import request from '@/utils/request';

export function getAllClientsFromKeycloak() {
  return request({
    url: '/api/Account/GetAllClientsFromKeycloak',
    method: 'get',
  });
}

export function getClientAvailableRolesByUserIdFromKeycloak(userID, clientID) {
  return request({
    url: `/api/Account/GetClientAvailableRolesByUserIdFromKeycloak?userId=${userID}&clientId=${clientID}`,
    method: 'get',
  });
}

export function getClientAssignedRolesByUserIdFromKeycloak(userID, clientID) {
  return request({
    url: `/api/Account/GetClientAssignedRolesByUserIdFromKeycloak?userId=${userID}&clientId=${clientID}`,
    method: 'get',
  });
}

export function assingClientRolesToUser(roles, userID, clientID) {
  return request({
    url: `/api/Account/AssingClientRolesToUser?userId=${userID}&clientId=${clientID}`,
    data: roles,
    method: 'post',
  });
}

export function removeClientRolesFromUser(roles, userID, clientID) {
  return request({
    url: `/api/Account/RemoveClientRolesFromUser?userId=${userID}&clientId=${clientID}`,
    data: roles,
    method: 'delete',
  });
}
