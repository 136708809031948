var render, staticRenderFns
import script from "./clientsSearchFilter.vue?vue&type=script&lang=js&"
export * from "./clientsSearchFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./clientsSearchFilter.vue?vue&type=custom&index=0&blockType=base-material-filter&title=%D0%A2%D1%8A%D1%80%D1%81%D0%B5%D0%BD%D0%B5%20%D0%BD%D0%B0%20%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%B8&%3AhasFilters=hasActiveFilters&v-on%3Ado-filter=doFilter()&v-on%3Aremove-filter=clearFilters"
if (typeof block0 === 'function') block0(component)

export default component.exports