import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/sass/overrides.sass';
import bg from './vuetify-locale/bg';

Vue.use(Vuetify)

const theme = {
  primary: '#263238',
  secondary: '#c4ffff',
  accent: '#3bcaba',
  info: '#3bcaba',
  success: "#36a498",
  successHeader: "#eeeeee",
}

export default new Vuetify({
  lang: { 
    locales: { bg },
    current: 'bg'
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
