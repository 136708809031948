import request from '@/utils/request';
import { filterQuery } from '@/utils/index';

// getting all clients
export function getClients(query) {
  return request({
    url: '/api/WaterMeter/Client/GetClientsList',
    method: 'get',
    params: filterQuery(query)
  })
}

export function getClientById(clientId) {
  return request({
    url: '/api/WaterMeter/Client/getClientById?clientId=' + clientId,
    method: 'get',
  });
}

export function updateClient(clientData) {
  return request({
    url: '/api/WaterMeter/Client/updateClient',
    method: 'post',
    data: clientData
  });
}

export function createClient(clientData) {
  return request({
    url: '/api/WaterMeter/Client/createClient',
    method: 'post',
    data: clientData
  });
}

export function getClientDataListByWaterMeterId(waterMeterId) {
  return request({
    url: '/api/WaterMeter/Client/getClientDataListByWaterMeterId?waterMeterId=' + waterMeterId,
    method: 'get',
  });
}

export function getClientSearchList(query) {
  return request({
    url: '/api/WaterMeter/Client/GetClientSearchList',
    method: 'get',
    params: filterQuery(query)
  })
}

export function getClientSearchDetailsByClientId(clientId) {
  return request({
    url: '/api/WaterMeter/Client/GetClientSearchDetailsByClientId?clientId=' + clientId,
    method: 'get',
  })
}

export function getClientDataByAddressId(addressId) {
  return request({
    url: '/api/WaterMeter/Client/GetClientDataByAddressId?addressId=' + addressId,
    method: 'get',
  })
}
