import Layout from "@/layouts/Layout.vue";
import signalFromOper from "@/views/signal_from_oper/preview.vue";

let signalFromOperRoute = {
  path: "/signals",
  component: Layout,
  name: "Сигнали",
  redirect: "/signals",
  hidden: true,
  meta: {
    actions: ["Оператор"],
    groups: ["TestMainGroup"],
  },
  children: [
    {
      path: "/signal_from_oper/preview",
      name: "Сигнал от оператор",
      component: signalFromOper,
      meta: {
        title: "Сигнал от оператор",
        actions: ["Оператор"],
        groups: ["TestMainGroup"],
        icon: "operator.png",
        goBackTitle: "Обратно към сигнали",
      },
      hidden: false
    },
  ]
}

export default signalFromOperRoute;
