<template>
  <base-material-filter
    style="border-top-right-radius: 30px;"
    title="Търсене на сигнали"
    :hasFilters="hasActiveFilters"
    v-on:do-filter="doFilter()"
    v-on:search-by-breakdown-Id="searchByBreakdownId()"
    v-on:remove-filter="clearFilters"
  >
    <v-row @keypress.enter="doFilter" class="mx-1">
      <v-col lg="2" md="4" cols="6">
        <v-select
          label="Потребител"
          :items="properties.users"
          item-value="key"
          item-text="value"
          v-model="filters.UserId"
          clearable
          @click:clear="clearIt(1, 'UserId')"
          @click="clickFilterItems(1)"
        >
        </v-select>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-select
          label="Приоритет"
          :items="properties.priorities"
          item-value="key"
          item-text="value"
          v-model="filters.priorityId"
          clearable
          @click:clear="clearIt(2, 'priorityId')"
          @click="clickFilterItems(2)"
        >
        </v-select>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-select
          label="Статус"
          :items="properties.statuses"
          item-value="key"
          item-text="value"
          v-model="filters.statusId"
          clearable
          @click:clear="clearIt(4, 'statusId')"
          @click="clickFilterItems(4)"
        >
        </v-select>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <base-select
          label="С отговор"
          :items="properties.needsReply"
          item-text="name"
          item-value="value"
          v-model="filters.needsReply"
          clearable
          @click:clear="clearIt(8, 'needsReply')"
          @click="clickFilterItems(8)"
        >
        </base-select>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu
          v-model="menuCreatedUTCFrom"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата на създаване от"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.createdUTCFrom"
              clearable
              @click:clear="clearIt(16, 'reatedUTCFrom')"
              @click="clickFilterItems(16)"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.createdUTCFrom"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu
          v-model="menuCreatedUTCTo"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата на създаване до"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.createdUTCTo"
              clearable
              @click:clear="clearIt(32, 'createdUTCTo')"
              @click="clickFilterItems(32)"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.createdUTCTo"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu
          v-model="menuClosedUTCFrom"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата на затваряне от"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.closedUTCFrom"
              clearable
              @click:clear="clearIt(64, 'closedUTCFrom')"
              @click="clickFilterItems(64)"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.closedUTCFrom"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu
          v-model="menuClosedUTCTo"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Дата на затваряне до"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.closedUTCTo"
              clearable
              @click:clear="clearIt(128, 'closedUTCTo')"
              @click="clickFilterItems(128)"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.closedUTCTo"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu
          v-model="menuLastResponseUTCFrom"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Посл. отговор от"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.lastResponseUTCFrom"
              clearable
              @click:clear="clearIt(256, 'lastResponseUTCFrom')"
              @click="clickFilterItems(256)"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.lastResponseUTCFrom"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu
          v-model="menuLastResponseUTCTo"
          v-bind:nudge-right="40"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Посл. отговор до"
              height="30"
              v-on="on"
              autocomplete="off"
              v-bind:value="filters.lastResponseUTCTo"
              clearable
              @click:clear="clearIt(512, 'lastResponseUTCTo')"
              @click="clickFilterItems(512)"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.lastResponseUTCTo"
            @@input="validFromMenu = false"
            persistent-hint
            color="primary"
            v-bind:first-day-of-week="1"
            locale="bg-bg"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field
          label="Авария №"
          v-model="filters.workCardNo"
          clearable
          @click:clear="clearIt(256, 'workCardNo')"
          @click="clickFilterItems(256)"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </base-material-filter>
</template>

<script>
import { isEmptyObject } from "@/utils";
import { getSignalPriorityAsKeyValuePairList } from "@/api/priorities";
import { getSignalStatusAsKeyValuePairList } from "@/api/statuses";
import { getUserAsKeyValuePairList } from "@/api/users";

const filtersModel = Object.freeze({
  createdUTCFrom: null,
  createdUTCTo: null,
  closedUTCFrom: null,
  closedUTCTo: null,
  lastResponseUTCFrom: null,
  lastResponseUTCTo: null,
  statusId: null,
  priorityId: null,
  needsReply: "",
  workCardNo: null,
});

export default {
  name: "signalsOperFilter",
  data() {
    return {
      properties: {
        priorities: [],
        statuses: [],
        needsReply: [
          { name: "Всички", value: null },
          { name: "Не", value: false },
          { name: "Да", value: true },
        ],
        users: [],
      },
      filters: Object.assign({}, filtersModel),

      hasActiveFilters: false,

      menuCreatedUTCFrom: false,
      menuCreatedUTCTo: false,
      menuClosedUTCFrom: false,
      menuClosedUTCTo: false,
      menuLastResponseUTCFrom: false,
      menuLastResponseUTCTo: false,
    };
  },

  created() {
    this.getUserList();
    this.getStatusList();
    this.getPriorityList();
  },
  mounted() {},
  methods: {
    getUserList() {
      getUserAsKeyValuePairList().then((data) => {
        this.properties.users = data;
      });
    },

    getStatusList() {
      getSignalStatusAsKeyValuePairList().then((data) => {
        this.properties.statuses = data;
      });
    },

    getPriorityList() {
      getSignalPriorityAsKeyValuePairList().then((data) => {
        this.properties.priorities = data;
      });
    },

    doFilter() {
      if (!isEmptyObject(this.filters) || this.filters.needsReply == null) {
        //a simple patch for our private deals
        this.filters.page = 1;
        this.hasActiveFilters = true;
        this.$emit("doFilter");
        delete this.filters.page;
      } else {
        if (this.hasActiveFilters === true) this.$emit("doFilter");

        this.hasActiveFilters = false;
      }
    },
    clearFilters() {
      this.filters = Object.assign({}, filtersModel);

      this.hasActiveFilters = false;

      this.$emit("doFilter", true);
    },
    searchByBreakdownId() {
      this.hasActiveFilters = true;
      this.$emit("search-by-breakdown-Id");
    },
    getFilters() {
      this.filters;
    },

    clickFilterItems(item) {
      this.itemSelectedMarker = this.itemSelectedMarker | item;
    },

    clearIt(item, propName) {
      this.itemSelectedMarker = this.itemSelectedMarker - item;
      this.filters[propName] = null;
    },
  },
};
</script>
