import Layout from "@/layouts/Layout.vue";

//Users
import UsersList from "@/views/administration/listUsers.vue";
import AddUser from "@/views/administration/addUser.vue";
import EditUser from "@/views/administration/editUser.vue";

//Roles
import EditUserRole from "@/views/administration/editUserRole.vue";

//Groups
import GroupList from "@/views/administration/listGroup.vue";
import EditGroup from "@/views/administration/editGroup.vue";
import AddGroup from "@/views/administration/addGroup.vue";
import EditUserGroup from "@/views/administration/editUserGroup.vue";

let administrationRoute = {
  path: "/administration",
  component: Layout,
  name: "Администрация",
  redirect: "/administration",
  hidden: false,
  meta: {
    actions: ["Администратор"],
    groups: ["TestMainGroup"],
    icon: "mdi-security",
  },
  children:
    [
      //Users
      {
        path: "/administration/listUsers",
        name: "Потребители",
        component: UsersList,
        meta: {
          title: "Потребители",
          actions: ["Администратор"],
          groups: ["TestMainGroup"],
          icon: "mdi-account"
        },
        hidden: false
      },
      {
        path: "/administration/editUser/:userID",
        name: "Редакция на потребител",
        component: EditUser,
        meta: {
          title: "Редакция на потребител",
          actions: ["Администратор", "Редакция"],
          groups: ["TestMainGroup"],
          goBackTitle: "Обратно към потребители",
        },
        hidden: true
      },
      {
        path: "/administration/addUser",
        name: "Създаване на потребител",
        component: AddUser,
        meta: {
          title: "Създаване на потребител",
          actions: ["Администратор", "Създаване"],
          groups: ["TestMainGroup"],
          goBackTitle: "Обратно към потребители",
        },
        hidden: true
      },
      //Roles
      {
        path: "/administration/editUserRole/:userID",
        name: "Асоцииране на роли",
        component: EditUserRole,
        meta: {
          title: "Асоцииране на роли",
          actions: ["Администратор", "Редакция"],
          groups: ["TestMainGroup"],
          goBackTitle: "Обратно към роли",
        },
        hidden: true
      },
      //Groups
      {
        path: "/administration/listGroups",
        name: "Групи",
        component: GroupList,
        meta: {
          title: "Групи",
          actions: ["Администратор"],
          groups: ["TestMainGroup"],
          icon: "mdi-account-group"
        },
        hidden: false
      },
      {
        path: "/administration/editGroup/:groupID",
        name: "Редакция на група",
        component: EditGroup,
        meta: {
          title: "Редакция на група",
          actions: ["Администратор", "Редакция"],
          groups: ["TestMainGroup"],
          goBackTitle: "Обратно към групи",
        },
        hidden: true
      },
      {
        path: "/administration/addGroup",
        name: "Създаване на група",
        component: AddGroup,
        meta: {
          title: "Създаване на група",
          actions: ["Администратор", "Създаване"],
          groups: ["TestMainGroup"],
          goBackTitle: "Обратно към групи",
        },
        hidden: true
      },
      {
        path: "/administration/editUserGroup/:userID",
        name: "Асоцииране на група",
        component: EditUserGroup,
        meta: {
          title: "Асоцииране на група",
          actions: ["Администратор", "Редакция"],
          groups: ["TestMainGroup"],
          goBackTitle: "Обратно към потребители",
        },
        hidden: true
      },
      //Clients
    ]
}

export default administrationRoute;
