<template>
  <!--https://stackoverflow.com/questions/48035310/open-a-vuetify-dialog-from-a-component-template-in-vuejs-->
  <!--https://gist.github.com/eolant/ba0f8a5c9135d1a146e1db575276177d-->
  <v-layout row justify-center>
    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
    >
      <v-card class="scroll-y" style="height: 300px">
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="headline text-center"
          >{{ message }}<br />{{ question }}</v-card-text
        >

        <div style="display: flex; justify-content: center">
          <div style="display: inline">
            <v-btn class="successGradient" width="220px" @click="agree">
              <v-icon style="margin-right: 10px">mdi-account-plus</v-icon>
              <span>Направи</span>
            </v-btn>
          </div>
          <div style="display: inline">
            <v-btn class="successGradient" width="220px" @click="cancel">
              <v-icon style="margin-right: 10px">mdi-account-plus</v-icon>
              <span>Откажи</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: "gisConfirmModal",
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      message: null,
      question: null,
      options: {
        width: 600,
        zIndex: 200,
        color: "success",
      },
    };
  },

  computed: {
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.dialog = value;
        if (value === false) {
          this.cancel();
        }
      },
    },
  },

  methods: {
    showModal(title, message, question, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.question = question;
      this.options = Object.assign(this.options, options);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      this.dialog = false;
    },

    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
