<template>
  <div>
    <tiptap-vuetify :disabled="true" v-model="content" />
  </div>
</template>

<script>
import { TiptapVuetify } from "tiptap-vuetify";

export default {
  components: { TiptapVuetify },
  data: () => ({
    content: ""
  })
};
</script>

<style lang="scss"></style>
