<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    color="primary"
    mobile-breakpoint="960"
    app
    mini-variant-width="0"
    width="320"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-list-item two-line class="logo-border mb-10">
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular mt-2 mb-1">
          <!-- Logo row -->
          <v-row style="margin: 0px !important" @click="redirectToDashboard">
            <v-col md="2" class="d-none text-center">
              <span class="logo-mini">
                <img src="@/assets/logo.svg" alt="Aquagis" />
              </span>
            </v-col>
            <v-col md="12 text-center">
              <span class="logo-normal" style="white-space: normal">
                <img src="@/assets/logo.svg" alt="Aquagis" />
              </span>
            </v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list expand nav>
      <div />
      <template v-for="(item, i) in permissionRoutes">
        <template v-if="!item.hidden">
          <v-divider
            class="my-5"
            :key="`group1-${i}`"
            v-if="item.meta && item.meta.divider"
          />
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
          </base-item-group>
          <base-item v-else :key="`item-${i}`" :item="item" />
        </template>
        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>
      <div />
    </v-list>
    <div class="version">
      {{ (!isProduction ? "" : "") + version }}
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import config from "@/config";

export default {
  name: "CoreAppDrawer",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      version: config.version,
    };
  },
  methods: {
    redirectToDashboard() {
      this.$router.push({ path: `/dashboard` });
    },
  },
  computed: {
    ...mapState(["barColor", "barImage"]),
    ...mapGetters(["permissionRoutes", "username", "isProduction"]),
    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.$store.commit("app/SET_DRAWER", val);
      },
    },
  },
};
</script>

<style lang="sass">
#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
