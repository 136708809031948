import Layout from "@/layouts/Layout.vue";
import VersionsIframe from "@/views/versions/versions.vue";

let versionsRoute = {
  path: "/versions",
  component: Layout,
  name: "Версии",
  redirect: "/versions",
  hidden: true,
  meta: {
    actions: ["Администратор"],
    groups: ["TestMainGroup", "TestDummyGroup"],
  },
  children: [
    {
      path: "/versions/list",
      name: "Версии",
      component: VersionsIframe,
      meta: {
        title: "Версии",
        actions: ["Администратор"],
        groups: ["TestMainGroup", "TestDummyGroup"],
        icon: "client-128x128-transp.png",
        goBackTitle: "Обратно към aварии",
      },
      hidden: false
    },    
  ]
}

export default versionsRoute;
