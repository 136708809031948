import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);


const modulesFiles = require.context('./modules', true, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1').split("/")[0];
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {})

const store = new Vuex.Store({
  getters,
  modules: modules,
  plugins: [createPersistedState({
    paths: [
      "app",
      "user.name",
      "user.username",
    ]
  })]
})

export default store;
