import Layout from "@/layouts/Layout.vue";
import gisNomenclaturesMainList from "@/views/gis_nomenclatures/main_list.vue";
import gisNomenclaturesItemList from "@/views/gis_nomenclatures/item_list.vue";
import gisNomenclaturesPreview from "@/views/gis_nomenclatures/preview.vue";

let gisNomenclaturesRoute = {
  path: "/gis_nomenclatures",
  component: Layout,
  name: "Номенклатури",
  redirect: "/gis_nomenclatures",
  hidden: true,
  meta: {
    actions: ["Администратор"],
    groups: ["TestMainGroup", "TestDummyGroup"],
  },
  children: [
    {
      path: "/gis_nomenclatures/main_list",
      name: "Списък номенклатури",
      component: gisNomenclaturesMainList,
      meta: {
        title: "Номенклатури",
        actions: ["Администратор"],
        groups: ["TestMainGroup", "TestDummyGroup"],
        icon: "mdi-account-alert",
        goBackTitle: "Обратно към номенклатури",
      },
      hidden: false
    },
    {
      path: "/gis_nomenclatures/item_list/:id",
      name: "Списък с елементи на номенклатура",
      component: gisNomenclaturesItemList,
      meta: {
        title: "Номенклатура",
        actions: ["Администратор"],
        groups: ["TestMainGroup", "TestDummyGroup"],
        icon: "mdi-account-alert",
        goBackTitle: "Списък номенклатури",
      },
      hidden: true
    },
    {
      path: "/gis_nomenclatures/preview/:nomenclatureSelectedId/:itemNomenclatureId",
      name: "Преглед на номенклатури",
      component: gisNomenclaturesPreview,
      meta: {
        title: "Сигнали оператор",
        actions: ["Администратор"],
        groups: ["TestMainGroup", "TestDummyGroup"],
        icon: "mdi-account-alert",
        goBackTitle: "Обратно към списък с елементи на номенклатура",
      },
      hidden: true
    }
  ]
}

export default gisNomenclaturesRoute;
