<template>
  <base-material-filter style="border-top-right-radius: 30px;"
                        title="Търсене на елементи на номенклатура"
                        :hasFilters="hasActiveFilters"
                        v-on:do-filter="doFilter()"
                        v-on:remove-filter="clearFilters">
    <v-row @keypress.enter="doFilter" class="mx-1">
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Код"
                      v-model="filters.code"
                      height="30"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(1, 'name')">
        </v-text-field>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Име на български"
                      v-model="filters.nameBg"
                      height="30"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(2, 'description')">
        </v-text-field>
      </v-col>
      <!--<v-col lg="2" md="4" cols="6">
        <v-text-field label="Име на английски"
                      v-model="filters.nameEn"
                      height="30"
                      autocomplete="off"
                      clearable
                      @click:clear="clearIt(2, 'description')">
        </v-text-field>
      </v-col>-->

      <v-col lg="2" md="4" cols="6">
        <v-select label="Активна"
                  v-model="filters.isActive"
                  :items="properties.isActive"
                  item-value="key"
                  item-text="value"
                  clearable
                  @click:clear="clearIt(4, 'isActive')">
        </v-select>
      </v-col>
    </v-row>
  </base-material-filter>
</template>

<script>
  import { isEmptyObject } from "@/utils";

  const filtersModel = Object.freeze({
    code: null,
    nameBg: null,
    nameEn: null,
    isActive: null,
  });

  export default {
    name: "filterItemNomenclature",
    data() {
      return {
        properties: {
          isActive: [
            { key: null, value: "Всички" },
            { key: true, value: "Да" },
            { key: false, value: "Не" },
          ],
        },

        filters: Object.assign({}, filtersModel),

        hasActiveFilters: false,
      }
    },

    created() { },

    methods: {
      doFilter() {
        if (!isEmptyObject(this.filters) || (this.filters.needsReply == null)) {  //a simple patch for our private deals
          this.filters.page = 1;
          this.hasActiveFilters = true;
          this.$emit("doFilter");
          delete this.filters.page;
        } else {
          if (this.hasActiveFilters === true)
            this.$emit("doFilter");

          this.hasActiveFilters = false;
        }
      },
      clearFilters() {
        this.filters = Object.assign({}, filtersModel);

        this.hasActiveFilters = false;

        this.$emit("doFilter", true);
      },

      getFilters() {
        this.filters;
      },

      clearIt(item, propName) {
        this.filters[propName] = null;
      }
    }
  }
</script>
