import keycloak from "@/plugins/keycloak"
import Vue from 'vue'
import App from './App.vue'
// router
import router from './router/index'
import '@/permissions' // permission control for router

// store 
import store from './store'

// plugins
import VueLogger from 'vuejs-logger';
import VueTheMask from 'vue-the-mask';
import './plugins/base'
import './plugins/vee-validate'
import Snotify, { SnotifyPosition } from 'vue-snotify';
import './plugins/loading';

import { SVGLoader } from "@/components/";
Vue.component('s-v-g-loader', SVGLoader);

// theme
import 'vue-snotify/styles/material.css';

import vuetify from './plugins/vuetify'

const snotify_options = {
  toast: {
    position: SnotifyPosition.rightBottom,
    timeout: 20000
  }
}

Vue.use(VueTheMask);
Vue.use(Snotify, snotify_options);

const vuelogger_options = {
  isEnabled: true,
  logLevel: 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};

Vue.use(VueLogger, vuelogger_options);

import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css';

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

Vue.config.productionTip = false;

export default new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
