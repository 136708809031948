<template>
  <v-app>
    <core-app-bar v-model="expandOnHover" class="d-print-none logo-bar" />

    <core-app-drawer :expand-on-hover.sync="expandOnHover" class="d-print-none" />

    <core-app-view />

    <vue-snotify></vue-snotify>
  </v-app>
</template>

<script>
  import CoreAppBar from "./components/AppBar.vue";
  import CoreAppDrawer from "./components/Drawer.vue";
  import CoreAppView from "./components/View.vue";

  export default {
    name: "CoreLayout",
    components: {
      CoreAppBar,
      CoreAppDrawer,
      CoreAppView
    },
    data: () => ({
      expandOnHover: false
    })
  }
</script>
<style>
.logo-bar {
  background-color: #ffffff !important;
}
</style>