<template>
  <v-container
    class="error-page full-height pt-10"
    tag="section"
  >
    <v-row
      class="text-center mt-2"
    >
      <v-col cols="12" lg="4" offset-lg="4">
        <v-img  style="background-color: #263238;background-size: 120px;" 
          :src="require(`@/assets/logo.png`)"
        ></v-img>
      </v-col>
      <v-col cols="12">
        <h1>
          Грешка 404
        </h1>

        <div class="display-3 mb-5 mt-10">
          Страницата не е намерена
        </div>

        <v-btn
          color="primary"
          @click="goToAvailablePath"
        >
          <v-icon class="mr-2">mdi-reply</v-icon>
          Към {{routeToGo.name ? routeToGo.name : ''}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PagesError',
    data(){
      return {
        routeToGo: {
          name: '',
          path: ''
        }
      }
    },
    mounted(){
      this.getFirstAvailableRoute();
    },
    methods: {
      goToAvailablePath(){
        this.$router.push({ path: this.routeToGo.path })
      },
      getFirstAvailableRoute(){
        let route;
        for(let r of this.$router.options.routes){
          if(r.path !== '/' && r.path !== '/authentication' && r.path != '/*'){
            route = r;
            break;
          }
        }

        if(route.hidden){
          if(route.children){
            for(let r of route.children){
              if(!r.hidden){
                this.routeToGo = r;
              }
            }
          }
        } else {
          this.routeToGo = route;
        }
      }
    }
  }
</script>

<style lang="sass">
  .error-page
    h1
      font-size: 8rem
</style>
