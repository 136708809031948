import Layout from "@/layouts/Layout.vue";
import breakdownsList from "@/views/breakdowns/list.vue";
import breakdownsPreview from "@/views/breakdowns/preview.vue";
import breakdownsAdd from "@/views/breakdowns/add.vue";

let breakdownsRoute = {
  path: "/breakdowns",
  component: Layout,
  name: "Аварии",
  redirect: "/breakdowns",
  hidden: true,
  meta: {
    actions: ["Оператор"],
    groups: ["TestMainGroup"],
  },
  children: [
    {
      path: "/breakdowns/list",
      name: "Аварии",
      component: breakdownsList,
      meta: {
        title: "Аварии",
        actions: ["Оператор"],
        groups: ["TestMainGroup"],
        icon: "breakdownMenu1.png",
        goBackTitle: "Обратно към aварии",
      },
      hidden: false
    },
    {
      path: "/breakdowns/preview/:breakdownId",
      name: "Преглед на аварии",
      component: breakdownsPreview,
      meta: {
        title: "Аварии",
        actions: ["Оператор"],
        groups: ["TestMainGroup"],
        icon: "mdi-account-alert",
        goBackTitle: "Обратно към aварии",
      },
      hidden: true
    },
    {
      path: "/breakdowns/add",
      name: "Добавяне на авария",
      component: breakdownsAdd,
      meta: {
        title: "Аварии",
        actions: ["Оператор"],
        groups: ["TestMainGroup"],
        icon: "mdi-account-alert",
        goBackTitle: "Обратно към aварии",
      },
      hidden: true
    }
  ]
}

export default breakdownsRoute;
