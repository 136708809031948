import request from '@/utils/request';

export function getAllGroupsFromKeycloak() {//All groups available
  return request({
    url: '/api/Account/GetGroupsFromKeycloak',
    method: 'get',
  });
}

export function getGroupByIdFromKeycloak(groupID) {//Get group by id
  return request({
    url: '/api/Account/GetGroupByIdFromKeycloak?Id=' + groupID,
    method: 'get',
  });
}

export function getClientAssignedRolesByGroupIdFromKeycloak(groupID, clientID) {//Assigned roles to client
  return request({
    url: `/api/Account/GetClientAssignedRolesByGroupIdFromKeycloak?groupId=${groupID}&clientId=${clientID}`,
    method: 'get',
  });
}

export function getClientAvailableRolesByGroupIdFromKeycloak(groupID, clientID) {//All roles
  return request({
    url: `/api/Account/GetClientAvailableRolesByGroupIdFromKeycloak?groupId=${groupID}&clientId=${clientID}`,
    method: 'get',
  });
}

export function assingClientRolesToGroup(roles, groupID, clientID) {//Add role to group
  return request({
    url: `/api/Account/AssingClientRolesToGroup?groupId=${groupID}&clientId=${clientID}`,
    data: roles,
    method: 'post',
  });
}

export function removeClientRolesFromGroup(roles, groupID, clientID) {//Remove role from group
  return request({
    url: `/api/Account/RemoveClientRolesFromGroup?groupId=${groupID}&clientId=${clientID}`,
    data: roles,
    method: 'delete',
  });
}

export function getUserGroupsFromKeycloak(userID) {//Assigned in
  return request({
    url: `/api/Account/GetUserGroupsFromKeycloak?userId=` + userID,
    method: 'get',
  });
}

export function addUserToGroup(userID, groupID) {//assign user to group
  return request({
    url: `/api/Account/AddUserToGroup?userId=${userID}&groupId=${groupID}`,
    method: 'put',
  });
}

export function removeUserFromGroup(userID, groupID) {//remove user from group
  return request({
    url: `/api/Account/RemoveUserFromGroup?userId=${userID}&groupId=${groupID}`,
    method: 'delete',
  });
}

export function createGroupForKeycloak(group) {//Create group
  return request({
    url: `/api/Account/CreateGroupForKeycloak`,
    data: group,
    method: 'post',
  });
}

export function updateGroupForKeycloak(group) {//Update group name
  return request({
    url: `/api/Account/UpdateGroupForKeycloak`,
    data: group,
    method: 'put',
  });
}

export function deleteGroupFromKeycloak(groupID) {//Update group name
  return request({
    url: `/api/Account/DeleteGroupFromKeycloak?Id=${groupID}`,
    method: 'delete',
  });
}
