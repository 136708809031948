<template>
  <v-container
    id="breakdownsPreview"
    ref="breakdownsPreview"
    fluid
    tag="section"
    class="full-height"
  >
    <base-v-component
      heading="Преглед на авария"
      goBackText="Обратно към аварии"
      goBackTo="/breakdowns"
    />

    <div style="display: inline-block; text-align: right; width: 100%">
      <span style="color: #283593; font-size: 28px; margin-right: 10px">{{
        objBreakdown.workCardNo
      }}</span>
    </div>

    <v-row>
      <v-col cols="6">
        <v-card
          style="
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            border-bottom-left-radius: 30px;
          "
          class="v-card-profile"
          color="white"
        >
          <v-avatar
            style="
              border-top-right-radius: 20%;
              border-bottom-left-radius: 20%;
              border-bottom-right-radius: 20%;
              margin-left: 20px;
              margin-top: -40px;
            "
            left
            color="#263238"
            size="107"
          >
            <v-icon style="font-size: 400%; color: #ffffff"
              >mdi-water-off</v-icon
            >
          </v-avatar>
          <v-card-text>
            <h3>Описание на аварията</h3>
            <v-simple-table style="margin-top: 20px">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Авария No. :</td>
                    <td
                      v-if="objBreakdown.finished == true"
                      class="fw600 success--text"
                    >
                      {{ objBreakdown.workCardNo }} - отстранена
                    </td>
                    <td
                      v-if="objBreakdown.finished == false"
                      class="fw600 error--text"
                    >
                      {{ objBreakdown.workCardNo }} - изпратен екип
                    </td>
                  </tr>
                  <tr>
                    <td>Latitude кординати:</td>
                    <td class="fw600">{{ objBreakdown.latitude }}</td>
                  </tr>
                  <tr>
                    <td>Longitude кординати:</td>
                    <td class="fw600">{{ objBreakdown.longitude }}</td>
                  </tr>
                  <tr>
                    <td>Начална дата:</td>
                    <td class="fw600">
                      {{
                        formatDateTimeToLocalTime(objBreakdown.signalTimestamp)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Начало на планиран ремонт:</td>
                    <td class="fw600">
                      {{ formatDateTimeToLocalTime(objBreakdown.repairStart) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Край на планиран ремонт:</td>
                    <td class="fw600">
                      {{ formatDateTimeToLocalTime(objBreakdown.repairEnd) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Начало на планиранoто спиране на водата:</td>
                    <td class="fw600">
                      {{
                        formatDateTimeToLocalTime(objBreakdown.waterStopStart)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Край на планиранoто спиране на водата:</td>
                    <td class="fw600">
                      {{ formatDateTimeToLocalTime(objBreakdown.waterStopEnd) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Идентификатор от Visoft:</td>
                    <td class="fw600">
                      {{ objBreakdown.visoftId }}
                    </td>
                  </tr>
                  <tr>
                    <td>Идентификатор на тръбата:</td>
                    <td class="fw600">
                      {{ objBreakdown.osmId }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          style="
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            border-bottom-left-radius: 30px;
          "
          class="v-card-profile"
          color="white"
        >
          <v-avatar
            style="
              border-top-right-radius: 20%;
              border-bottom-left-radius: 20%;
              border-bottom-right-radius: 20%;
              margin-left: 20px;
              margin-top: -40px;
            "
            left
            color="#263238"
            size="107"
          >
            <v-icon style="font-size: 400%; color: #ffffff"
              >mdi-water-off</v-icon
            >
          </v-avatar>
          <v-card-text>
            <h3>Описание на аварията</h3>
            <v-simple-table style="margin-top: 20px">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>КЕВР категория 1:</td>
                    <td class="fw600">
                      {{ objBreakdown.kevrCategory1Display }}
                    </td>
                  </tr>
                  <tr>
                    <td>КЕВР категория 2:</td>
                    <td class="fw600">
                      {{ objBreakdown.kevrCategory2Display }}
                    </td>
                  </tr>
                  <tr>
                    <td>Област / Oбщина:</td>
                    <td class="fw600">
                      {{ objBreakdown.regionName }} /
                      {{ objBreakdown.municipalityName }}
                    </td>
                  </tr>
                  <tr>
                    <td>Град:</td>
                    <td class="fw600">{{ objBreakdown.townName }}</td>
                  </tr>
                  <tr>
                    <td>Улица:</td>
                    <td class="fw600">
                      {{ objBreakdown.streetName }},
                      {{ objBreakdown.streetDescription }}
                    </td>
                  </tr>
                  <tr>
                    <td>Приоритет:</td>
                    <td class="fw600">
                      {{
                        objBreakdown.priority != null
                          ? objBreakdown.priority.nameBg
                          : "-"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Засегнати потребители:</td>
                    <td class="fw600">
                      {{
                        objBreakdown.waterStopPeople != null
                          ? objBreakdown.waterStopPeople.toLocaleString()
                          : "-"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Брой сигнали за аварията:</td>
                    <td class="fw600">{{ selectedSignals.length }}</td>
                  </tr>
                  <tr>
                    <td>Създадена от:</td>
                    <td class="fw600">
                      {{ objBreakdown.createdBy }}
                    </td>
                  </tr>
                  <tr>
                    <td>Проверена:</td>
                    <td class="fw600">
                      {{ objBreakdown.checked ? 'Да' : 'Не' }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card style="border-top-right-radius: 30px" class="mx-4" cols="4">
      <base-map
        :isReadonly="false"
        :isBreakdown="true"
        :value="{ lat: objBreakdown.latitude, lng: objBreakdown.longitude }"
      ></base-map>
    </v-card>

    <base-material-card
      style="
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        justify-content: left;
      "
      class="v-card-profile"
    >
      <template v-slot:after-heading>
        <v-row>
          <v-col class="col-9">Текущи сигнали към авария</v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn
              class="successGradient"
              style="width: 200px"
              @click="dialog = true"
            >
              Свържи сигнал
            </v-btn>
          </v-col>
        </v-row>
        <!-- data table -->
        <v-data-table
          ref="table"
          :headers="breakdownsForSignalTableProps.headers"
          :items="breakdownsForSignalTableProps.data"
          class="elevation-2"
          disable-pagination
          hide-default-footer
          multi-sort
          :disable-sort="
            !breakdownsForSignalTableProps.data ||
            !breakdownsForSignalTableProps.data.length
          "
        >
          <template v-slot:body="{ items }">
            <tbody>
              <template v-if="items && items.length">
                <tr
                  v-for="(item, idx1) in items"
                  :key="`row_${idx1}`"
                  @click="setSignalSelected(item)"
                >
                  <template
                    v-for="(cell, idx) in breakdownsForSignalTableProps.headers"
                  >
                    <td
                      :key="'td_' + idx"
                      v-if="
                        cell.valueType !== 'button' &&
                        cell.valueType !== 'status' &&
                        cell.valueType !== 'textBalance' &&
                        cell.valueType !== 'checkbox'
                      "
                      :class="cell.class"
                      :style="cell.style"
                    >
                      {{ getValue(item, cell) }}
                    </td>

                    <td
                      :key="'td_' + idx"
                      v-if="cell.valueType === 'textBalance'"
                      :class="cell.class"
                      :style="cell.style"
                    >
                      <span class="blue--text darken-4">
                        {{ getValue(item, cell) }}
                      </span>
                    </td>

                    <td
                      :key="'td_' + idx"
                      v-if="cell.valueType === 'status'"
                      :class="
                        cell.class +
                        ' ' +
                        (cell.active === item[cell.displayValue || cell.value]
                          ? cell.activeClass
                          : cell.notActive ===
                            item[cell.displayValue || cell.value]
                          ? cell.notActiveClass
                          : '')
                      "
                      :style="cell.style"
                    >
                      <template v-if="cell.statusIcons">
                        <v-icon
                          class="success--text"
                          v-if="
                            item[cell.displayValue || cell.value] ===
                            cell.active
                          "
                          >mdi-check</v-icon
                        >
                        <v-icon
                          class="error--text"
                          v-if="
                            item[cell.displayValue || cell.value] ===
                            cell.notActive
                          "
                          >mdi-close</v-icon
                        >
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            item[cell.displayValue || cell.value] == cell.active
                          "
                        >
                          {{ cell.activeText }}
                        </template>
                        <template
                          v-else-if="
                            item[cell.displayValue || cell.value] ==
                            cell.notActive
                          "
                        >
                          {{ cell.notActiveText }}
                        </template>
                        <template v-else>
                          {{ getValue(item, cell) }}
                        </template>
                      </template>
                    </td>

                    <td
                      :key="'td_' + idx"
                      v-if="cell.valueType === 'checkbox'"
                      :class="cell.class"
                      :style="cell.style"
                    >
                      <v-checkbox
                        v-model="item[cell.displayValue || cell.value]"
                        color="primary"
                        :value="cell.checked"
                        :false-value="cell.unchecked"
                        hide-details
                        class="mt-0"
                        @change="changeCheckboxValue(item)"
                      ></v-checkbox>
                    </td>

                    <td
                      :key="'td_' + idx"
                      v-if="cell.valueType === 'button'"
                      :style="cell.style"
                    >
                      <template v-for="(button, bIdx) in cell.buttons">
                        <v-btn
                          v-if="checkButton(button, item)"
                          :key="'td_button_' + bIdx"
                          :block="button.block"
                          :style="button.style"
                          :title="button.title"
                          @click="doAction(button.click, item)"
                          :class="button.class"
                          :id="`id-` + item.id"
                        >
                          <v-icon
                            :class="button.label ? 'mr-2' : 'mr-0'"
                            v-if="
                              button.icon && button.icon.length ? true : false
                            "
                            >{{ button.icon }}</v-icon
                          >
                          {{ button.label }}
                        </v-btn>
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td
                    :colspan="breakdownsForSignalTableProps.headers.length"
                    class="text-center px-8"
                  >
                    <v-row>
                      <v-col cols="12" class="pa-7">
                        <h4
                          class="
                            display-1
                            font-weight-medium
                            blue-grey--text
                            text--darken-2
                          "
                        >
                          Няма намерени резултати
                        </h4>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
        <!-- paging -->
        <div style="display: flex; justify-content: space-between">
          <div style="display: inline; padding-top: 20px; width: 150px"></div>
          <div style="display: inline">
            <v-pagination
              v-if="breakdownsForSignalTableProps.hasPaging"
              v-model="breakdownsForSignalTableProps.pagination.page"
              :color="breakdownsForSignalTableProps.props.color"
              :length="
                Math.ceil(
                  breakdownsForSignalTableProps.pagination.total /
                    breakdownsForSignalTableProps.pagination.itemsPerPage
                )
              "
              :total-visible="10"
              @input="changedPaginationBreakdownSignals()"
            ></v-pagination>
          </div>
          <div style="display: inline; padding-top: 30px">
            <span
              >Общо:
              <strong>{{
                breakdownsForSignalTableProps.pagination.total
              }}</strong>
              записа.</span
            >
          </div>
        </div>
      </template>
    </base-material-card>

    <!-- dialog section -->
    <v-dialog
      eager
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar dense color="success">
          <v-toolbar-title class="ml-5" style="color: white">
            Добавяне на сигнал към авария
            <b>№: {{ objBreakdown.workCardNo }}.</b>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                x-small
                @click="dialog = false"
                class="mr-5 successGradient"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Затвори</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="height: 100%">
          <!-- filter component -->
          <filter-oper-signals
            ref="signalsOperFilter"
            @doFilter="getData()"
            @search-by-breakdown-Id="searchByBreakdownId()"
          />
          <!-- data table -->
          <v-data-table
            ref="table"
            :headers="tableProps.headers"
            :items="tableProps.data"
            class="elevation-2"
            disable-pagination
            hide-default-footer
            multi-sort
            :disable-sort="!tableProps.data || !tableProps.data.length"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <template v-if="items && items.length">
                  <tr
                    v-for="(item, idx) in items"
                    :key="`row_${idx}`"
                    @click="setSignalSelected(item)"
                  >
                    <template v-for="(cell, idx) in tableProps.headers">
                      <td
                        :key="'td_' + idx"
                        v-if="
                          cell.valueType !== 'button' &&
                          cell.valueType !== 'status' &&
                          cell.valueType !== 'textBalance' &&
                          cell.valueType !== 'checkbox'
                        "
                        :class="cell.class"
                        :style="cell.style"
                      >
                        {{ getValue(item, cell) }}
                      </td>

                      <td
                        :key="'td_' + idx"
                        v-if="cell.valueType === 'textBalance'"
                        :class="cell.class"
                        :style="cell.style"
                      >
                        <span class="blue--text darken-4">
                          {{ getValue(item, cell) }}
                        </span>
                      </td>

                      <td
                        :key="'td_' + idx"
                        v-if="cell.valueType === 'status'"
                        :class="
                          cell.class +
                          ' ' +
                          (cell.active === item[cell.displayValue || cell.value]
                            ? cell.activeClass
                            : cell.notActive ===
                              item[cell.displayValue || cell.value]
                            ? cell.notActiveClass
                            : '')
                        "
                        :style="cell.style"
                      >
                        <template v-if="cell.statusIcons">
                          <v-icon
                            class="success--text"
                            v-if="
                              item[cell.displayValue || cell.value] ===
                              cell.active
                            "
                            >mdi-check</v-icon
                          >
                          <v-icon
                            class="error--text"
                            v-if="
                              item[cell.displayValue || cell.value] ===
                              cell.notActive
                            "
                            >mdi-close</v-icon
                          >
                        </template>
                        <template v-else>
                          <template
                            v-if="
                              item[cell.displayValue || cell.value] ==
                              cell.active
                            "
                          >
                            {{ cell.activeText }}
                          </template>
                          <template
                            v-else-if="
                              item[cell.displayValue || cell.value] ==
                              cell.notActive
                            "
                          >
                            {{ cell.notActiveText }}
                          </template>
                          <template v-else>
                            {{ getValue(item, cell) }}
                          </template>
                        </template>
                      </td>

                      <td
                        :key="'td_' + idx"
                        v-if="cell.valueType === 'checkbox'"
                        :class="cell.class"
                        :style="cell.style"
                      >
                        <v-checkbox
                          v-model="item[cell.displayValue || cell.value]"
                          color="primary"
                          :value="cell.checked"
                          :false-value="cell.unchecked"
                          hide-details
                          class="mt-0"
                          @change="changeCheckboxValue(item)"
                        ></v-checkbox>
                      </td>

                      <td
                        :key="'td_' + idx"
                        v-if="cell.valueType === 'button'"
                        :class="cell.class"
                        :style="cell.style"
                      >
                        <template v-for="(button, bIdx) in cell.buttons">
                          <v-btn
                            :style="button.style"
                            v-if="checkButton(button, item)"
                            :key="'td_button_' + bIdx"
                            :block="button.block"
                            :title="button.title"
                            @click="doAction(button.click, item)"
                            :class="button.class"
                            :id="`id-` + item.id"
                          >
                            <v-icon
                              :class="button.label ? 'mr-2' : 'mr-0'"
                              v-if="
                                button.icon && button.icon.length ? true : false
                              "
                              >{{ button.icon }}</v-icon
                            >
                            {{ button.label }}
                          </v-btn>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td
                      :colspan="tableProps.headers.length"
                      class="text-center px-8"
                    >
                      <v-row>
                        <v-col cols="12" class="pa-7">
                          <h4
                            class="
                              display-1
                              font-weight-medium
                              blue-grey--text
                              text--darken-2
                            "
                          >
                            Няма намерени резултати
                          </h4>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-data-table>
          <!-- paging -->
          <div style="display: flex; justify-content: space-between">
            <div style="display: inline; padding-top: 20px; width: 150px"></div>
            <div style="display: inline">
              <v-pagination
                v-if="tableProps.hasPaging"
                v-model="tableProps.pagination.page"
                :color="tableProps.props.color"
                :length="
                  Math.ceil(
                    tableProps.pagination.total /
                      tableProps.pagination.itemsPerPage
                  )
                "
                :total-visible="10"
                @input="changedPagination()"
              ></v-pagination>
            </div>
            <div style="display: inline; padding-top: 30px">
              <span
                >Общо:
                <strong>{{ tableProps.pagination.total }}</strong> записа.</span
              >
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { getSignalList } from "@/api/signals";
import { getUserAsKeyValuePairList } from "@/api/users";
import {
  getBreakdownById,
  addSignalToBreakdown,
  removeSignalFromBreakdown,
} from "@/api/breakdowns";
import { filterOperSignals } from "@/components";
import {
  formatDateTime,
  formatDateTimeToLocalTime,
  formatDateTimeSeconds,
  getArrayField,
  ISODateString,
  roundNumber,
} from "@/utils";

import moment from "moment";

export default {
  name: "breakdownsPreview",
  components: { filterOperSignals },
  data() {
    return {
      tableProps: {
        headers: [
          { text: "Сигнал No.", value: "id", valueType: "object" },
          { text: "Приоритет", value: "priority.nameBg", valueType: "object" },
          { text: "Статус", value: "status.nameBg", valueType: "object" },
          {
            text: "Създаден от",
            value: "createdBySubmitter.name",
            valueType: "object",
          },
          { text: "Създаден на", value: "createdUtc", valueType: "dateTime" },
          {
            text: "Дата на последен отговор на",
            value: "lastResponseUtc",
            valueType: "dateTime",
          },
          {
            text: "Дата на затваряне",
            value: "closedUtc",
            valueType: "dateTime",
          },
          {
            text: "Прикачен към авария",
            value: "breakdown.workCardNo",
            valueType: "object",
          },
          {
            sortable: false,
            text: "",
            value: "actions",
            valueType: "button",
            class: "",
            style: "width: 100px; padding: 10px;",
            buttons: [
              {
                label: "Прикачи сигнал",
                title: "",
                class: "successGradient",
                style: "width: 120px; height: 30px;",
                click: "addSignalToBreakdown",
                id: "signalToBreakdown",
                if: { item: "breakdownId", value: "!=null" },
              },
            ],
          },
          {
            sortable: false,
            text: "",
            value: "actions",
            valueType: "button",
            class: "",
            style: "width: 100px; padding: 10px;",
            buttons: [
              {
                label: "Премахни връзката",
                title: "",
                class: "errorGradient",
                style: "width: 150px; height: 30px;",
                click: "removeSignalFromBreakdown",
                id: "signalToBreakdown",
                if: { item: "breakdownId", value: null },
              },
            ],
          },
          {
            sortable: false,
            text: "",
            value: "actions",
            valueType: "button",
            class: "text-right cols cols-2",
            style: "width: 150px; padding: 10px;",
            buttons: [
              {
                label: "",
                title: "Преглед",
                class: "successGradient",
                icon: "mdi-text-box-search-outline",
                style: "width: 70px; height: 30px;",
                click: "previewSignalsOper",
                id: "signalsOperDetailsPreview",
              },
            ],
          },
        ],
        data: [],
        filters: {},
        hasPaging: true,
        noms: {
          statuses: [],
          priorities: [],
          users: [],
        },
        pagination: {
          itemsPerPage: 10,
          page: 1,
          perPageOptions: [5, 10, 25, 50, 100],
          total: 0,
        },

        props: {
          id: {
            type: String,
            default: "",
          },
          printAll: false,
          color: "primary",
        },
        sortColumn: [],
        sortDescending: [],
      },
      breakdownsForSignalTableProps: {
        headers: [
          { text: "Сигнал No.", value: "id", valueType: "object" },
          { text: "Приоритет", value: "priority.nameBg", valueType: "object" },
          { text: "Статус", value: "status.nameBg", valueType: "object" },
          {
            text: "Създаден от",
            value: "createdBySubmitter.name",
            valueType: "object",
          },
          { text: "Създаден на", value: "createdUtc", valueType: "dateTime" },
          {
            text: "Последен отговор на",
            value: "lastResponseUtc",
            valueType: "dateTime",
          },
          {
            text: "Дата на затваряне",
            value: "closedUtc",
            valueType: "dateTime",
          },
          {
            text: "Прикачен към авария",
            value: "breakdown.workCardNo",
            valueType: "object",
          },
          {
            sortable: false,
            text: "",
            value: "actions",
            valueType: "button",
            class: "",
            style: "width:100px; padding: 10px;",
            buttons: [
              {
                label: "Прикачи сигнал",
                title: "",
                class: "successGradient",
                style: "width: 100px; height: 30px;",
                click: "addSignalToBreakdown",
                id: "signalToBreakdown",
                if: { item: "breakdownId", value: "!=null" },
              },
            ],
          },
          {
            sortable: false,
            text: "",
            value: "actions",
            valueType: "button",
            class: "",
            style: "width: 100px; padding: 10px;",
            buttons: [
              {
                label: "Премахни връзката",
                title: "",
                class: "errorGradient",
                style: "width: 150px; height: 30px;",
                click: "removeSignalFromBreakdown",
                id: "signalToBreakdown",
                if: { item: "breakdownId", value: null },
              },
            ],
          },
          {
            sortable: false,
            text: "",
            value: "actions",
            valueType: "button",
            class: "text-right cols cols-2",
            style: "width:90px; padding: 10px;",
            buttons: [
              {
                label: "",
                title: "Преглед",
                class: "successGradient",
                icon: "mdi-text-box-search-outline",
                style: "width: 70px; height: 30px;",
                click: "previewSignalsOper",
                id: "signalsOperDetailsPreview",
              },
            ],
          },
        ],
        data: [],
        filters: {},
        hasPaging: true,
        noms: {
          statuses: [],
          priorities: [],
          users: [],
        },
        pagination: {
          itemsPerPage: 10,
          page: 1,
          perPageOptions: [5, 10, 25, 50, 100],
          total: 0,
        },

        props: {
          id: {
            type: String,
            default: "",
          },
          printAll: false,
          color: "primary",
        },
        sortColumn: [],
        sortDescending: [],
      },

      data: [],
      selectedSignals: [],
      allSignals: [],
      objBreakdown: {},
      dialog: false,
      users: [],
      latitude: 0.0,
      longitude: 0.0,
    };
  },

  created() {},

  mounted() {
    this.getUser();

    this.waitLodingNoms();

    this.objBreakdown.id = this.$route.params.breakdownId;

    this.getBreakdownById(this.objBreakdown.id);
    this.getData();
    this.getSignalsForBreakdown();
  },

  methods: {
    formatDateTime: formatDateTime,
    ISODateString: ISODateString,
    formatDateTimeToLocalTime: formatDateTimeToLocalTime,

    getBreakdownById(breakdownId) {
      getBreakdownById(breakdownId).then((data) => {
        this.objBreakdown = data;

        this.latitude = this.objBreakdown.latitude + 0.0;
        this.longitude = this.objBreakdown.longitude + 0.0;

        this.objBreakdown.kevrCategory1Display =
          this.objBreakdown.kevrCategory1Navigation.code +
          " - " +
          this.objBreakdown.kevrCategory1Navigation.nameBg;
        this.objBreakdown.kevrCategory2Display =
          this.objBreakdown.kevrCategory2Navigation.code +
          " - " +
          this.objBreakdown.kevrCategory2Navigation.nameBg;

        this.$forceUpdate();
      });
    },
    changedPagination() {
      this.getData();
    },

    changedPaginationBreakdownSignals() {
      this.getSignalsForBreakdown();
    },

    getData() {
      this.tableProps.filters = this.$refs.signalsOperFilter.filters;
      this.tableProps.filters.statusId = 1; // set it to be signal no matter what
      this.tableProps.filters.breakdownId = null;
      this.tableProps.filters.breakdownIsNull = true; // show non attached signals only
      this.getSignals(this.tableProps.filters);
    },

    getSignalsForBreakdown() {
      this.breakdownsForSignalTableProps.filters =
        this.$refs.signalsOperFilter.filters;
      this.breakdownsForSignalTableProps.filters.breakdownId =
        this.$route.params.breakdownId; // show only curren breakdown signals in this grid
      this.breakdownsForSignalTableProps.filters.breakdownIsNull = false; // dont show non attached signals

      let query = Object.assign({}, this.breakdownsForSignalTableProps.filters);
      query.userId = this.currentClient;

      if (!query.page)
        query.page = this.breakdownsForSignalTableProps.pagination.page;
      query.itemsPerPage =
        this.breakdownsForSignalTableProps.pagination.itemsPerPage;
      query.sortBy = this.breakdownsForSignalTableProps.sortColumn;
      query.sortDesc = this.breakdownsForSignalTableProps.sortDescending;

      getSignalList(query).then((data) => {
        data.items.forEach((val) => {
          val.createdBySubmitterId = this.getUserName(
            this.users,
            val.createdBySubmitterId
          );
        });

        this.breakdownsForSignalTableProps.data = data.items;
        this.breakdownsForSignalTableProps.pagination.total = data.totalCount;
        this.breakdownsForSignalTableProps.pagination.page = query.page;
        this.$forceUpdate();
      });
    },

    getSignals(tablePropsFilters) {
      let query = Object.assign({}, tablePropsFilters);
      query.userId = this.currentClient;

      if (!query.page) query.page = this.tableProps.pagination.page;
      query.itemsPerPage = this.tableProps.pagination.itemsPerPage;
      query.sortBy = this.tableProps.sortColumn;
      query.sortDesc = this.tableProps.sortDescending;

      getSignalList(query).then((data) => {
        data.items.forEach((val) => {
          val.createdBySubmitterId = this.getUserName(
            this.users,
            val.createdBySubmitterId
          );
        });

        this.tableProps.data = data.items;

        this.tableProps.pagination.total = data.totalCount;
        this.tableProps.pagination.page = query.page;
        this.$forceUpdate();
      });
    },

    getUser() {
      getUserAsKeyValuePairList().then((data) => {
        this.users = data;
      });
    },

    getUserName(users, userId) {
      users.forEach((val) => {
        if (val.key == userId) return val.value;
      });

      return "-";
    },
    getValue(item, options) {
      let template = "";
      let keys = [];
      let props = [];
      let prop = options.displayValue || options.value;
      let result;
      let itemData;

      if (options.value == "needsReply") {
        return item.needsReply == false ? "Не" : "Да";
      } else if (options.value == "attachmentId") {
        // return this.tableProps.noms.needsReply == 0 ? "Не" : "Да";
      }

      switch (options.valueType) {
        case "decimal":
          return roundNumber(item[prop || options.value], 16, 16, 8);
        case "text":
          return item[prop] || item[prop] === 0 ? item[prop] : " — ";
        case "textGL":
          return item[prop] || "GL";
        case "textBalance":
          // result = (item[options.value] + '').split('.')[1];
          // if(result && result.length <= 1 || (item[options.value] + '').length === 1){
          return roundNumber(item[prop || options.value], 2);
        // }
        // return item[options.value];
        case "price":
          return roundNumber(item[prop || options.value], 2);
        case "priceAccounting":
          var num = item[prop || options.value];
          if (typeof num === "number") num = num + "";

          var value = Number(num);

          var res = num.split(".");
          if (res.length == 1 || res[1].length < 3)
            value = roundNumber(value, 2);

          return value;
        case "array":
          // eslint-disable-next-line no-case-declarations
          let val = getArrayField(item, prop, options.comma);
          if (val && val !== "null") {
            return val;
          }
          return "";
        //return val != null ? val : '';
        case "date":
          if (item[prop]) {
            // eslint-disable-next-line no-case-declarations
            let d = moment();

            if (
              (typeof item[prop] === "number" &&
                item[prop].toString().length === 8) ||
              (typeof item[prop] === "string" && item[prop].length === 8)
            ) {
              d = moment(item[prop], "DDMMYYYY");
              if (!d.isValid()) d = moment(item[prop], "YYYYMMDD");
            } else if (
              (typeof item[prop] === "number" &&
                item[prop].toString().length === 14) ||
              (typeof item[prop] === "string" && item[prop].length === 14)
            ) {
              d = moment(item[prop], "YYYYMMDDHHmmss");
            } else {
              d = moment(item[prop]);
            }
            if (d.isValid()) {
              return ISODateString(d.toISOString());
            }
          }

          return "—";
        case "dateTime":
          if (item[prop]) {
            // eslint-disable-next-line no-case-declarations
            let d = moment(item[prop]);
            if (d.isValid()) {
              return formatDateTime(item[prop]);
            }
          }

          return "—";
        case "dateTimeSeconds":
          if (item[prop]) {
            // eslint-disable-next-line no-case-declarations
            let d = moment(item[prop]);
            if (d.isValid()) {
              return formatDateTimeSeconds(item[prop]);
            }
          }

          return "—";
        case "firstElement":
          props = prop.split(".");
          return item[props[0]] ? item[props[0]][0][props[1]] : "";
        case "firstElementObject":
          result = "";
          keys = prop.split(".");
          var firstChildren = item[keys[0]][0];
          keys.shift();
          if (firstChildren) {
            if (firstChildren[keys[0]]) {
              result = keys.reduce((a, v) => a[v], firstChildren);
            }
          }
          return result ? result : "";
        case "object":
          keys = prop.split(".");
          // eslint-disable-next-line no-case-declarations
          result = "";
          try {
            if (item[keys[0]]) {
              result = keys.reduce((a, v) => a[v], item);
            }
            return result ? result : " — ";
          } catch (e) {
            return " — ";
          }
        case "objectDate":
          keys = prop.split(".");

          // eslint-disable-next-line no-case-declarations
          let date = keys.reduce((a, v) => a[v], item);

          if (date) {
            // eslint-disable-next-line no-case-declarations
            let d;
            if (typeof date === "number" && date.toString().length === 8) {
              d = moment(date, "YYYYMMDD");
            } else {
              d = moment(date);
            }

            if (d.isValid()) {
              return ISODateString(d.toISOString());
            }
          }

          return " — ";
        case "objectDateTime":
          keys = prop.split(".");

          // eslint-disable-next-line no-case-declarations
          let dateTimeObject = keys.reduce(
            (a, v) => a[v] != null && a[v],
            item
          );

          if (dateTimeObject) {
            return formatDateTime(dateTimeObject);
          }

          return " — ";
        case "objectPrice":
          keys = prop.split(".");
          // eslint-disable-next-line no-case-declarations
          result = "";
          try {
            if (item[keys[0]]) {
              result = keys.reduce((a, v) => a[v], item);
            }
            return result ? roundNumber(result, 2) : " — ";
          } catch (e) {
            return " — ";
          }
        case "twoObjectsPrice":
          result = "";
          try {
            for (let obj of prop) {
              keys = obj.split(".");
              if (item[keys[0]]) {
                if (result.length) {
                  let val = keys.reduce((a, v) => a[v], item);
                  result += val ? " " + val : "";
                } else {
                  result += roundNumber(
                    keys.reduce((a, v) => a[v], item),
                    2
                  );
                }
              }
            }

            return result ? result : " — ";
          } catch (e) {
            return " — ";
          }
        case "valueOrValue":
          keys = prop.split(".");

          try {
            if (item[keys[0]]) {
              return item[keys[0]];
            }

            return item[keys[1]] || " — ";
          } catch (e) {
            return " — ";
          }
        case "valueOrValueObject":
          keys = prop[0].split(".");
          try {
            result = "";
            if (item[keys[0]]) {
              result = keys.reduce((a, v) => a[v], item);
            }

            if (result) {
              return result;
            }

            // checking next value
            keys = prop[1].split(".");

            result = "";
            if (item[keys[0]]) {
              result = keys.reduce((a, v) => a[v], item);
            }

            return result ? result : " — ";
          } catch (e) {
            return " — ";
          }
        case "valueOrValueArray":
          result = "";
          for (let i = 0; i < prop.length; i++) {
            if (prop[i].includes(".")) {
              keys = prop[i].split(".");
            } else {
              keys = [prop[i]];
            }

            for (let k = 0; k < keys.length; k++) {
              if (item[keys[k]]) {
                if (result.length) result += options.separator || " ";
                result += item[keys[k]];
              }
            }
          }
          return result;
        case "button":
          for (let i = 0; i < options.buttons.length; i++) {
            template += ``;
          }
          return template;
        case "sort":
          itemData = Object.assign([], item[options["sortBy"]]);

          if (options["sortType"] === "date") {
            itemData.sort(function (x, y) {
              if (options["sortDesc"]) {
                return (
                  new Date(y[options["sortValue"]]).getTime() -
                  new Date(x[options["sortValue"]]).getTime()
                );
              } else {
                return (
                  new Date(x[options["sortValue"]]).getTime() -
                  new Date(y[options["sortValue"]]).getTime()
                );
              }
            });
          }

          if (prop.includes(".")) {
            keys = prop.split(".");
            result = "";
            if (itemData[0][keys[0]]) {
              result = keys.reduce((a, v) => a[v], itemData[0]);
            }
            return result ? result : " — ";
          }

          return itemData[0][prop];
        case "status":
          if (item[prop] != null) {
            return item[prop].toString() || " - ";
          }
          return " - ";
        case "translateValue":
          result = " - ";
          for (let obj of options.translateValues) {
            if (obj.value === item[prop]) {
              result = obj.label;
            }
          }
          return result;
      }
    },

    checkButton(button, item) {
      let ifValue = true;
      if (button.if !== undefined && button.if.value == "!=null") {
        if (item[button.if.item] != null) {
          return false;
        }
      }
      return (
        (button.if
          ? button.if.type
            ? button.if.type === "equal"
              ? item[button.if.item] === button.if.value
              : false
            : item[button.if.item] !== button.if.value
          : true) && ifValue
      );
    },

    doAction(action, data) {
      if (action == "previewSignalsOper") this.previewSignalsOper(data.id);
      if (action == "addSignalToBreakdown") this.addSignalToBreakdown(data.id);
      if (action == "removeSignalFromBreakdown")
        this.removeSignalFromBreakDown(data.id);
    },

    previewSignalsOper(signalId) {
      if (signalId > 0) {
        this.$router.push({ path: `/signals_oper/preview/${signalId}` });
        this.$forceUpdate();
      }
    },

    addSignalToBreakdown(signalId) {
      addSignalToBreakdown(this.objBreakdown.id, signalId).then(() => {
        this.$snotify.success("Успешно добавен сигнал!");
        this.getData();
        this.getSignalsForBreakdown();
        this.dialog = false;
      });
    },

    removeSignalFromBreakDown(signalId) {
      removeSignalFromBreakdown(signalId).then(() => {
        this.$snotify.success("Успешно премахнат сигнал!");
        this.getData();
        this.getSignalsForBreakdown();
      });
    },

    setSignalSelected(signalSelected) {
      this.signalSelectedId = signalSelected.id;
      this.signalSelectedUserId = signalSelected.createdBySubmitterId;
    },
    searchByBreakdownId() {
      this.isSearchedByBreakdown = true;
      this.tableProps.filters.breakdownId = this.$route.params.breakdownId; // set breakdown id before posting form so it can filter out all signals for the current breakdown
      this.getData();
    },

    async waitLodingNoms() {
      while (this.users.length == 0) {
        await new Promise((r) => setTimeout(r, 1000));
      }
    },
  },
};
</script>
// custom style because of data table in base-material-card which gives strech to buttons and columns with this class
// so we set padding to zero
<style scoped>
.v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab) {
  padding: 0 !important;
}
</style>
