<template>
  <v-container id="editUserGroup"
               fluid
               tag="section"
               class="full-height">

    <base-v-component goBackText="Обратно към потребители"
                      goBackTo="/administration/listUsers" />

    <base-material-card hover-reveal
                        color="primary"
                        icon="mdi-account-group"
                        inline
                        class="px-5 py-5 mt-15"
                        style="border-top-right-radius: 30px; border-bottom-right-radius: 30px; border-bottom-left-radius: 30px;">
      <h3>Потребител: {{getUserInfo}}</h3><br />
    </base-material-card>

    <v-expansion-panels focusable v-model="panel">
      <v-expansion-panel disabled style="background-color: #263238;">
        <v-expansion-panel-header>
          <v-row>
            <v-col cols="6">
              <h3 style="color: white !important">Групи</h3>
            </v-col>
            <v-col cols="6">
              <h3 style="color: white !important">Членство в групи</h3>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
      </v-expansion-panel>
      <v-expansion-panel readonly>
        <v-expansion-panel-header expand-icon="">
          <v-row class="justify-center" wrap>

            <!--All available Groups-->
            <v-col cols="6">
              <v-card class="v-card-profile" color="white">
                <v-card-text>
                  <b>Налични</b>
                  <v-list dense>
                    <v-list-item-group v-model="allGroups"
                                       color="primary"
                                       multiple>
                      <v-list-item v-for="(group, i) in groupsDB"
                                   :key="i"
                                   :value="group">
                        <v-list-item-content>
                          <v-list-item-title v-text="group.name"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="unactiveGradient" style="font-weight: bold;"
                         x-small
                         @click="addUserToGroup">
                    Добави<v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>

            <!--All Associated Groups-->
            <v-col cols="6">
              <v-card class="v-card-profile" color="white">
                <v-card-title class="text-h5">
                  <b>Избрани</b>
                </v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item-group v-model="assignedGroups"
                                       color="primary"
                                       multiple>
                      <v-list-item v-for="(group, i) in assignedGroupsDB"
                                   :key="i"
                                   :value="group">
                        <v-list-item-content>
                          <v-list-item-title v-text="group.name"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="unactiveGradient" style="font-weight: bold;"
                         x-small
                         @click="removeUserFromGroup">
                    <v-icon>mdi-chevron-left</v-icon>Премахни
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>

          </v-row>
        </v-expansion-panel-header>
      </v-expansion-panel>
    </v-expansion-panels>

  </v-container>
</template>

<script>

  import { getAllGroupsFromKeycloak, addUserToGroup, removeUserFromGroup, getUserGroupsFromKeycloak } from "@/api/groups"
  import { getUserInfoKeycloak } from "@/api/users";

  export default {
    name: "editUserGroup",
    components: {
    },
    data() {
      return {
        panel: [1],

        groupsDB: [],
        assignedGroupsDB: [],

        currentUser: {},

        allGroups: [],
        assignedGroups: []
      }
    },
    watch: {},
    computed: {
      getUserInfo() {
        return `${this.currentUser.firstName} ${this.currentUser.lastName} (${this.currentUser.username})`;
      }
    },
    mounted() {
      let vue = this;

      vue.getData();
      getUserInfoKeycloak(vue.$route.params.userID).then((data) => { vue.currentUser = data; });
    },
    created() { },
    methods: {
      getData() {
        let vue = this;

        getAllGroupsFromKeycloak().then((groups) => {
          vue.groupsDB = groups;

          getUserGroupsFromKeycloak(vue.$route.params.userID).then((data) => {
            vue.assignedGroupsDB = data;

            vue.assignedGroupsDB.forEach(g => {
              let index = vue.groupsDB.findIndex(x => x.id === g.id);
              if (index !== -1) {
                vue.groupsDB.splice(index, 1);
              }
            });
          });
        });
      },
      addUserToGroup() {
        let vue = this;

        if (vue.allGroups.length > 0) {
          vue.allGroups.forEach(s => {
            addUserToGroup(vue.$route.params.userID, s.id).then((data) => { this.notification(data); });

            let index = vue.groupsDB.findIndex(x => x.id === s.id);
            vue.assignedGroupsDB.push(vue.groupsDB.splice(index, 1)[0]);
          });

          vue.allGroups = [];
        }
      },
      removeUserFromGroup() {
        let vue = this;

        if (vue.assignedGroups.length > 0) {
          vue.assignedGroups.forEach(a => {
            removeUserFromGroup(vue.$route.params.userID, a.id).then((data) => { this.notification(data); });

            let index = vue.assignedGroupsDB.findIndex(x => x.id === a.id);
            vue.groupsDB.push(vue.assignedGroupsDB.splice(index, 1)[0]);
          });

          vue.assignedGroups = [];
        }
      },
      notification(data) {
        if (data.includes('(0)')) {

          //Notification for failed operation
          this.$snotify.error(data.substring(4));

        } else if (data.includes('({0})')) {

          //Notification for successfull operation
          this.$snotify.success(data.substring(6));
        }
      }
    }
  };
</script>
