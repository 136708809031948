<template>
  <v-container id="waterMeterAdd"
               ref="waterMeterAdd"
               fluid
               tag="section"
               class="full-height">
    <base-v-component heading="Добавяне на водомер"
                      goBackText="Обратно към водомери"
                      goBackTo="/water_meters" />

    <v-row>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Водомер №"
                      v-model="waterMeterData.waterMeterFabricNo"
                      autocomplete="off"
                      clearable
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="latitude"
                      v-model="waterMeterData.latitude"
                      autocomplete="off"
                      clearable
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="longitude"
                      v-model="waterMeterData.longitude"
                      autocomplete="off"
                      clearable
                      dense />
      </v-col>

      <v-col lg="2" md="4" cols="6">
        <v-menu v-model="menuLastDateOfRepair"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Дата последен ремонт"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="waterMeterData.lastDateOfRepair"
                          clearable
                          dense>
            </v-text-field>
          </template>
          <v-date-picker v-model="waterMeterData.lastDateOfRepair"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu v-model="menuLastDateOfCheck"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Дата последно засичане"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="waterMeterData.lastDateOfCheck"
                          clearable
                          dense>
            </v-text-field>
          </template>
          <v-date-picker v-model="waterMeterData.lastDateOfCheck"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Засечена стойност"
                      v-model="waterMeterData.checkValue"
                      autocomplete="off"
                      clearable
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-menu v-model="menuNextDateOfCheck"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Следващо засичане на"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="waterMeterData.nextDateOfCheck"
                          clearable
                          dense>
            </v-text-field>
          </template>
          <v-date-picker v-model="waterMeterData.nextDateOfCheck"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <!--<v-col lg="2" md="4" cols="6">
    <base-select label="Партидата на водомера е затворена"
                 :items="nomenclatures.closedItems"
                 item-text="name"
                 item-value="value"
                 v-model="waterMeterData.closed"
                 clearable
                 dense>
    </base-select>
  </v-col>-->
      <!--<v-col lg="2" md="4" cols="6">
    <v-menu v-model="menuClosedDate"
            v-bind:nudge-right="40"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px">
      <template v-slot:activator="{ on }">
        <v-text-field label="Дата на затваряне"
                      height="30"
                      v-on="on"
                      autocomplete="off"
                      v-bind:value="waterMeterData.closedDate"
                      clearable
                      dense>
        </v-text-field>
      </template>
      <v-date-picker v-model="waterMeterData.closedDate"
                     @@input="validFromMenu = false"
                     persistent-hint
                     color="primary"
                     v-bind:first-day-of-week="1"
                     locale="bg-bg">
      </v-date-picker>
    </v-menu>
  </v-col>-->
      <v-col lg="2" md="4" cols="6">
        <base-select label="Тип водомер"
                     :items="nomenclatures.waterMeterTypes"
                     item-text="value"
                     item-value="key"
                     v-model="waterMeterData.waterMeterTypeId"
                     clearable
                     dense>
        </base-select>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <base-select label="Общ / Индивидуален водомер"
                     :items="nomenclatures.waterMeterOwnershipTypes"
                     item-text="value"
                     item-value="key"
                     v-model="waterMeterData.waterMeterOwnershipTypeId"
                     clearable
                     dense>
        </base-select>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <base-select label="Производител"
                     :items="nomenclatures.manufacturers"
                     item-text="value"
                     item-value="key"
                     v-model="waterMeterData.manufacturerId"
                     clearable
                     dense>
        </base-select>
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Хора, ползващи водомера"
                      v-model="waterMeterData.customersTotal"
                      autocomplete="off"
                      clearable
                      dense />
      </v-col>
      <v-col lg="2" md="4" cols="6">
        <v-text-field label="Апартаменти, ползващи водомера"
                      v-model="waterMeterData.apartamentsTotal"
                      autocomplete="off"
                      clearable
                      dense />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="4" md="8" cols="10">
        <base-select label="Клиент"
                     :items="nomenclatures.clients"
                     item-text="value"
                     item-value="key"
                     v-model="waterMeterData.clientId"
                     clearable
                     dense>
        </base-select>
      </v-col>
      <v-col lg="2" md="2" cols="2">
        <v-btn class="success"
               width="220px"
               @click="addNewClient()">
          <v-icon style="margin-right:10px">mdi-account-plus</v-icon>
          <span>Добави нов клиент</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="4" md="8" cols="10">
        <base-select label="Адрес"
                     :items="nomenclatures.addresses"
                     item-text="value"
                     item-value="key"
                     v-model="waterMeterData.addressId"
                     clearable
                     dense>
        </base-select>
      </v-col>
      <v-col lg="2" md="2" cols="2">
        <v-btn class="success"
               width="220px"
               @click="addNewAddress()">
          <v-icon style="margin-right:10px">mdi-account-plus</v-icon>
          <span>Добави нов адрес</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-btn class="success"
           width="220px"
           @click="createWaterMeterData(waterMeterData)">
      <v-icon style="margin-right:10px">mdi-account-plus</v-icon>
      <span>Добави водомер</span>
    </v-btn>


  </v-container>
</template>

<script>
  import {
    createWaterMeter
  } from "@/api/water_meters";

  import {
    ISODateString,
    formatDateTime
  } from '@/utils';

  import {
    getWaterMeterTypesAsKeyValuePairList,
    getManufacturersAsKeyValuePairList,
    getAddressesAsKeyValuePairList,
    getClientsAsKeyValuePairList,
    getWaterMeterOwnershipTypesAsKeyValuePairList,
  } from '@/api/gis_nomenclatures';

  export default {
    name: "waterMeterAdd",
    components: {},
    data() {
      return {
        menuLastDateOfRepair: false,
        menuLastDateOfCheck: false,
        menuNextDateOfCheck: false,
        //menuClosedDate: false,

        data: [],

        waterMeterData: {
          addressId: null,
          apartamentsTotal: null,
          clientId: null,
          checkValue: null,
          //closed: null,
          //closedDate: null,
          customersTotal: null,
          latitude: null,
          lastDateOfCheck: null,
          lastDateOfRepair: null,
          longitude: null,
          manufacturerId: null,
          nextDateOfCheck: null,
          waterMeterFabricNo: null,
          waterMeterTypeId: null,
          waterMeterOwnershipTypeId: null
        },

        nomenclatures: {
          addresses: [],
          clients: [],
          //closedItems: [
          //  { name: "Отворена партида", value: false },
          //  { name: "Затворена партида", value: true },
          //],
          manufacturers: [],
          waterMeterTypes: [],
          waterMeterOwnershipTypes: [],
        },

        waterMeterId: null,
      };
    },

    mounted() {
      this.waterMeterId = null;
    },

    watch: {},

    computed: {},

    created() {
      this.getWaterMeterTypesList();
      this.getWaterMeterOwnershipTypesList();      
      this.getManufacturersList();
      this.getAddressesList();
      this.getClientsList();
    },

    methods: {
      formatDateTime: formatDateTime,
      ISODateString: ISODateString,

      getWaterMeterTypesList() {
        getWaterMeterTypesAsKeyValuePairList().then(data => {

          //will remove 0 index (this is "AllItems")
          data.splice(0, 1);

          this.nomenclatures.waterMeterTypes = data;
        })
      },
      
      getWaterMeterOwnershipTypesList() {
        getWaterMeterOwnershipTypesAsKeyValuePairList().then(data => {

          //will remove 0 index (this is "AllItems")
          data.splice(0, 1);

          this.nomenclatures.waterMeterOwnershipTypes = data;
        })
      },

      getManufacturersList() {
        getManufacturersAsKeyValuePairList().then(data => {

          //will remove 0 index (this is "AllItems")
          data.splice(0, 1);

          this.nomenclatures.manufacturers = data;
        })
      },

      getAddressesList() {
        getAddressesAsKeyValuePairList().then(data => {
          this.nomenclatures.addresses = data;
        })
      },
 
      getClientsList() {
        getClientsAsKeyValuePairList().then(data => {
          this.nomenclatures.clients = data;
        })
      },

      createWaterMeterData(waterMeterData) {
        createWaterMeter(waterMeterData).then(data => {
          if (data.id == undefined) {
            var index1 = data.indexOf("(");
            var index2 = data.indexOf(")");
            if (index1 > -1 && index1 < index2) {
              this.$snotify.error(
                "Нова партида на водомер не е създадена." +
                "Грешка " +
                data.substring(index2 + 1) +
                ".");
            }
          }else if (data.id > 0) { //success
            this.$snotify.success(
              "Открита е нова партида на водомер с номер " +
              data.waterMeterFabricNo +
              ".");
            this.$forceUpdate();
          }
        });
      },

      addNewAddress() {
        var addressId = 0;

        this.$router.push({ path: `/gis_addresses/addressPreview/${addressId}` });
        this.$forceUpdate();
      },

      addNewClient() {
        var clientId = 0;

        this.$router.push({ path: `/gis_clients/clientPreview/${clientId}` });
        this.$forceUpdate();
      }
    }
  };

</script>
