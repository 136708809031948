<template>
  <v-container id="clientPreview"
               ref="clientPreview"
               fluid
               tag="section"
               class="full-height">
    <base-v-component heading="Преглед на клиент"
                      goBackText="Обратно преглед на водомер"
                      goBackTo="/water_meters/preview" />

    <gis-confirm-modal ref="gisConfirmModal" />

    <v-row class="justify-center">
      <v-col>
        <v-card style="border-top-right-radius: 30px;
                       border-bottom-right-radius: 30px;
                       border-bottom-left-radius: 30px;"
                class="v-card--material pa-3"
                color="white">

          <v-avatar style="border-top-right-radius: 20%;
                           border-bottom-left-radius: 20%;
                           border-bottom-right-radius: 20%;
                           margin-left: 20px;
                           margin-top: -40px;"
                    left
                    color="#263238"
                    size="107">
            <v-icon style="font-size: 400%; color: #ffffff;">mdi-account-outline</v-icon>
          </v-avatar>

          <v-card-text class="text-center">
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Клиентски номер"
                              v-model="clientData.clientNumber"
                              autocomplete="off"
                              disabled
                              dense />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Име"
                              v-model="clientData.name"
                              autocomplete="off"
                              disabled
                              dense />
              </v-col>
            </v-row>
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Клиент ЕГН/ЕИК"
                              v-model="clientData.egn"
                              autocomplete="off"
                              disabled
                              dense />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <base-select label="Тип клиент"
                             :items="nomenclatures.clientTypes"
                             item-value="key"
                             item-text="value"
                             v-model="clientData.clientTypeId"
                             disabled
                             dense>
                </base-select>
              </v-col>
            </v-row>
            <v-row style="height: 80px">
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Създаден на"
                              v-model="clientData.createdOn"
                              autocomplete="off"
                              disabled
                              dense />
              </v-col>
              <v-col lg="6" md="6" cols="6">
                <v-text-field label="Последно променен на"
                              v-model="clientData.modifiedOn"
                              autocomplete="off"
                              disabled
                              dense />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center">
            <!--<v-btn
      class="successGradient"
      width="220px"
      @click="saveClientData(clientData)"
    >
      <v-icon style="margin-right: 10px">mdi-account-check</v-icon>
      <span>Запази</span>
    </v-btn>
    <v-btn
      class="successGradient"
      width="220px"
      @click="addClientData(clientData)"
    >
      <v-icon style="margin-right: 10px">mdi-account-check</v-icon>
      <span>Добави нов клиент</span>
    </v-btn>-->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col>
        <base-material-card style="border-bottom-right-radius: 30px;
                                   border-bottom-left-radius: 30px;">
          <template v-slot:heading>
            <h1 class="display-2 text-center">Адреси на клиента</h1>
          </template>

          <v-data-table ref="table"
                        :headers="clientAddressTableProps.headers"
                        :items="clientAddressTableProps.addressData"
                        class="elevation-2"
                        disable-pagination
                        hide-default-footer
                        multi-sort>
            <template v-slot:body="{ items }">
              <tbody>
                <template v-if="items && items.length">
                  <tr v-for="(item, idx) in items"
                      :key="`row_${idx}`"
                      @click="setAddressSelected(item)">
                    <template v-for="(cell, idx) in clientAddressTableProps.headers">
                      <td :key="'td_' + idx"
                          v-if="
                          cell.valueType !== 'button' &&
                          cell.valueType !== 'status' &&
                          cell.valueType !== 'textBalance' &&
                          cell.valueType !== 'checkbox'
                        "
                          :class="cell.class"
                          :style="cell.style">
                        {{ getValue(item, cell) }}
                      </td>

                      <td :key="'td_' + idx"
                          v-if="cell.valueType === 'textBalance'"
                          :class="cell.class"
                          :style="cell.style">
                        <span class="blue--text darken-4">
                          {{ getValue(item, cell) }}
                        </span>
                      </td>

                      <td :key="'td_' + idx"
                          v-if="cell.valueType === 'status'"
                          :class="
                          cell.class +
                          ' ' +
                          (cell.active === item[cell.displayValue || cell.value]
                            ? cell.activeClass
                            : cell.notActive ===
                              item[cell.displayValue || cell.value]
                            ? cell.notActiveClass
                            : '')
                        "
                          :style="cell.style">
                        <template v-if="cell.statusIcons">
                          <v-icon class="success--text"
                                  v-if="
                              item[cell.displayValue || cell.value] ===
                              cell.active
                            ">mdi-check</v-icon>
                          <v-icon class="error--text"
                                  v-if="
                              item[cell.displayValue || cell.value] ===
                              cell.notActive
                            ">mdi-close</v-icon>
                        </template>
                        <template v-else>
                          <template v-if="
                              item[cell.displayValue || cell.value] ==
                              cell.active
                            ">
                            {{ cell.activeText }}
                          </template>
                          <template v-else-if="
                              item[cell.displayValue || cell.value] ==
                              cell.notActive
                            ">
                            {{ cell.notActiveText }}
                          </template>
                          <template v-else>
                            {{ getValue(item, cell) }}
                          </template>
                        </template>
                      </td>

                      <td :key="'td_' + idx"
                          v-if="cell.valueType === 'checkbox'"
                          :class="cell.class"
                          :style="cell.style">
                        <v-checkbox v-model="item[cell.displayValue || cell.value]"
                                    color="primary"
                                    :value="cell.checked"
                                    :false-value="cell.unchecked"
                                    hide-details
                                    class="mt-0"
                                    @change="changeCheckboxValue(item)"></v-checkbox>
                      </td>

                      <td :key="'td_' + idx"
                          v-if="cell.valueType === 'button'"
                          :class="cell.class"
                          :style="cell.style"
                          class="d-print-none">
                        <template v-for="(button, bIdx) in cell.buttons">
                          <v-btn class="button.class"
                                 v-if="checkButton(button, item)"
                                 :key="'td_button_' + bIdx"
                                 :block="button.block"
                                 :style="button.style"
                                 :title="button.title"
                                 @click="doAction(button.click, item)"
                                 :class="`v-size--${button.size} ` + button.class">
                            <v-icon :class="button.label ? 'mr-2' : 'mr-0'"
                                    v-if="
                                button.icon && button.icon.length ? true : false
                              ">{{ button.icon }}</v-icon>
                            {{ button.label }}
                          </v-btn>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td :colspan="clientAddressTableProps.headers.length"
                        class="text-center px-8">
                      <v-row>
                        <v-col cols="12" class="pa-7">
                          <h4 class="
                              display-1
                              font-weight-medium
                              blue-grey--text
                              text--darken-2
                            ">
                            Няма намерени резултати
                          </h4>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { getClientTypesAsKeyValuePairList } from "@/api/gis_nomenclatures";

  import moment from "moment";

  import {
    formatDateTime,
    formatDateTimeSeconds,
    getArrayField,
    ISODateString,
    roundNumber,
  } from "@/utils";

  import {
    getClientById,
    createClient, updateClient
  } from "@/api/gis_client";

  import { gisConfirmModal } from "@/components";

  export default {
    name: "clientPreview",

    components: {
      gisConfirmModal,
    },

    data() {
      return {
        clientData: {},
        clientInitData: [],

        nomenclatures: {
          clientTypes: [],
        },

        selectedClientId: null,

        clientAddressTableProps: {
          headers: [
            {
              text: "Област",
              value: "districtName",
              valueType: "text",
              width: "60px",
              fixed: true,
            },
            {
              text: "Община",
              value: "municipalityName",
              valueType: "text",
              width: "60px",
              fixed: true,
            },
            {
              text: "ГРАО код град",
              value: "townGraoCode",
              valueType: "text",
              width: "70px",
              fixed: true,
            },
            {
              text: "Град",
              value: "townName",
              valueType: "text",
              width: "60px",
              fixed: true,
            },
            {
              text: "Улица",
              value: "streetName",
              valueType: "text",
              width: "60px",
              fixed: true,
            },
            {
              text: "№ улица",
              value: "streetNumber",
              valueType: "text",
              width: "70px",
              fixed: true,
            },
            {
              text: "Блок",
              value: "block",
              valueType: "text",
              width: "60px",
              fixed: true,
            },
            {
              text: "Вход",
              value: "entrance",
              valueType: "text",
              width: "60px",
              fixed: true,
            },
            {
              text: "Етаж",
              value: "floor",
              valueType: "text",
              width: "60px",
              fixed: true,
            },
            {
              text: "Апартамент",
              value: "apartment",
              valueType: "text",
              width: "60px",
              fixed: true,
            },
            {
              text: "Пощенски код",
              value: "zipCode",
              valueType: "text",
              width: "60px",
              fixed: true,
            },
            {
              sortable: false,
              text: "",
              value: "actions",
              valueType: "button",
              class: "text-center cols cols-2",
              style: "width:100px; padding: 10px;",
              buttons: [
                {
                  label: "",
                  title: "Преглед",
                  class: "successGradient",
                  icon: "mdi-text-box-search-outline",
                  style: "width:100px; height: 30px;",
                  click: "previewAddress",
                  id: "previewAddress",
                },
              ],
            },
          ],

          addressData: [],

          filters: {},
          hasPaging: false,

          noms: {
            statuses: [],
            priorities: [],
            users: [],
          },

          pagination: {
            itemsPerPage: 10,
            page: 1,
            perPageOptions: [5, 10, 25, 50, 100],
            total: 0,
          },

          props: {
            id: {
              type: String,
              default: "",
            },
            printAll: false,
            color: "primary",
          },

          sortColumn: [],
          sortDescending: [],

          lastAuditDate: null,
          lastAuditValue: null,
          auditorId: null,
        },
      };
    },

    mounted() {
      this.getClientTypesList();

      this.waitLoadingNoms();

    },

    watch: {},

    computed: {},

    created() { },

    methods: {
      formatDateTime: formatDateTime,
      ISODateString: ISODateString,

      getClientTypesList() {
        getClientTypesAsKeyValuePairList().then((data) => {
          data.splice(0, 1); //will remove 0 index (this is "AllItems")
          this.nomenclatures.clientTypes = data;
        });
      },

      getClientById(clientId) {
        getClientById(clientId).then((data) => {
          this.clientData = data;
          this.clientAddressTableProps.addressData = data.addresses;
          this.clientInitData = JSON.parse(JSON.stringify(this.clientData)); //deep copy
        });
      },

      async saveClientData(clientModifiedData) {
        var confirm = await this.$refs.gisConfirmModal.showModal(
          "Запазване данните на клиент",
          "Въведените данни за този клиент ще се запазят. Моля, потвърдете?"
        );

        if (confirm == true) {
          var clientModifiedDataLoc = JSON.parse(
            JSON.stringify(clientModifiedData)
          ); //deep copy
          var clientInitData = this.clientInitData;
          var flagModify = false;

          for (var key in clientModifiedDataLoc) {
            if (key != "id")
              if (
                JSON.stringify(clientModifiedDataLoc[key]) ==
                JSON.stringify(clientInitData[key])
              )
                clientModifiedDataLoc[key] = null;
              else flagModify = true;
          }

          if (flagModify == true) {
            updateClient(clientModifiedDataLoc).then((data) => {
              var index1 = data.indexOf("(");
              var index2 = data.indexOf(")");

              if (index1 > -1 && index1 < index2) {
                var newClientId = parseInt(
                  data.substring(index1 + 1, index2),
                  10
                );
                if (newClientId > 0) {
                  this.$snotify.success(
                    "Данните за клиент " +
                    clientModifiedData.clientNumber +
                    " са обновени успешно."
                  );
                  this.getClientById(newClientId);
                  this.$forceUpdate();
                } else {
                  this.$snotify.error(
                    "Данните за клиент " +
                    clientModifiedData.clientNumber +
                    " не са обновени. Грешка " +
                    data.substring(index2 + 1) +
                    "."
                  );
                }
              }
            });
          }
        }
      },

      async addClientData(clientData) {
        var confirm = await this.$refs.gisConfirmModal.showModal(
          "Нов клиент",
          "Добавяне на нов клиент с посочените данни. Моля, потвърдете?"
        );

        if (confirm == true) {
          clientData.clientId = null;
          createClient(clientData).then((data) => {
            var index1 = data.indexOf("(");
            var index2 = data.indexOf(")");

            if (index1 > -1 && index1 < index2) {
              var newClientId = parseInt(data.substring(index1 + 1, index2), 10);
              if (newClientId > 0) {
                this.$snotify.success("Данните за клиент са добавени успешно.");
                this.getClientById(newClientId);
                this.$forceUpdate();
              } else {
                this.$snotify.error(
                  "Данните за клиент не са добавени. Грешка " +
                  data.substring(index2 + 1) +
                  "."
                );
              }
            }
          });
        }
      },
      checkButton(button, item) {
        let ifValue = true;
        if (button.ifValueObjectExists) {
          let props = button.ifValueObjectExists.split(".");
          if (item[props[0]]) {
            let value = props.reduce((a, v) => a[v], item);

            if (!value) ifValue = false;
          }
        }
        return (
          (button.if
            ? button.if.type
              ? button.if.type === "equal"
                ? item[button.if.item] === button.if.value
                : false
              : item[button.if.item] !== button.if.value
            : true) && ifValue
        );
      },
      getValue(item, options) {
        let template = "";
        let keys = [];
        let props = [];
        let prop = options.displayValue || options.value;
        let result;
        let itemData;

        //if (options.value == 'closed') {
        //  return item.closed == false ? "Не" : "Да";
        //}

        switch (options.valueType) {
          case "decimal":
            return roundNumber(item[prop || options.value], 16, 16, 8);
          case "text":
            return item[prop] || item[prop] === 0 ? item[prop] : " — ";
          case "textGL":
            return item[prop] || "GL";
          case "textBalance":
            // result = (item[options.value] + '').split('.')[1];
            // if(result && result.length <= 1 || (item[options.value] + '').length === 1){
            return roundNumber(item[prop || options.value], 2);
          // }
          // return item[options.value];
          case "price":
            return roundNumber(item[prop || options.value], 2);
          case "priceAccounting":
            var num = item[prop || options.value];
            if (typeof num === "number") num = num + "";

            var value = Number(num);

            var res = num.split(".");
            if (res.length == 1 || res[1].length < 3)
              value = roundNumber(value, 2);

            return value;
          case "array":
            // eslint-disable-next-line no-case-declarations
            let val = getArrayField(item, prop, options.comma);
            if (val && val !== "null") {
              return val;
            }
            return "";
          //return val != null ? val : '';
          case "date":
            if (item[prop]) {
              // eslint-disable-next-line no-case-declarations
              let d = moment();

              if (
                (typeof item[prop] === "number" &&
                  item[prop].toString().length === 8) ||
                (typeof item[prop] === "string" && item[prop].length === 8)
              ) {
                d = moment(item[prop], "DDMMYYYY");
                if (!d.isValid()) d = moment(item[prop], "YYYYMMDD");
              } else if (
                (typeof item[prop] === "number" &&
                  item[prop].toString().length === 14) ||
                (typeof item[prop] === "string" && item[prop].length === 14)
              ) {
                d = moment(item[prop], "YYYYMMDDHHmmss");
              } else {
                d = moment(item[prop]);
              }
              if (d.isValid()) {
                return ISODateString(d.toISOString());
              }
            }

            return "—";
          case "dateTime":
            if (item[prop]) {
              // eslint-disable-next-line no-case-declarations
              let d = moment(item[prop]);
              if (d.isValid()) {
                return formatDateTime(item[prop]);
              }
            }

            return "—";
          case "dateTimeSeconds":
            if (item[prop]) {
              // eslint-disable-next-line no-case-declarations
              let d = moment(item[prop]);
              if (d.isValid()) {
                return formatDateTimeSeconds(item[prop]);
              }
            }

            return "—";
          case "firstElement":
            props = prop.split(".");
            return item[props[0]] ? item[props[0]][0][props[1]] : "";
          case "firstElementObject":
            result = "";
            keys = prop.split(".");
            var firstChildren = item[keys[0]][0];
            keys.shift();
            if (firstChildren) {
              if (firstChildren[keys[0]]) {
                result = keys.reduce((a, v) => a[v], firstChildren);
              }
            }
            return result ? result : "";
          case "object":
            keys = prop.split(".");
            // eslint-disable-next-line no-case-declarations
            result = "";
            try {
              if (item[keys[0]]) {
                result = keys.reduce((a, v) => a[v], item);
              }
              return result ? result : " — ";
            } catch (e) {
              return " — ";
            }
          case "objectDate":
            keys = prop.split(".");

            // eslint-disable-next-line no-case-declarations
            let date = keys.reduce((a, v) => a[v], item);

            if (date) {
              // eslint-disable-next-line no-case-declarations
              let d;
              if (typeof date === "number" && date.toString().length === 8) {
                d = moment(date, "YYYYMMDD");
              } else {
                d = moment(date);
              }

              if (d.isValid()) {
                return ISODateString(d.toISOString());
              }
            }

            return " — ";
          case "objectDateTime":
            keys = prop.split(".");

            // eslint-disable-next-line no-case-declarations
            let dateTimeObject = keys.reduce(
              (a, v) => a[v] != null && a[v],
              item
            );

            if (dateTimeObject) {
              return formatDateTime(dateTimeObject);
            }

            return " — ";
          case "objectPrice":
            keys = prop.split(".");
            // eslint-disable-next-line no-case-declarations
            result = "";
            try {
              if (item[keys[0]]) {
                result = keys.reduce((a, v) => a[v], item);
              }
              return result ? roundNumber(result, 2) : " — ";
            } catch (e) {
              return " — ";
            }
          case "twoObjectsPrice":
            result = "";
            try {
              for (let obj of prop) {
                keys = obj.split(".");
                if (item[keys[0]]) {
                  if (result.length) {
                    let val = keys.reduce((a, v) => a[v], item);
                    result += val ? " " + val : "";
                  } else {
                    result += roundNumber(
                      keys.reduce((a, v) => a[v], item),
                      2
                    );
                  }
                }
              }

              return result ? result : " — ";
            } catch (e) {
              return " — ";
            }
          case "valueOrValue":
            keys = prop.split(".");

            try {
              if (item[keys[0]]) {
                return item[keys[0]];
              }

              return item[keys[1]] || " — ";
            } catch (e) {
              return " — ";
            }
          case "valueOrValueObject":
            keys = prop[0].split(".");
            try {
              result = "";
              if (item[keys[0]]) {
                result = keys.reduce((a, v) => a[v], item);
              }

              if (result) {
                return result;
              }

              // checking next value
              keys = prop[1].split(".");

              result = "";
              if (item[keys[0]]) {
                result = keys.reduce((a, v) => a[v], item);
              }

              return result ? result : " — ";
            } catch (e) {
              return " — ";
            }
          case "valueOrValueArray":
            result = "";
            for (let i = 0; i < prop.length; i++) {
              if (prop[i].includes(".")) {
                keys = prop[i].split(".");
              } else {
                keys = [prop[i]];
              }

              for (let k = 0; k < keys.length; k++) {
                if (item[keys[k]]) {
                  if (result.length) result += options.separator || " ";
                  result += item[keys[k]];
                }
              }
            }
            return result;
          case "button":
            for (let i = 0; i < options.buttons.length; i++) {
              template += ``;
            }
            return template;
          case "sort":
            itemData = Object.assign([], item[options["sortBy"]]);

            if (options["sortType"] === "date") {
              itemData.sort(function (x, y) {
                if (options["sortDesc"]) {
                  return (
                    new Date(y[options["sortValue"]]).getTime() -
                    new Date(x[options["sortValue"]]).getTime()
                  );
                } else {
                  return (
                    new Date(x[options["sortValue"]]).getTime() -
                    new Date(y[options["sortValue"]]).getTime()
                  );
                }
              });
            }

            if (prop.includes(".")) {
              keys = prop.split(".");
              result = "";
              if (itemData[0][keys[0]]) {
                result = keys.reduce((a, v) => a[v], itemData[0]);
              }
              return result ? result : " — ";
            }

            return itemData[0][prop];
          case "status":
            if (item[prop] != null) {
              return item[prop].toString() || " - ";
            }
            return " - ";
          case "translateValue":
            result = " - ";
            for (let obj of options.translateValues) {
              if (obj.value === item[prop]) {
                result = obj.label;
              }
            }
            return result;
        }
      },

      doAction(action, data) {
        if (action == "previewAddress") this.previewAddress(data.id);
        //if (action == "closeWaterMeter")
        //  this.closeWaterMeter(data.id, data.waterMeterFabricNo, data.closed);
      },

      previewAddress(addressId) {
        if (addressId > 0) {
          this.$router.push({
            path: `/gis_addresses/addressPreviewOnly/${addressId}`,
          });
          this.$forceUpdate();
        }
      },

      setAddressSelected(addressSelected) {
        this.addressSelected = addressSelected.id;
      },

      async waitLoadingNoms() {
        while (this.nomenclatures.clientTypes.length == 0) {
          await new Promise(r => setTimeout(r, 1000));
        }

        this.selectedClientId = this.$route.params.clientId;

        if (this.selectedClientId > 0)
          this.getClientById(this.selectedClientId);
        else this.selectedClientId = null;

      },

    },
  };

</script>

// custom style because of data table in base-material-card which gives strech to buttons and columns with this class
// so we set padding to zero
<style scoped>
  .v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab) {
    padding: 0 !important;
  }
</style>
