<template>
  <base-material-filter title="Търсене на сигнали"
                        :hasFilters="hasActiveFilters"
                        v-on:do-filter="doFilter()"
                        v-on:remove-filter="clearFilters">
    <v-row @keypress.enter="doFilter" class="mx-1">
      <v-col width lg="4" md="6" cols="8">
        <v-select :items="properties.users"
                  item-value="key"
                  item-text="value"
                  v-model="filters.UserId"
                  label="Потребител">
        </v-select>
      </v-col>
      <v-col width lg="4" md="6" cols="8">
        <v-select :items="properties.priorities"
                  item-value="key"
                  item-text="value"
                  v-model="filters.priorityId"
                  label="Приоритет">
        </v-select>
      </v-col>
      <v-col lg="4" md="6" cols="8">
        <v-select :items="properties.statuses"
                  item-value="key"
                  item-text="value"
                  v-model="filters.statusId"
                  label="Статус">
        </v-select>
      </v-col>
      <v-col lg="4" md="6" cols="8">
        <base-select :items="properties.needsReply"
                     item-text="name"
                     item-value="value"
                     v-model="filters.needsReply"
                     label="С отговор">
        </base-select>
      </v-col>
      <v-col lg="4" md="6" cols="8">
        <v-menu v-model="menuCreatedUTCFrom"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Дата на създаване от"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.createdUTCFrom"
                          clearable>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.createdUTCFrom"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="4" md="6" cols="8">
        <v-menu v-model="menuCreatedUTCTo"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Дата на създаване до"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.createdUTCTo"
                          clearable>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.createdUTCTo"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="1" md="1" cols="2">
        <v-menu v-model="menuLastResponseUTCFrom"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Посл. отговор от"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.lastResponseUTCFrom"
                          clearable>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.lastResponseUTCFrom"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="1" md="1" cols="2">
        <v-menu v-model="menuLastResponseUTCTo"
                v-bind:nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Посл. отговор до"
                          height="30"
                          v-on="on"
                          autocomplete="off"
                          v-bind:value="filters.lastResponseUTCTo"
                          clearable>
            </v-text-field>
          </template>
          <v-date-picker v-model="filters.lastResponseUTCTo"
                         @@input="validFromMenu = false"
                         persistent-hint
                         color="primary"
                         v-bind:first-day-of-week="1"
                         locale="bg-bg">
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </base-material-filter>
</template>

<script>

  import { isEmptyObject } from "@/utils";
  import { getSignalPriorityAsKeyValuePairList } from '@/api/priorities';
  import { getSignalStatusAsKeyValuePairList } from '@/api/statuses';
  import { getUserAsKeyValuePairList } from '@/api/users';

  const filtersModel = Object.freeze({
    createdUTCFrom: null,
    createdUTCTo: null,
    closedUTCFrom: null,
    closedUTCTo: null,
    lastResponseUTCFrom: null,
    lastResponseUTCTo: null,
    statusId: null,
    priorityId: null,
    needsReply: ""
  });

  export default {
    name: "signalsEmailFilter",
    data() {
      return {
        properties: {
          priorities: [],
          statuses: [],
          needsReply: [
            { name: "Не", value: false },
            { name: "Да", value: true },
            { name: "Всички", value: null }
          ],
          users: [],
        },
        filters: Object.assign({}, filtersModel),
        hasActiveFilters: false,
        menuCreatedUTCFrom: false,
        menuCreatedUTCTo: false,
        menuLastResponseUTCFrom: false,
        menuLastResponseUTCTo: false,
      }
    },

    created() {
      this.getUserList();
      this.getStatusList();
      this.getPriorityList();
    },

    methods: {
      getUserList() {
        getUserAsKeyValuePairList().then(data => {
          this.properties.users = data;
        })
      },

      getStatusList() {
        getSignalStatusAsKeyValuePairList().then(data => {
          this.properties.statuses = data;
        })
      },

      getPriorityList() {
        getSignalPriorityAsKeyValuePairList().then(data => {
          this.properties.priorities = data;
        })
      },

      doFilter() {
        if (!isEmptyObject(this.filters) || (this.filters.needsReply == null)) {  //a simple patch for our private deals
          this.filters.page = 1;
          this.hasActiveFilters = true;
          this.$emit("doFilter");
          delete this.filters.page;
        } else {
          if (this.hasActiveFilters === true)
            this.$emit("doFilter");

          this.hasActiveFilters = false;
        }
      },
      clearFilters() {
        this.filters = Object.assign({}, filtersModel);

        this.hasActiveFilters = false;

        this.$emit("doFilter", true);
      },
      getFilters() {
        this.filters;
      }
    }
  }
</script>
