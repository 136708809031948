  let reports = {
    name: "Справки",
  //  path: 'https://superset.vik-vratza.eu:9443/',
  //  path: 'https://reports.vik-ruse.com/',

  //path: 'https://reports.vik-pernik.eu/',
    path: process.env.VUE_APP_SUPERSET,

    //path: 'https://reports.vik-pernik.eu/',
  //  path: 'https://reports.aquagis.eu/',

    target: true,
    meta: { icon: "reports" },
    groups: ["TestMainGroup", "TestDummyGroup"],
  }

//let reports = {
//   name: "Справки",
//    path: 'https://reports.vik-ruse.com/',
//    target: true,
//    meta: { icon: "reports" },
//    groups: ["TestMainGroup", "TestDummyGroup"],
//}

// let reports = {
//     name: "Справки",
//     path: 'https://reports.wik-stz.com/',
//     target: true,
//     meta: { icon: "reports" },
//     groups: ["TestMainGroup", "TestDummyGroup"],
//    }
//let reports = {
 //    name: "Справки",
 //    path: 'https://reports.vik-pernik.eu/',
 //    target: true,
 //    meta: { icon: "reports" },
 //    groups: ["TestMainGroup", "TestDummyGroup"],
 //   }

export default reports;
