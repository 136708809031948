import request from '@/utils/request';

export function getAddressGoogle(term) {
    return request({
        url: process.env.VUE_APP_WAREHOUSE_BACKEND + 'Warehouse/SearchByAddress',
        method: 'get',
        params: { address: term },
    })
}

export function getMapWMSLayers() {
    return request({
      url: process.env.VUE_APP_WAREHOUSE_BACKEND + "WMS/GetWMSLayers",
      method: 'get'
    }) 
  }