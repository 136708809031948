var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-5 v-card--filter",class:[
    {
      'v-card--has-filter': _vm.hasFilters,
      'mb-15': _vm.marginBottom,
    },
    _vm.cardClass,
  ],attrs:{"flat":_vm.flat}},[(_vm.header)?_c('div',{staticClass:"pt-6"},[_c('v-icon',{attrs:{"color":"primary","x-large":""}},[_vm._v(" mdi-filter-variant ")]),_c('h2',{staticClass:"display-2 font-weight-light",domProps:{"textContent":_vm._s(_vm.title)}})],1):_vm._e(),_c('v-row',[_vm._t("default")],2),_c('v-row',{attrs:{"justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"successGradient",on:{"click":function($event){return _vm.doFilter()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Приложи филтрите")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.hasFilters),expression:"hasFilters"}],attrs:{"title":"Премахни филтрите","color":"errorGradient"},on:{"click":function($event){return _vm.clearFilters()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Изчисти филтрите")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }