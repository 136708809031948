import request from '@/utils/request';
import { filterQuery } from '@/utils/index';

export function getResponsesList(query) {
  return request({
    url: '/api/response/GetResponsesList',
    method: 'get',
    params: filterQuery(query)
  });
}

export function getSignalDetails(signalId) {
  return request({
    url: '/api/signal/GetSignalDetails?signalId=' + signalId,
    method: 'get',
  });
}

export function createResponse(responseDetails, attachments) {
  return request({
    url: '/api/response/CreateResponse',
    method: 'post',
    data: responseDetails,
    formData: attachments
  });
}
