<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">
          {{title}}
        </v-card-title>
        <v-card-text>{{body}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            @click="confirm"
          >
            Продължи
          </v-btn>
          <v-btn
            color="default darken-1"
            default
            @click="open = false"
          >
            Откажи
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'confirmActionModal',
    props: {
    },
    data (){
      return {
        open: false,
        title: '',
        body: ''
      }
    },
    methods: {
      confirm(){
        this.callback();
      },
      openModal(data){
        this.title = data.title;
        this.body = data.body;
        if(data.callback)
          this.callback = data.callback;
        
        this.open = true;
      }
    }
  }
</script>